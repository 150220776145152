import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import auth from '../../auth/auth';
import { LoaderComponent } from '../loaderComponent';
import PropertyDetailsForm from './PropertyDetailsForm';
import PropertyGraphicsForm from './PropertyGraphicsForm';
import PropertyLocationForm from './PropertyLocationForm';


export class Property{
    constructor(id, title, price, negotiable, description, size, 
        added_by, status, time_posted, locations, graphics) {
        this.id= id;
        this.title= title;
        this.price= price;
        this.negotiable= negotiable;
        this.description= description;
        this.size=size
        this.added_by=added_by
        this.status=status
        this.time_posted=time_posted
        this.locations=locations
        this.graphics=graphics
        this.isUpLoading = false
        this.isPendingChange = false
        this.isChangeSuccessfull = false
        this.msg = ''
        this.StatusClass = 'form-select'
    }

}

export class Location{
    constructor(id, state, street, lga, country, zip) {
        this.id= id;
        this.state= state;
        this.street= street;
        this.lga= lga;
        this.country= country;
        this.zip=zip
    }

}

export class Graphic{
    constructor(id, selectedValue,msg,comment) {
        this.id= id;
        this.selectedValue= selectedValue;
        this.msg=msg;
        this.comment=comment;
    }

}

export default class PropertiesComponent extends Component {
    
    state = { 
        pageNumber: 0, countPerPage: 15, sort_by: 'title', sort_by_order: 1,
        SearchValue: '',negotiable: -1, user_type: -1, status: -1,
        step: 0, property_id: this.props.property_id,
        isLoading: false, isUpLoading: false, userId:'', msgS: '', msgF: '',
        properties: [],
    }

    componentDidMount() {
        auth.checkAuthentication().then(() => {
            if(auth.userRole !== 'admin' && auth.userRole !== 'seller'){
                localStorage.setItem("navigating_to", window.location.href);
                window.location.href = '/Login';
            }
            this.searchValue();
        });
    }
    
    pagesVisited = () =>{
        return this.state.pageNumber * this.state.countPerPage;
    }
    pageCount = () =>{
        return Math.ceil(this.state.properties.length / this.state.countPerPage);
    }

    changePage=({selected})=>{
        this.setState({pageNumber: selected});
    }

    displayItems = () =>{
        return this.state.properties.slice(
            this.pagesVisited(), this.pagesVisited() + this.state.countPerPage
        ).map(property => {return(<div className="">
                <h6 className='card-header'>
                    {property.title}
                    <a href='#Delete' type='button' 
                        onClick={()=>{
                            this.deleteProperty(property.id, property.title)
                        }}
                        className='btn-sm btn-danger float-end'
                    ><span className='fa fa-trash'></span></a>
                    <a href={'Properties/Edit/' + property.id}
                        className='btn-sm btn-primary me-2 float-end'
                    ><span className='fa fa-edit'></span></a>
                    <a href={'Properties/' + property.id}
                        className='btn-sm btn-success me-2 float-end'
                    ><span className='fa fa-eye'></span></a>
                </h6>
                <div className="lst shadow p-2">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="row">
                                <div className="form-group col-6">
                                    <label className='control-label'>Price: </label>
                                    <p className='description'>{auth.formatter.format(property.price)}</p>
                                </div>                      
                                <div className="form-group col-6">
                                    <label className='control-label'>Size: </label>
                                    <p className='description'>{property.size}</p>
                                </div>
                            </div>
                        </div>
                        <div className="form-group col-md-3">
                            <label className='control-label me-2'>
                                Negotiable: 
                            </label>
                            <div className='description row'>
                                <div className="col-1">
                                    <input type='checkbox' className='form-control-sm' 
                                        defaultChecked={property.negotiable}
                                        onChange={() => {this.changePropertyNegotiable(property.id)}}
                                    />
                                </div>
                                <div className="col-9">
                                    {property.negotiable && 'Item is negotiable'}
                                    {!property.negotiable && 'Item is not negotiable'}
                                </div>
                            </div>
                        </div>
                        <div className="form-group col-md-3">
                            <label className='control-label me-2'>
                                Status: 
                            </label>
                            {
                                auth.userRole === 'admin' &&
                                <div className="input-group mb-3">
                                    <select className={property.StatusClass}
                                        defaultValue={property.status}
                                        onChange={e => {this.setPropertyStatus(property.id, e)}}
                                    >
                                        {
                                            auth.property_statuses.map(status => 
                                                <option value={auth.property_statuses.indexOf(status)}>{status}</option>
                                            )
                                        }
                                    </select>
                                    <span className="input-group-text text-primary" title='Save Changes'
                                        onClick={(e) => {this.changePropertyStatus(property.id, e.target)}}
                                    >
                                        <i className='fa fa-save'></i>
                                    </span>
                                </div>
                            }
                            {
                                auth.userRole !== 'admin' &&
                                <div className="input-group mb-3">
                                    <input disabled className={property.StatusClass}
                                        defaultValue={auth.property_statuses[property.status]} />
                                </div>
                            }
                            {
                                property.msg !== "" && property.isChangeSuccessfull && <div className='p-2 shadow border-secondary alert alert-primary d-flex align-items-center alert-dismissible fade show' role="alert">
                                    <button type="button" className="p-2 btn-sm btn-close" 
                                        onClick={() => this.clearPropertyMsg(property.id)}
                                    // <button type="button" className="p-2 btn-sm btn-close" data-bs-dismiss="alert" 
                                        aria-label="Close" title='Close'></button>
                                    {property.msg}
                                </div>
                            }
                            {
                                property.msg !== "" && !property.isChangeSuccessfull && <div className='p-2 shadow border-secondary alert alert-danger d-flex align-items-center alert-dismissible fade show' role="alert">
                                    <button type="button" className="p-2 btn-sm btn-close" 
                                        onClick={() => this.clearPropertyMsg(property.id)}
                                    // <button type="button" className="p-2 btn-sm btn-close" data-bs-dismiss="alert" 
                                        aria-label="Close" title='Close'></button>
                                    {property.msg}
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div hidden>
                    <h6 className='underline'>Available in:</h6>
                    <div className="row">
                    {
                        property.locations.map(location => <div className="p-2 col-md-6">
                            <p className='description'>{location.street}</p>
                            <p className='description'>{location.lga}, {location.state}</p>
                            <p className='description'>{location.country}, {location.zip}</p>
                            <hr />
                        </div>)
                    }
                    </div>
                </div>
            </div>)}
        )
    }

    searchValue = () => {
        (async () => {
            //alert(this.props.property_id)
            this.setState({isLoading: true});
            this.setState({property_id: this.props.property_id});
            this.setState({user_id: this.props.user_id});

            //search_string: str = '', negotiable: int = -1, user_type: int = -1, status
            var url = `properties?` + new URLSearchParams({
                search_string: this.state.SearchValue, negotiable: this.state.negotiable,
                user_type: this.state.user_type, status:this.state.status
            });

            var result = await auth.GetData(url, 'GET');
            //console.log(result);
            if(result["Error"] != null || result["Error"] !== undefined){
                this.setState({msgF: result.Error});
                this.setState({isLoading: false});
            }if(result["details"] == null){
                this.setState({msgF: "No result found"})
            }else if(result["details"] != null || result["details"] !== undefined){
                //const {properties} = this.state;
                const newProperties = [];
                result["details"].forEach(prop => {
                    var details = prop.details;
                    //console.log(prop.locations);
                    if(auth.userRole === 'admin' || auth.userId === details.added_by){
                        newProperties.push(new Property(
                            details.id, details.title, details.price,
                            details.negotiable, details.description, details.size,
                            details.added_by, details.status, details.time_posted,
                            prop.locations, prop.graphics
                        ))
                    }
                })
                this.setState({properties: newProperties});
                if(newProperties.length === 0){
                    this.setState({msgF: "No result found"})
                }
            }
        })().then(()=>{this.setState({isLoading: false})});
    }

    deleteProperty(property_id, property_title){
        (async () => {
            this.setState({msgF: ""})
            this.setState({msgS: ""})
            var answer = window.confirm("Delete " + property_title + "?");
            if(answer){
                this.setState({isUpLoading: true});
                var result = await auth.PostData(null, `properties/` + property_id, 'DELETE');
                //console.log(result);
                if(result["Error"] != null || result["Error"] !== undefined){
                    this.setState({msgF: result.Error});
                    this.setState({isLoading: false});
                }if(result['message'] === 'success'){
                    this.setState({msgS: "Property deleted"})
                    this.setState({isUpLoading: false});
                    const properties = this.state.properties.filter(c=>c.id !== property_id);
                    this.setState({properties});
                }else{
                    this.setState({msgF: result.statusText})
                }
                this.setState({isUpLoading: false});
            }
        })().then(()=>{this.setState({isLoading: false})});
    }

    changePropertyNegotiable(property_id){
        (async () => {
            this.setState({msgF: ""})
            this.setState({msgS: ""})
            this.setState({isUpLoading: true});
            var result = await auth.PostData(null, `properties/change_negotiable/` + property_id, 'POST');
            //console.log(result);
            if(result["Error"] != null || result["Error"] !== undefined){
                this.setState({msgF: result.Error});
                this.setState({isLoading: false});
            }if(result['message'] === 'success'){
                this.setState({msgS: "Property Negotiation Changed"})
                this.setState({isUpLoading: false});
                const properties = this.state.properties;
                this.state.properties.forEach(prop => {
                    if(prop.id===property_id){
                        prop.negotiable = !prop.negotiable;
                    }
                })
                this.setState({properties});
            }else{
                this.setState({msgF: result.statusText})
            }
            this.setState({isUpLoading: false});
        })().then(()=>{this.setState({isLoading: false})});
    }

    clearPropertyMsg(property_id){
        const {properties} = this.state;
        properties.forEach(prop => {
            if(prop.id===property_id){
                prop.msg = '';
                return
            }
        })
        this.setState({properties})
    }

    setPropertyStatus(property_id, e){
        const {properties} = this.state;
        properties.forEach(prop => {
            if(prop.id===property_id){
                prop.status = e.target.value;
                prop.isPendingChange = true;
                prop.StatusClass = this.getStatusClass(prop);
                return
            }
        })
        this.setState({properties})
        //console.log(this.state.properties);
        //e.target.className = 'form-control border-primary';
    }

    getStatusClass = (prop) => {
        var className = 'form-select'
        if(prop.isPendingChange) className = 'form-select border-primary';
        if(prop.isUpLoading) className = 'form-select border-warning';
        if(prop.isChangeSuccessfull) className = 'form-select border-success';
        return className;
    }

    changePropertyStatus(property_id, e){
        (async () => {
            this.setState({msgF: ""})
            this.setState({msgS: ""})
            this.setState({isUpLoading: true});
            var status = 0;
            const {properties} = this.state;
            var p = null;
    
            properties.forEach(prop => {
                if(prop.id===property_id){
                    prop.isPendingChange = false;
                    prop.isUpLoading = true;
                    status = prop.status;
                    prop.StatusClass = this.getStatusClass(prop)
                    prop.msg = '';
                    p = prop;
                    return;
                }
            })

            this.setState({properties})
            e.disabled = true;
            var classname = e.className;
            e.className = 'fa fa-spin fa-spinner';
            var result = await auth.PostData(JSON.stringify({
                    "property": "status",
                    "value": status
                }), 
                `properties/change_status/` + property_id, 'POST');
            //console.log(result);
            if(result["Error"] != null || result["Error"] !== undefined){
                this.setState({msgF: result.Error});
                this.setState({isLoading: false});
            }if(result['message'] === 'success'){
                this.setState({msgS: "Property Status Changed"})
                p.msg = 'Status Changed';
                p.isPendingChange = false;
                p.isChangeSuccessfull = true;
            }else{
                this.setState({msgF: result.statusText})
                p.msg = result.statusText;
            }
            e.className = classname;
            this.setState({isUpLoading: false});
            p.isUpLoading = false;
            p.StatusClass = this.getStatusClass(p);
            this.setState({properties})
            e.disabled = false;
        })().then(()=>{this.setState({isLoading: false})});
    }

    setSearchValue(val){
        this.setState({SearchValue: val});
    }

    setSortBy(val){
        this.setState({sort_by: val});
        const {properties} = this.state;
        properties.sort(auth.dynamicSort(val, this.state.sort_by_order));
    }

    setSortByOrder(val){
        this.setState({sort_by_order: val});
        const {properties} = this.state;
        properties.sort(auth.dynamicSort(this.state.sort_by, val));
    }

    render() { 
        if(this.state.isLoading) 
            return (
                <LoaderComponent />
            );
            
        return ( 
            <React.Fragment>
                <div className=''>
                    <div className='col-md-12 p-4'>
                        <a href='/Properties-Create' className=''>Add New Properties</a>
                        <div className='shadow m-2 p-2'>
                                <div className="card-body">
                                    <div className="form-group">
                                        <p className='text-success'>{this.state.msgS}</p>
                                        <p className='text-danger'>{this.state.msgF}</p>
                                    </div>
                                    <div className='card-header p-2 mt-2'>
                                        <h5 className='text-primary'>Available Properties</h5>
                                        <p>These properties are available in the database.</p>
                                    </div>
                                    <div className="text-start mt-2">
                                        <h6 className='control-label'>Filter:</h6>
                                        <div className="row">
                                            <div className="form-group col-md-3">
                                                <label className='control-label'>Negotiable?</label>
                                                <select className='form-select'
                                                    onChange={(e) => {this.setState({negotiable: e.target.value})}}
                                                    value={this.state.negotiable}>
                                                    <option value="-1" selected>All</option>
                                                    <option value="0">Negotiable</option>
                                                    <option value="1">Non Negotiable</option>
                                                </select>
                                            </div>
                                            <div className="form-group col-md-3">
                                                <label className='control-label'>User Type</label>
                                                <select className='form-select'
                                                    onChange={(e) => {this.setState({user_type: e.target.value})}}
                                                    value={this.state.user_type}>
                                                    <option value="-1" selected>All</option>
                                                    <option value="0">Admin</option>
                                                    <option value="1">Seller</option>
                                                </select>
                                            </div>
                                            <div className="form-group col-md-3">
                                                <label className='control-label'>Status</label>
                                                <select className='form-select'
                                                    onChange={(e) => {this.setState({status: e.target.value})}}
                                                    value={this.state.status}>
                                                    <option value="-1" selected>All</option>
                                                    {
                                                        auth.property_statuses.map(status => 
                                                            <option value={auth.property_statuses.indexOf(status)}>{status}</option>
                                                        )
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="form-group col-md-4 float-start mb-2">
                                                <div className="input-group">
                                                    <input type="text" className='form-control' 
                                                        placeholder='Search by title, size, street, City, Zip, Country'
                                                        value={this.state.SearchValue}
                                                        onChange={(e)=>{this.setSearchValue(e.target.value)}}
                                                        onKeyDown={(e)=>{
                                                            if (e.key === "Enter") {
                                                                // Cancel the default action, if needed
                                                                e.preventDefault();
                                                                // Trigger the button element with a click
                                                                this.searchValue()
                                                              }}}
                                                    />
                                                    <span className="input-group-text btn btn-primary"
                                                        onClick={(e)=>{this.searchValue()}}
                                                        ><i className=' fa fa-search'></i></span>
                                                </div>
                                            </div>
                                            <div className="col-md-8 text-end pe-4">
                                                <label className='control-label mw-150 pe-2'>Sort By:</label>
                                                <select className='mw-150' 
                                                    onChange={(e) => this.setSortBy(e.target.value)}
                                                >
                                                    <option value="title" selected>Title</option>
                                                    <option value="price">Price</option>
                                                    <option value="size">Size</option>
                                                    <option value="status">Status</option>
                                                    <option value="time_posted">Creation Date</option>
                                                </select>
                                                <select className='mw-150'
                                                    onChange={(e) => this.setSortByOrder(e.target.value)}>
                                                    <option value="1" selected>Asc</option>
                                                    <option value="-1">Desc</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        {this.displayItems()}
                                        <hr />
                                        <ReactPaginate 
                                            previousLabel={'Previous'}
                                            nextLabel={'Next'}
                                            pageCount={this.pageCount()}
                                            onPageChange={this.changePage}
                                            previousLinkClassName={'btn btn-sm btn-primary'}
                                            nextLinkClassName={'btn btn-sm btn-primary'}
                                            activeClassName={'btn btn-sm btn-primary'}
                                            disabledClassName={'btn btn-sm disabled'}
                                            
                                        />
                                    </div>
                                    <div className="row p-2 mt-2" hidden>
                                    {
                                        this.state.properties.map(property => <div className="p-2 mt-2">
                                            <h6 className='card-header'>
                                                {property.title}
                                                <a href='#Delete' type='button' 
                                                    onClick={()=>{
                                                        this.deleteProperty(property.id, property.title)
                                                    }}
                                                    className='btn-sm btn-danger float-end'
                                                ><span className='fa fa-trash'></span></a>
                                                <a href={'Properties-Edit/' + property.id}
                                                    className='btn-sm btn-primary me-2 float-end'
                                                ><span className='fa fa-edit'></span></a>
                                                <a href={'Properties/' + property.id}
                                                    className='btn-sm btn-success me-2 float-end'
                                                ><span className='fa fa-eye'></span></a>
                                            </h6>
                                            <div className="row">
                                                <div className="form-group col-md-3">
                                                    <label className='control-label'>Price: </label>
                                                    <p className='description'>{property.price}</p>
                                                </div>                      
                                                <div className="form-group col-md-3">
                                                    <label className='control-label'>Size: </label>
                                                    <p className='description'>{property.size}</p>
                                                </div>
                                                <div className="form-group col-md-3">
                                                    <label className='control-label me-2'>
                                                        Negotiable: 
                                                    </label>
                                                    <div className='description row'>
                                                        <div className="col-1">
                                                            <input type='checkbox' className='form-control-sm' 
                                                                defaultChecked={property.negotiable}
                                                                onChange={() => {this.changePropertyNegotiable(property.id)}}
                                                            />
                                                        </div>
                                                        <div className="col-9">
                                                            {property.negotiable && 'Item is negotiable'}
                                                            {!property.negotiable && 'Item is not negotiable'}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div hidden>
                                                <h6 className='underline'>Available in:</h6>
                                                <div className="row">
                                                {
                                                    property.locations.map(location => <div className="p-2 col-md-6">
                                                        <p className='description'>{location.street}</p>
                                                        <p className='description'>{location.lga}, {location.state}</p>
                                                        <p className='description'>{location.country}, {location.zip}</p>
                                                        <hr />
                                                    </div>)
                                                }
                                                </div>
                                            </div>
                                        </div>)
                                    }
                                    </div>
                                </div>
                        </div>
                    </div>
                    <div className="text-start">
                        <a href='/Properties-Create' className=''>Add New Properties</a>
                    </div>
                    <div className='col-md-5 mt-4 mb-4 p-2'>
                        {/* <img src='' /> */}
                    </div>
                </div>
            </React.Fragment>
         );
    }
}