import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import auth from '../auth/auth';


export const RegisterComponent  = () =>  {
    
    const [username, setUsername]= useState();
    const [password, setPassword]= useState();
    const [cpassword, setCPassword]= useState();
    const [isBuyer, setisBuyer]= useState(true);
    const [message, setMessage]= useState('');
    const [passwordType, setPasswordType]= useState('password');
    const [redirect, setRedirect]= useState(false);
    const [isLoading, setIsLoading]= useState(false);

    useEffect(() => {
        
      }, []);

    const validatePassword = (e) => {
        var validated =  true;
        if(e.value.length < 8)
            validated = false;
        else if(!/\d/.test(e.value))
            validated = false;
        else if(!/[a-z]/.test(e.value))
            validated = false;
        else if(!/[A-Z]/.test(e.value))
            validated = false;
        else if(/[a-zA-Z0-9]{8,}/.test(e.value))
            validated = false;

        setMessage('');
        e.style.borderColor = "green";
        if(!validated){
            setMessage("Password must contain at least one digit, At least one lower case, At least one upper case, At least 8 from the mentioned characters");
            e.style.borderColor = "red";
        }
        return validated;
    }
    const handleSubmit = (btn) => {
        if(cpassword !== password){
            setMessage("Password does not match.");
            return;
        }
        const pass = document.getElementById('Password');
        pass.value = password;
        if(!validatePassword(pass)){
            return;
        }
        setIsLoading(true);
        var role = 'buyer';
        if(!isBuyer)
            role = 'seller';

        const userData = JSON.stringify({
            "email": username,
            "password": password,
            "role": role
        });
        //console.log(userData);
        try {
            fetch(auth.baseUrl() + `users/register`, {
                method: 'POST', 
                headers: {
                    'content-type': 'application/json'
                },
                credentials: 'include',
                body: userData
            }).then((response) => {
                setIsLoading(false);
                if(response.status === 200){
                    response.json().then(result => {
                        console.log(result);
                        if(result["error"] != null || result["error"] !== undefined){
                            setMessage(result.error);
                        }else if(result["access token"] != null || result["access token"] !== undefined){
                            setMessage("Successfull Registration");
                            localStorage.setItem('access_token', result['access token'])
                            auth.setLogin();
                            window.location.href = '/Update-Profile' ;
                            setRedirect(true);
                            return;
                        }else{
                            setMessage(result);
                        }
                    });
                }else{
                    setMessage("An error occured");
                }
            });
        } catch (error) {
            setMessage(error.toString().replace("TypeError: ", ""));
        }

        if(redirect){
            if(localStorage.getItem("navigating_to") != null){
                var ref = localStorage.getItem("navigating_to");
                localStorage.removeItem("navigating_to");
                window.location.href = ref;
            }else{
                window.location.href = '/Update-Profile' ;
            }
            return <Navigate to='/Update-Profile' replace />
        }
    }

    const togglePWD = (e) => {
        // toggle the type attribute
        const pwd = document.getElementById("Password");
        if(pwd==null) return;
        const newType = passwordType === "password" ? "text" : "password";
        setPasswordType(newType);
        // toggle the eye icon
        e.className = passwordType === "password" ? 'far fa-eye-slash' : 'far fa-eye';
    };
    return ( 
        <React.Fragment>
            <form name='frm' id='frm'>
                <div className='form-group justify-content-center d-flex'>
                    <div className="btn-group">
                        <label className="rdb-btn btn btn-outline-primary btn-primary text-white"
                            onClick={e=>{
                                setisBuyer(true);
                                var els = document.getElementsByClassName("rdb-btn");
                                for(var i=0; i<els.length;i++){
                                    els[i].className = "rdb-btn btn btn-outline-primary btn-primary text-white";
                                }
                                els = document.getElementsByClassName("rdb-btn2");
                                for(var j=0; j<els.length;j++){
                                    els[j].className = "rdb-btn2 btn btn-outline-primary";
                                }
                            }}
                        >
                            I am a Buyer
                        </label>

                        <label className="rdb-btn2 btn btn-outline-primary"
                            onClick={e=>{
                                setisBuyer(false);
                                var els = document.getElementsByClassName("rdb-btn2");
                                for(var i=0; i<els.length;i++){
                                    els[i].className = "rdb-btn2 btn btn-outline-primary btn-primary text-white";
                                }
                                els = document.getElementsByClassName("rdb-btn");
                                for(var j=0; j<els.length;j++){
                                    els[j].className = "rdb-btn btn btn-outline-primary";
                                }
                            }}>
                            I am a Seller
                        </label>
                    </div>
                </div>
                <div className='form-group'>
                    <label className='control-label lbl'>Email</label>
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1">@</span>
                        <input required className='form-control' type='email' 
                            name='Email' id='Email' placeholder='Enter Email'
                            value={username} autoFocus
                            onChange={e => setUsername(e.target.value)}
                            aria-describedby="basic-addon1"
                            />
                    </div>
                </div>
                <div className='form-group'>
                    <label className='control-label lbl'>Password</label>
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1"><i className='fa fa-key'></i></span>
                        <input required className='form-control' type={passwordType} 
                            name='Password' id='Password' placeholder='Enter Password'
                            value={password}
                            onChange={e => {setPassword(e.target.value); validatePassword(e.target)}}
                            aria-describedby="basic-addon1" data-toggle="password"
                            />
                        <span className="input-group-text"><i className="far fa-eye-slash" 
                            onClick={e=>{togglePWD(e.target)}}></i></span>
                    </div>
                </div>
                <div className='form-group'>
                    <label className='control-label lbl'>Confirm Password</label>
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1"><i className='fa fa-key'></i></span>
                        <input required className='form-control' type={passwordType} 
                            name='ConfirmPassword' id='ConfirmPassword' placeholder='Re-type Your Password'
                            value={cpassword}
                            onChange={e => setCPassword(e.target.value)}
                            aria-describedby="basic-addon1"
                            />
                    </div>
                </div>
                <div className='form-group'>
                    <div className="row text-center">
                        <input type='checkbox' checked required className='form-control-sm' />
                        <span className='text-dark'> Agree to our term and condition.</span>
                    </div>
                </div>
                <hr className='hr'/>
                <div className='form-group text-center'>
                    {isLoading &&
                        <button 
                            type='button'
                            className='btn btn-primary btn-5'>
                            <i className='fa fa-spinner fa-spin'></i> Submiting...
                        </button>
                    }{!isLoading &&
                        <button 
                            className='btn btn-primary btn-5'
                            onClick={e => {
                                e.preventDefault();
                                return handleSubmit(this)
                            }}>Register</button>
                    }
                    <div className='text-warning'>
                        {message}
                    </div>
                </div>
            </form>
        </React.Fragment>
    );
}