import { Carousel } from 'react-bootstrap';
import React, { Component } from 'react';
import auth from '../../auth/auth';
import { LoaderComponent } from '../loaderComponent';


export default class ProposalDetailsComponent extends Component {
    constructor(props) {
        super(props);
        //this.myRef = React.createRef();
    }
    state = { 
        step: 0, proposal_id: this.props.proposal_id,
        carouselIndex: 0,
        isLoading: false, isUpLoading: false, userId:'', msgS: '', msgF: '',
        proposal: []
    }

    componentDidMount() {
        this.setState({isLoading: true});
        auth.checkAuthentication().then(() => {
        });
        (async () => {
            //alert(this.props.proposal_id)
            this.setState({proposal_id: this.props.proposal_id});
            if(this.props.proposal_id == null || this.props.proposal_id === undefined) return

            var result = await auth.GetData('proposals/' + this.props.proposal_id, 'GET');
            console.log(result);
            if(result["Error"] != null || result["Error"] !== undefined){
                this.setState({msgF: result.Error});
                this.setState({isLoading: false});
            }else{
                var proposal = this.state.proposal;
                proposal = result;
                proposal.StatusClass = this.getStatusClass();
                proposal.msg = '';
                this.setState({proposal});
            }
        })().then(()=>{this.setState({isLoading: false})});
    }
    
    nextStep = () => {
        if(this.state.step < 2){
            this.setState({step: this.state.step + 1})
        }
    }
    
    prevStep = () => {
        if(this.state.step > 0){
            this.setState({step: this.state.step - 1})
        }
    }

    deleteproposal(proposal_id, proposal_title){
        if(auth.userRole !== 'admin')
            return;
        (async () => {
            this.setState({msgF: ""})
            this.setState({msgS: ""})
            var answer = window.confirm("Delete " + proposal_title + "?");
            if(answer){
                this.setState({isUpLoading: true});
                var result = await auth.PostData(null, `proposals/` + proposal_id, 'DELETE');
                //console.log(result);
                if(result["Error"] != null || result["Error"] !== undefined){
                    this.setState({msgF: result.Error});
                    this.setState({isLoading: false});
                }if(result['message'] === 'success'){
                    this.setState({msgS: "Proposal deleted."})
                    this.setState({isUpLoading: false});
                    window.location.href = "/proposals";
                }else{
                    this.setState({msgF: result.statusText})
                }
                this.setState({isUpLoading: false});
            }
        })().then(()=>{this.setState({isLoading: false})});
    }


    handleSelect = (selectedIndex, e) => {
        this.setState({carouselIndex: selectedIndex});
    };


    clearproposalMsg(){
        const proposal = this.state.proposal;
        proposal.msg = '';
        this.setState({proposal});
    }

    setproposalStatus(e){
        const proposal = this.state.proposal;
        proposal.status = e.target.value;
        proposal.isPendingChange = true;
        proposal.StatusClass = this.getStatusClass();
        this.setState({proposal});
        //console.log(this.state.proposals);
        //e.target.className = 'form-control border-primary';
    }

    getStatusClass = () => {
        var className = 'form-select'
        if(this.state.proposal.isPendingChange && auth.userRole !== 'admin') className = 'form-control border-primary';
        if(this.state.proposal.isPendingChange && auth.userRole === 'admin') className = 'form-select border-primary';
        if(this.state.proposal.isUpLoading) className = 'form-select border-warning';
        if(this.state.proposal.isChangeSuccessfull) className = 'form-select border-success';
        return className;
    }

    changeproposalStatus(e){
        (async () => {
            this.setState({msgF: ""})
            this.setState({msgS: ""})
            const proposal = this.state.proposal;
            proposal.isUpLoading = true;
            proposal.isPendingChange = false;
            proposal.isUpLoading = true;
            proposal.msg = '';
            proposal.StatusClass = this.getStatusClass();
            this.setState({proposal});
            e.disabled = true;
            var classname = e.className;
            e.className = 'fa fa-spin fa-spinner';
            var result = await auth.PostData(JSON.stringify({
                    "proposal": "status",
                    "value": proposal.status
                }), 
                `proposals/change_status/` + this.state.proposal_id, 'POST');
            //console.log(result);
            if(result["Error"] != null || result["Error"] !== undefined){
                this.setState({msgF: result.Error});
                this.setState({isLoading: false});
            }if(result['message'] === 'success'){
                proposal.isChangeSuccessfull = true;
                proposal.isPendingChange = false;
                proposal.msg = 'Status Changed';
                this.setState({msgS: "proposal's Status Changed"})
            }else{
                proposal.isChangeSuccessfull = false;
                proposal.msg = result.statusText;
                this.setState({msgF: result.statusText})
            }
            e.className = classname;
            proposal.StatusClass = this.getStatusClass();
            proposal.isUpLoading = false;
            this.setState({proposal});
            e.disabled = false;
        })().then(()=>{this.setState({isLoading: false})});
    }


    render() { 
        if(this.state.isLoading) 
            return (
                <LoaderComponent />
            );
        if(this.state.msgF !== "") 
            return (
                <React.Fragment>
                    <div className='card-header text-center p-5 m-5'>
                        <h4 className='text-danger'>{this.state.msgF}</h4>
                    </div>
                </React.Fragment>
            );

        const proposalDetails = this.state.proposal;
        //if(this.state.step === 0)
        //    return(
        //    )
        return ( 
            <React.Fragment>
                <div className='card-header text-center pt-4'>
                    <h4 className='text-primary'><u>Proposal Details</u></h4>
                    <h5>({proposalDetails.name})</h5>
                    <hr />
                    <p>Below are details of this proposal.</p>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='shadow m-2 p-2'>
                                <div className="card-body">
                                    <h6 className='text-center card-header p-2 mt-2'>
                                        Basic Info
                                        {
                                            auth.userRole === "admin" && <React.Fragment>
                                                <a href='#Delete' type='button' 
                                                    onClick={()=>{
                                                        this.deleteproposal(this.state.proposal_id, proposalDetails.title)
                                                    }}
                                                    className='btn-sm btn-danger float-end'
                                                ><span className='fa fa-trash'></span></a>
                                                <a href={'Edit/' + this.state.proposal_id}
                                                    className='btn-sm btn-primary float-end me-2'
                                                    hidden
                                                ><span className='fa fa-edit'></span></a>
                                                <a href={'/Support-Admin/Proposal/' + this.state.proposal_id} type='button' 
                                                    className='btn-sm btn-success float-end me-2'
                                                    hidden
                                                ><span className='fa fa-info-circle me-2'></span>Contact User</a>
                                            </React.Fragment>
                                        }
                                    </h6>
                                    <div className="form-group">
                                        <p className='text-success'>{this.state.msgS}</p>
                                        <p className='text-danger'>{this.state.msgF}</p>
                                    </div>
                                    <div className="row">                        
                                        <div className="form-group col-md-4">
                                            <label className='control-label'>Names: </label>
                                            <p className='description'>{proposalDetails.name}</p>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label className='control-label'>Email: </label>
                                            <p className='description'>{proposalDetails.email}</p>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label className='control-label'>Phone: </label>
                                            <p className='description'>{proposalDetails.phone}</p>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label className='control-label'>Country: </label>
                                            <p className='description'>{proposalDetails.country}</p>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label className='control-label'>State: </label>
                                            <p className='description'>{proposalDetails.state}</p>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label className='control-label'>City: </label>
                                            <p className='description'>{proposalDetails.city}</p>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label className='control-label'>Zip: </label>
                                            <p className='description'>{proposalDetails.zip}</p>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label className='control-label'>Price Start: </label>
                                            <p className='description'>{
                                                auth.formatter.format(proposalDetails.priceStart)
                                            }</p>
                                        </div>      
                                        <div className="form-group col-md-4">
                                            <label className='control-label'>Price Stop: </label>
                                            <p className='description'>{
                                                auth.formatter.format(proposalDetails.priceStop)
                                            }</p>
                                        </div>                   
                                        <div className="form-group col-md-4">
                                            <label className='control-label'>Size: </label>
                                            <p className='description'>{proposalDetails.size}</p>
                                        </div>
                                        {
                                            <React.Fragment>
                                                <div className="form-group col-md-4">
                                                    <label className='control-label me-2'>
                                                        Status: 
                                                    </label>
                                                    {
                                                        auth.userRole === 'admin' &&
                                                        <div className="input-group mb-3">
                                                            <select className={proposalDetails.StatusClass}
                                                                defaultValue={proposalDetails.status}
                                                                onChange={e => {this.setproposalStatus(e)}}
                                                            >
                                                                {
                                                                    auth.proposal_statuses.map(status => 
                                                                        <option value={auth.proposal_statuses.indexOf(status)}>{status}</option>
                                                                    )
                                                                }
                                                            </select>
                                                            <span className="input-group-text text-primary" title='Save Changes'
                                                                onClick={(e) => {this.changeproposalStatus(e.target)}}
                                                            >
                                                                <i className='fa fa-save'></i>
                                                            </span>
                                                        </div>
                                                    }
                                                    {
                                                        auth.userRole !== 'admin' &&
                                                        <div className="input-group mb-3">
                                                            <input disabled className={proposalDetails.StatusClass}
                                                                defaultValue={auth.proposal_statuses[proposalDetails.status]} />
                                                        </div>
                                                    }
                                                    {
                                                        proposalDetails.msg !== "" && proposalDetails.isChangeSuccessfull && <div className='p-2 shadow border-secondary alert alert-primary d-flex align-items-center alert-dismissible fade show' role="alert">
                                                            <button type="button" className="p-2 btn-sm btn-close" 
                                                                onClick={() => this.clearproposalMsg()}
                                                            // <button type="button" className="p-2 btn-sm btn-close" data-bs-dismiss="alert" 
                                                                aria-label="Close" title='Close'></button>
                                                            {proposalDetails.msg}
                                                        </div>
                                                    }
                                                    {
                                                        proposalDetails.msg !== "" && !proposalDetails.isChangeSuccessfull && <div className='p-2 shadow border-secondary alert alert-danger d-flex align-items-center alert-dismissible fade show' role="alert">
                                                            <button type="button" className="p-2 btn-sm btn-close" 
                                                                onClick={() => this.clearproposalMsg()}
                                                            // <button type="button" className="p-2 btn-sm btn-close" data-bs-dismiss="alert" 
                                                                aria-label="Close" title='Close'></button>
                                                            {proposalDetails.msg}
                                                        </div>
                                                    }
                                                </div>
                                            </React.Fragment>
                                        }                      
                                        <div className="form-group">
                                            <label className='control-label'>Description: </label>
                                            <div  style={{minHeight: '150px'}}
                                                className='description form-control' disabled
                                                dangerouslySetInnerHTML={{ __html: proposalDetails.description }}
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                    <div className='text-center mt-4 border-top pt-2 bg-light m-0 p-2 card-footer'>
                        <div className='col-md-12'>
                            { 
                                this.state.isUpLoading && <button disabled
                                    className='btn btn-primary col-md-4'>
                                    <i className='fa fa-spinner fa-spin me-2'></i>
                                    Updating proposal...</button>
                            }
                        </div>
                        <div className="form-group">
                            <p className='text-success'>{this.state.msgS}</p>
                            <p className='text-danger'>{this.state.msgF}</p>
                        </div>
                        {
                            auth.userRole === "admin" &&  <React.Fragment>
                                <a href={'/Support-Admin/Proposal/' + this.state.proposal_id} type='button' 
                                    className='btn-sm btn-success float-start ms-2 me-2'
                                    hidden
                                ><span className='fa fa-info-circle me-2'></span>Enquire More</a> | 
                                <a href='/Search-proposals' className=''>Back To Proposals</a>
                            </React.Fragment>
                        }
                        {
                            auth.userRole !== "admin" &&  <React.Fragment>
                                <a href='/' className=''>Back To Home</a>
                            </React.Fragment>
                        }
                    </div>
                    <div className='col-md-5 mt-4 mb-4 p-2'>
                        {/* <img src='' /> */}
                    </div>
                </div>
            </React.Fragment>
         );
    }
}