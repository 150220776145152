import React, { Component } from 'react';
import auth from '../../auth/auth';
import friendlyTime from 'friendly-time';
// import defaultPropertyLogo from '../../images/property5.jpg'
import defaultPropertyLogo from '../../images/default.jpg'

class PropertyComponent extends Component {
    constructor (id = 0, title = "", description = "", price = 0, negotiable = false,
        time_posted, locations = [], graphics = []
    ){
        super();
        this.id = id;
        this.title = title;
        this.description = description;
        this.price = price;
        this.negotiable = negotiable;
        this.time_posted = time_posted;
        this.locations = locations;
        this.graphics = graphics;
    }
    state = { 
        bgimage: defaultPropertyLogo,
        styleClass: {
             backgroundImage: "url(" + defaultPropertyLogo + ")"
        }
    } 

    componentDidMount(){
        this.GetImageData();
    }
    GetImageData() {
        var returnValue = defaultPropertyLogo;
        this.props.value.graphics.forEach((gra) => {
            if(gra.type == 1){
                returnValue = auth.baseUrl() + 'property_graphics/' + gra.id;
                this.setState({bgimage: returnValue});
                this.setState({styleClass: {
                    backgroundImage: "url(" + returnValue + ")"
               }})
            }
        })

        return returnValue;
    }

    render() { 
        //const {value, onDelete, onIncreament} = this.props;
        const {value} = this.props;
        // const {slickerindex} = this.props;
        return (<div animation-type='bounce'  class="animated-item animated bounce ">
                {
                    value.locations.map(location =>
                    <a href={this.GetUrl()} className='colmd3 recentproperty p-2 text-center'>
                        <div className="bg-light">
                            <div className="rounded card-body p-2 recent-properties"
                                style={this.state.styleClass}
                            >
                                <p className='posted-time'>New - {this.GetPostedTime()}</p>
                                {/* {<img src={this.GetImageData()} alt={value.title} /> } */}
                            </div>
                            <h6>{value.title}</h6>
                            <h4 className='recentproperty-amount'>{auth.formatter.format(value.price)}</h4>
                            <p>{value.size}</p>
                            <hr/>                     
                            <p className='description m-0'>{location.street}</p>
                            <p className='description m-0'>{location.lga}, {location.state}</p>
                            <p className='description m-0'>{location.country}, {location.zip}</p>
                        </div>
                    </a>) 
                }
            </div>
        );
    }

    GetPostedTime() {
        // var d = auth.createFromMysql(this.props.value.time_posted);
        // if(d==null) d= this.props.value.time_posted;
        // return this.timeSince(d) + " ago";
        var d = new Date(this.props.value.time_posted);
        // alert(d + ", " + Date.now());
        return friendlyTime(d);
    }
    timeSince(date) {

        var seconds = Math.floor((new Date() - date) / 1000);
      
        var interval = seconds / 31536000;
      
        if (interval > 1) {
          return Math.floor(interval) + " yrs";
        }
        interval = seconds / 2592000;
        if (interval > 1) {
          return Math.floor(interval) + " months";
        }
        interval = seconds / 86400;
        if (interval > 1) {
          return Math.floor(interval) + " days";
        }
        interval = seconds / 3600;
        if (interval > 1) {
          return Math.floor(interval) + " hrs";
        }
        interval = seconds / 60;
        if (interval > 1) {
          return Math.floor(interval) + " mins";
        }
        return Math.floor(seconds) + " secs";
      }

    GetUrl = () => {
        //console.log(this.props.value.graphics[0][1]);
        return '/Search-Properties/' + this.props.value.id;
    }
    GetLocations() {
        let str = "";
        this.props.value.locations.forEach(loc => {
            console.log(loc)
            if(loc != null && loc !== "") {
                if(str !== "")
                    str = str + ", ";
                    
                str = str + loc;                
            }
        })
        this.GetImageData();
        return str;
    }
}
 
export default PropertyComponent;