import React, { Component } from 'react';

class PropertyGraphicsForm extends Component {
    constructor(props) {
        super(props);
    }
    state = {  }
    render() { 
        const {PropertyGraphic, handleChange, onDelete} = this.props;
        return ( 
            <div className='col-md-6'>
            <div className='card m-2 p-0'>
                <h6 className='card-header p-2'>Attachment {PropertyGraphic.id}
                    <button type='button'
                        className='btn btn-danger float-end'
                        onClick={() => onDelete(PropertyGraphic.id)}
                    ><span className='fa fa-trash'></span></button></h6>
                <div className="row p-2">                        
                    <div className="form-group col-md-12">
                        <label className='control-label'>Add Image/Video: </label>
                        <input type='file' className='form-control' 
                            accept="image/png, image/gif, image/jpeg, image/bmp, video/mp4" 
                            defaultValue={PropertyGraphic.selectedValue} autoFocus
                            onChange={handleChange(PropertyGraphic.id, 'selectedValue')}
                        />
                        <textarea required className='textarea2 textarea mt-2' 
                            placeholder='Enter Attachment Description' 
                            defaultValue={PropertyGraphic.comment}
                            onChange={handleChange(PropertyGraphic.id, 'comment')}>
                        </textarea>
                        <p className='text-primary'>{PropertyGraphic.msg}</p>
                    </div>
                </div>
            </div>
            </div>
         );
    }
}
 
export default PropertyGraphicsForm;