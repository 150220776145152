import { Carousel } from 'react-bootstrap';
import React, { Component } from 'react';
import auth from '../../auth/auth';
import { LoaderComponent } from '../loaderComponent';


export class Location{
    constructor(id, state, street, lga, country, zip) {
        this.id= id;
        this.state= state;
        this.street= street;
        this.lga= lga;
        this.country= country;
        this.zip=zip
    }

}

export class Graphic{
    constructor(id, url,msg,type,comment) {
        this.id= id;
        this.url= url;
        this.type= type;
        this.msg=msg;
        this.comment=comment
    }

}

class PropertyDetailsComponent extends Component {
    constructor(props) {
        super(props);
        //this.myRef = React.createRef();
    }
    state = { 
        step: 0, property_id: this.props.property_id, isInCart: false,
        selectedGraphic: new Graphic(0,"","",0,""), carouselIndex: 0,
        isLoading: false, isUpLoading: false, userId:'', msgS: '', msgF: '',
        locations: [],
        graphics: [],
        property: []
    }

    componentDidMount() {
        this.setState({isLoading: true});
        auth.checkAuthentication().then(() => {
        });
        (async () => {
            //alert(this.props.property_id)
            this.setState({property_id: this.props.property_id});
            if(this.props.property_id == null || this.props.property_id === undefined) return

            var result = await auth.GetData('properties/' + this.props.property_id, 'GET');
            // console.log(result);
            if(result["Error"] != null || result["Error"] !== undefined){
                this.setState({msgF: result.Error});
                this.setState({isLoading: false});
            }else{
                if(result["details"] != null || result["details"] !== undefined){
                    var property = this.state.property;
                    property = result.details;
                    property.StatusClass = this.getStatusClass();
                    property.msg = '';
                    this.setState({property});

                    var resultC = await auth.GetData('users/is_property_in_cart/' + this.props.property_id, 'GET');
                    // console.log(resultC);
                    if(resultC != null || resultC !== undefined){
                        if(resultC.message === 'true'){
                            this.setState({isInCart: resultC})
                        }
                    }
                    
                }
                if(result["locations"] != null || result["locations"] !== undefined){
                    const {locations} = this.state;
                    result["locations"].forEach(loc => {
                        locations.push(loc)
                    })
                    this.setState({locations});
                }
                if(result["graphics"] != null || result["graphics"] !== undefined){
                    const {graphics} = this.state;
                    result["graphics"].forEach(g => {
                        if(g.comment === undefined)
                            graphics.push(new Graphic(g.id,g.url,'',g.type,''));
                        else
                            graphics.push(new Graphic(g.id,g.url,'',g.type,g.comment));
                    })
                    this.setState({graphics});
                }
            }
        })().then(()=>{this.setState({isLoading: false})});
    }
    
    nextStep = () => {
        if(this.state.step < 2){
            this.setState({step: this.state.step + 1})
        }
    }
    
    prevStep = () => {
        if(this.state.step > 0){
            this.setState({step: this.state.step - 1})
        }
    }

    deleteProperty(property_id, property_title){
        if(auth.userRole !== 'admin')
            return;
        (async () => {
            this.setState({msgF: ""})
            this.setState({msgS: ""})
            var answer = window.confirm("Delete " + property_title + "?");
            if(answer){
                this.setState({isUpLoading: true});
                var result = await auth.PostData(null, `properties/` + property_id, 'DELETE');
                //console.log(result);
                if(result["Error"] != null || result["Error"] !== undefined){
                    this.setState({msgF: result.Error});
                    this.setState({isLoading: false});
                }if(result['message'] === 'success'){
                    this.setState({msgS: "Property deleted"})
                    this.setState({isUpLoading: false});
                    window.location.href = "/Properties";
                }else{
                    this.setState({msgF: result.statusText})
                }
                this.setState({isUpLoading: false});
            }
        })().then(()=>{this.setState({isLoading: false})});
    }


    removeProperty(property_id){
        (async () => {
            this.setState({msgF: ""})
            this.setState({msgS: ""})
            var answer = window.confirm("Remove this property from cart?");
            if(answer){
                this.setState({isUpLoading: true});
                var result = await auth.PostData(null, `users/remove_from_cart/` + property_id, 'DELETE');
                //console.log(result);
                if(result["Error"] != null || result["Error"] !== undefined){
                    this.setState({msgF: result.Error});
                    this.setState({isLoading: false});
                }if(result['message'] === 'success'){
                    this.setState({msgS: "Property removed from cart"})
                    this.setState({isUpLoading: false});
                    this.setState({isInCart: false})
                }else{
                    this.setState({msgF: result.statusText})
                }
                this.setState({isUpLoading: false});
            }
        })().then(()=>{this.setState({isLoading: false})});
    }


    addProperty(property_id){
        if(!auth.isAuthenticated){
            localStorage.setItem("navigating_to", window.location.href);
            window.location.href = '/Login';
        }
        (async () => {
            this.setState({msgF: ""})
            this.setState({msgS: ""})
            var answer = window.confirm("Add this property to cart?");
            if(answer){
                this.setState({isUpLoading: true});
                var result = await auth.PostData(null, `users/add_to_cart/` + property_id, 'POST');
                //console.log(result);
                if(result["Error"] != null || result["Error"] !== undefined){
                    this.setState({msgF: result.Error});
                    this.setState({isLoading: false});
                }if(result['message'] === 'success'){
                    this.setState({msgS: "Property added to cart"})
                    this.setState({isUpLoading: false});
                    this.setState({isInCart: true})
                }else{
                    this.setState({msgF: result.statusText})
                }
                this.setState({isUpLoading: false});
            }
        })().then(()=>{this.setState({isLoading: false})});
    }


    deletePropertyGraphics(graphic_id){
        (async () => {
            this.setState({msgF: ""})
            this.setState({msgS: ""})
            var answer = window.confirm("Delete attachment?");
            if(answer){
                this.setState({isUpLoading: true});
                var result = await auth.PostData(null, `properties/delete_attachment/` + graphic_id, 'DELETE');
                console.log(result);
                // if(result.status)
                if(result["Error"] != null || result["Error"] !== undefined){
                    this.setState({msgF: result.Error});
                    this.setState({isLoading: false});
                }if(result['message'] === 'success'){
                    this.setState({msgS: "Property attachment deleted"})
                    this.setState({isUpLoading: false});
                    const {graphics} = this.state;
                    const graphic = graphics.filter((x)=>x.id !== graphic_id)
                    graphics.pop(graphic);
                    this.setState({graphics});
                }else{
                    this.setState({msgF: result.statusText})
                }
                this.setState({isUpLoading: false});
            }
        })().then(()=>{this.setState({isLoading: false})});
    }

    changePropertyNegotiable(property_id){
        (async () => {
            this.setState({msgF: ""})
            this.setState({msgS: ""})
            this.setState({isUpLoading: true});
            var result = await auth.PostData(null, 
                `properties/change_negotiable/` + this.props.property_id, 'POST');
            //console.log(result);
            if(result["Error"] != null || result["Error"] !== undefined){
                this.setState({msgF: result.Error});
                this.setState({isLoading: false});
            }if(result['message'] === 'success'){
                this.setState({msgS: "Property Negotiation Changed"})
                this.setState({isUpLoading: false});
                this.setState({negotiable: !this.state.negotiable})
            }else{
                this.setState({msgF: result.statusText})
            }
            this.setState({isUpLoading: false});
        })().then(()=>{this.setState({isLoading: false})});
    }

    changeSelectedGraphic(graphic_id){        
        const {graphics} = this.state;
        graphics.forEach(g => {
            if(g.id===graphic_id){
                this.setState({selectedGraphic: g});
                return;
            }
        })
        //this.setState({graphics});
    }
    
    handleSelect = (selectedIndex, e) => {
        this.setState({carouselIndex: selectedIndex});
    };


    clearPropertyMsg(){
        const property = this.state.property;
        property.msg = '';
        this.setState({property});
    }

    setPropertyStatus(e){
        const property = this.state.property;
        property.status = e.target.value;
        property.isPendingChange = true;
        property.StatusClass = this.getStatusClass();
        this.setState({property});
        //console.log(this.state.properties);
        //e.target.className = 'form-control border-primary';
    }

    getStatusClass = () => {
        var className = 'form-select'
        if(this.state.property.isPendingChange && auth.userRole !== 'admin') className = 'form-control border-primary';
        if(this.state.property.isPendingChange && auth.userRole === 'admin') className = 'form-select border-primary';
        if(this.state.property.isUpLoading) className = 'form-select border-warning';
        if(this.state.property.isChangeSuccessfull) className = 'form-select border-success';
        return className;
    }

    changePropertyStatus(e){
        (async () => {
            this.setState({msgF: ""})
            this.setState({msgS: ""})
            const property = this.state.property;
            property.isUpLoading = true;
            property.isPendingChange = false;
            property.isUpLoading = true;
            property.msg = '';
            property.StatusClass = this.getStatusClass();
            this.setState({property});
            e.disabled = true;
            var classname = e.className;
            e.className = 'fa fa-spin fa-spinner';
            var result = await auth.PostData(JSON.stringify({
                    "property": "status",
                    "value": property.status
                }), 
                `properties/change_status/` + this.state.property_id, 'POST');
            //console.log(result);
            if(result["Error"] != null || result["Error"] !== undefined){
                this.setState({msgF: result.Error});
                this.setState({isLoading: false});
            }if(result['message'] === 'success'){
                property.isChangeSuccessfull = true;
                property.isPendingChange = false;
                property.msg = 'Status Changed';
                this.setState({msgS: "Property's Status Changed"})
            }else{
                property.isChangeSuccessfull = false;
                property.msg = result.statusText;
                this.setState({msgF: result.statusText})
            }
            e.className = classname;
            property.StatusClass = this.getStatusClass();
            property.isUpLoading = false;
            this.setState({property});
            e.disabled = false;
        })().then(()=>{this.setState({isLoading: false})});
    }


    render() { 
        if(this.state.isLoading) 
            return (
                <LoaderComponent />
            );
        if(this.state.msgF !== "") 
            return (
                <React.Fragment>
                    <div className='card-header text-center p-5 m-5'>
                        <h4 className='text-danger'>{this.state.msgF}</h4>
                    </div>
                </React.Fragment>
            );

        const PropertyDetails = this.state.property;
        const {locations} = this.state;
        const {graphics} = this.state;
        //if(this.state.step === 0)
        //    return(
        //    )
        return ( 
            <React.Fragment>
                <div className="modal fade modal-bg" id="imgViewModal" tabindex="-1" aria-labelledby="imgViewModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-fullscreen shadow">
                        <div className="modal-content border shadow">
                            <div className="modal-header">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                            {
                                this.state.selectedGraphic.type === '1' && 
                                <div className='justify-content-center'>
                                    <div className="text-center">
                                        <img src={auth.baseUrl() + 'property_graphics/' + this.state.selectedGraphic.id} 
                                            alt="" className='img-thumbnail rounded' />
                                    </div>
                                </div>
                            }
                            {
                                this.state.selectedGraphic.type === '2' && 
                                <div className='justify-content-center'>
                                    <div className="text-center">
                                        <img src={auth.baseUrl() + 'property_graphics/' + this.state.selectedGraphic.id} 
                                            alt="" className='img-thumbnail rounded' />
                                            <video width="80%" height="80%" controls 
                                                className='img-thumbnail rounded graphic' >
                                                <source src={auth.baseUrl() + 'property_graphics/' + 
                                                    this.state.selectedGraphic.id}  type="video/mp4" />
                                                <source src="movie.ogg" type="video/ogg" />
                                                <span>Your browser does not support the video tag.</span>
                                            </video> 
                                    </div>
                                </div>
                            }
                            </div>
                        </div>
                    </div>
                </div>
                <div className='card-header text-center pt-4'>
                    <h4 className='text-primary'><u>Property Details</u></h4>
                    <h5>({PropertyDetails.title})</h5>
                    <hr />
                    <p>Below are details of this property including images and/or videos if available.</p>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='shadow m-2 p-2'>
                                <div className="card-body">
                                    <h6 className='text-center card-header p-2 mt-2'>
                                        <div className='row'>
                                            <div className="text-start col-5">Basic Info</div>
                                            <div className="col-7">                                                
                                                <div className="">                                                
                                                    {
                                                        !this.props.view_only && <React.Fragment>
                                                            <a href='#Delete' type='button' 
                                                                onClick={()=>{
                                                                    this.deleteProperty(this.state.property_id, PropertyDetails.title)
                                                                }}
                                                                className='btn-sm btn-danger float-end m-2'
                                                            ><span className='fa fa-trash'></span></a>
                                                            <a href={'Edit/' + this.state.property_id}
                                                                className='btn-sm btn-primary float-end m-2'
                                                            ><span className='fa fa-edit'></span></a>
                                                        </React.Fragment>
                                                    }
                                                    {
                                                        this.props.view_only && this.state.isInCart && <React.Fragment>
                                                            <a href='#RemoveFromCart' type='button' 
                                                                onClick={()=>{
                                                                    this.removeProperty(this.state.property_id)
                                                                }}
                                                                className='btn-sm btn-warning float-end m-2'
                                                            ><span className='fa fa-cart-plus me-2'></span>Remove</a>
                                                        </React.Fragment>
                                                    }
                                                    {
                                                        this.props.view_only && !this.state.isInCart && <React.Fragment>
                                                            <a href='#AddToCart' type='button' 
                                                                onClick={()=>{
                                                                    this.addProperty(this.state.property_id)
                                                                }}
                                                                className='btn-sm btn-primary float-end m-2'
                                                            ><span className='fa fa-cart-plus me-2'></span>Add</a>
                                                        </React.Fragment>
                                                    }
                                                    {
                                                        this.props.view_only && <React.Fragment>
                                                            <a href={'/Support/' + this.state.property_id} type='button' 
                                                                className='btn-sm btn-success float-end m-2'
                                                            ><span className='fa fa-info-circle me-2'></span>Enquire More</a>
                                                        </React.Fragment>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </h6>
                                    <div className="form-group">
                                        <p className='text-success'>{this.state.msgS}</p>
                                        <p className='text-danger'>{this.state.msgF}</p>
                                    </div>
                                    <div className="row">                        
                                        <div className="form-group col-md-4">
                                            <label className='control-label'>Title: </label>
                                            <p className='description'>{PropertyDetails.title}</p>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label className='control-label'>Price: </label>
                                            <p className='description'>{
                                                auth.formatter.format(PropertyDetails.price)
                                            }</p>
                                        </div>                      
                                        <div className="form-group col-md-4">
                                            <label className='control-label'>Size: </label>
                                            <p className='description'>{PropertyDetails.size}</p>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label className='control-label me-2'>Negotiable: </label>
                                                <div className='description row'>
                                                {
                                                    !this.props.view_only && <React.Fragment>
                                                        <div className="col-1">
                                                            <input type='checkbox' className='form-control-sm' 
                                                                defaultChecked={PropertyDetails.negotiable}
                                                                onChange={() => {this.changePropertyNegotiable(PropertyDetails.id)}}
                                                            />
                                                        </div>
                                                    </React.Fragment>
                                                }
                                                    <div className="col-9">
                                                        {PropertyDetails.negotiable && 'Item is negotiable'}
                                                        {!PropertyDetails.negotiable && 'Item is not negotiable'}
                                                    </div>
                                                </div>
                                        </div>
                                        {
                                            !this.props.view_only && <React.Fragment>
                                                <div className="form-group col-md-4">
                                                    <label className='control-label me-2'>
                                                        Status: 
                                                    </label>
                                                    {
                                                        auth.userRole === 'admin' &&
                                                        <div className="input-group mb-3">
                                                            <select className={PropertyDetails.StatusClass}
                                                                defaultValue={PropertyDetails.status}
                                                                onChange={e => {this.setPropertyStatus(e)}}
                                                            >
                                                                {
                                                                    auth.property_statuses.map(status => 
                                                                        <option value={auth.property_statuses.indexOf(status)}>{status}</option>
                                                                    )
                                                                }
                                                            </select>
                                                            <span className="input-group-text text-primary" title='Save Changes'
                                                                onClick={(e) => {this.changePropertyStatus(e.target)}}
                                                            >
                                                                <i className='fa fa-save'></i>
                                                            </span>
                                                        </div>
                                                    }
                                                    {
                                                        auth.userRole !== 'admin' &&
                                                        <div className="input-group mb-3">
                                                            <input disabled className={PropertyDetails.StatusClass}
                                                                defaultValue={auth.property_statuses[PropertyDetails.status]} />
                                                        </div>
                                                    }
                                                    {
                                                        PropertyDetails.msg !== "" && PropertyDetails.isChangeSuccessfull && <div className='p-2 shadow border-secondary alert alert-primary d-flex align-items-center alert-dismissible fade show' role="alert">
                                                            <button type="button" className="p-2 btn-sm btn-close" 
                                                                onClick={() => this.clearPropertyMsg()}
                                                            // <button type="button" className="p-2 btn-sm btn-close" data-bs-dismiss="alert" 
                                                                aria-label="Close" title='Close'></button>
                                                            {PropertyDetails.msg}
                                                        </div>
                                                    }
                                                    {
                                                        PropertyDetails.msg !== "" && !PropertyDetails.isChangeSuccessfull && <div className='p-2 shadow border-secondary alert alert-danger d-flex align-items-center alert-dismissible fade show' role="alert">
                                                            <button type="button" className="p-2 btn-sm btn-close" 
                                                                onClick={() => this.clearPropertyMsg()}
                                                            // <button type="button" className="p-2 btn-sm btn-close" data-bs-dismiss="alert" 
                                                                aria-label="Close" title='Close'></button>
                                                            {PropertyDetails.msg}
                                                        </div>
                                                    }
                                                </div>
                                            </React.Fragment>
                                        }                      
                                        <div className="form-group">
                                            <label className='control-label'>Description: </label>
                                            <div  style={{minHeight: '150px'}}
                                                className='description form-control' disabled
                                                dangerouslySetInnerHTML={{ __html: PropertyDetails.description }}
                                                >
                                            </div>
                                        </div>
                                    </div>
                                    <div className='text-center card-header p-2 mt-2'>
                                        <h6>Property Locations</h6>
                                        <p>This property is available in the following locations.</p>
                                    </div>
                                    <div className="row p-2 mt-2">                        
                                    {
                                            locations.map(location => <div className="col-md-4">
                                                <p className='description'>{location.street}</p>
                                                <p className='description'>{location.lga}, {location.state}</p>
                                                <p className='description'>{location.country}, {location.zip}</p>
                                                <hr />
                                            </div>) 
                                    }
                                    </div>
                                    {
                                        graphics.length >= 1 &&
                                        <React.Fragment>
                                            <h6 className='card-header p-2'>Attachments</h6>
                                            <div className="justify-content-center">
                                                <div className='justify-content-center'>
                                                    <Carousel variant="dark" activeIndex={this.state.carouselIndex} onSelect={this.handleSelect}>
                                                    {
                                                        graphics.map(graphic => 
                                                            <Carousel.Item>
                                                                <div className="text-center">
                                                                    {
                                                                        graphic.type === '1' && 
                                                                        <img src={auth.baseUrl() + 'property_graphics/' + graphic.id} 
                                                                            alt="" className='img-thumbnail rounded graphic' />
                                                                    }
                                                                    {
                                                                        graphic.type === '2' && 
                                                                        <video width="320" height="240" controls 
                                                                            className='img-thumbnail rounded graphic' >
                                                                            <source src={auth.baseUrl() + 'property_graphics/' + graphic.id}  type="video/mp4" />
                                                                            <source src="movie.ogg" type="video/ogg" />
                                                                            <span>Your browser does not support the video tag.</span>
                                                                        </video> 
                                                                    }
                                                                </div>
                                                                <Carousel.Caption>
                                                                    <div className='d-flex justify-content-center'>
                                                                        <div className='Caption'>
                                                                            {
                                                                                graphic.comment !== undefined && <p className='small'>{graphic.comment}</p>
                                                                            }                                                                            
                                                                            <button className='btn btn-sm btn-primary '
                                                                                data-bs-toggle="modal" data-bs-target="#imgViewModal"
                                                                                onClick={()=>{this.changeSelectedGraphic(graphic.id)}}
                                                                            > 
                                                                                <span className='fa fa-eye me-2'></span>
                                                                                View Full-Screen
                                                                            </button>
                                                                            {
                                                                                !this.props.view_only && <React.Fragment>
                                                                                    <button className='btn btn-sm btn-danger '
                                                                                        onClick={()=>{this.deletePropertyGraphics(graphic.id)}}
                                                                                    > 
                                                                                        <span className='fa fa-trash'></span>
                                                                                    </button>
                                                                                </React.Fragment>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </Carousel.Caption>
                                                            </Carousel.Item>
                                                        ) 
                                                    }
                                                    </Carousel>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }
                                </div>
                        </div>
                    </div>
                    <div className='text-center mt-4 border-top pt-2 bg-light m-0 p-2 card-footer'>
                        <div className='col-md-12'>
                            { 
                                this.state.isUpLoading && <button disabled
                                    className='btn btn-primary col-md-4'>
                                    <i className='fa fa-spinner fa-spin me-2'></i>
                                    Updating Property...</button>
                            }
                        </div>
                        <div className="form-group">
                            <p className='text-success'>{this.state.msgS}</p>
                            <p className='text-danger'>{this.state.msgF}</p>
                        </div>
                        {
                            this.props.view_only && <React.Fragment>
                                <a href={'/Support/' + this.state.property_id} type='button' 
                                    className='btn-sm btn-success float-start ms-2 me-2'
                                ><span className='fa fa-info-circle me-2'></span>Enquire More</a>
                            </React.Fragment>
                        }
                        {
                            this.props.view_only && <React.Fragment>
                                <a href='/Search-Properties' className=''>Back To Properties</a>
                            </React.Fragment>
                        }
                        {
                            !this.props.view_only && <React.Fragment>
                                <a href='/Properties' className=''>Back To Properties</a>
                            </React.Fragment>
                        }
                    </div>
                    <div className='col-md-5 mt-4 mb-4 p-2'>
                        {/* <img src='' /> */}
                    </div>
                </div>
            </React.Fragment>
         );
    }
}
 
export default PropertyDetailsComponent;