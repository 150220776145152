import logo from '../images/anaking_logo.png'

export const AboutComponent = () => {

    return ( 
        <div className="container justify-content-center d-flex mt-5 mb-5">
            <div className="col-md-8 bg-light shadow p-2">
                <div className="text-header text-center border-bottom">
                    <img src={logo} alt="Site Logo" className='img-thumbnail rounded'/>
                </div>
                <div className="card-body">
                    <h5 className="text-primary ">
                        <u>Anakings Home And Properties</u>
                    </h5>
                    <p className='mb-0'>
                        <b>Address:</b>
                    </p>
                    <p className='text-secondary mb-0'>
                        6815 Riverdale road 
                        Suit B4
                        Riverdale,  MD 20737.
                    </p>
                    <p className='text-secondary mt-0' hidden>
                        DIASPORAS CITY 
                        AGULERI, 
                        15 minutes to the airport.
                    </p>
                    <div className='card'>
                        <iframe title='gmap' 
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3102.26323597642!2d-76.90021558503605!3d38.963656879560475!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7c13d1e4315b3%3A0x84d892ccc1053764!2s6815%20Riverdale%20Rd%20b4%2C%20Riverdale%2C%20MD%2020737%2C%20USA!5e0!3m2!1sen!2suk!4v1657216107151!5m2!1sen!2suk" 
                            width="100%" height="450" style={{"border":"0" }}
                            allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">

                            </iframe>
                    </div>
                    <hr/>
                    <p className='pt-4'>
                        <b>Motto :</b> For peace of mind Investment.
                    </p>
                    <p>We Sell, Buy, Develop Land and Properties</p>
                    <p>
                        Anakings provides a platform to market lands and properties in Africa, USA, Canada, Europe and Australia.
                    </p>
                    <p>
                        At <u>Anakings Home And Properties</u> we understand that you work too long and 
                        hard in your jobs and businesses. As such, a peace of mind investment in your land 
                        and properties is what we provide for you. We can design, 
                        build and develop your properties to your specifications. 
                        A process that is monitored by <u>Anakings Home And Properties</u> team 
                        to ensure legitimacy, transparency and satisfaction of each transaction.
                    </p>
                    <p>
                        Users are required to create account and update their profile.
                        Two types of users are allowed:
                        <ul>
                            <li>
                                <h6>Buyers:</h6>
                                <p>
                                    A buyer simply creates account and browses through avalaible properties of their choice.
                                    He/She can then add each property to cart or contact the seller for the rest of the process.
                                </p>
                            </li>
                            <li>
                                <h6>Sellers:</h6>
                                <p>
                                    A seller creates account and adds Properties they intend to sell.
                                    These Properties are then verified by <u>Anakings Home And Properties</u>&nbsp;
                                    before they are Approved or Rejected. Once Approved, they become visible for users
                                    (buyers) searching for property.
                                </p>
                            </li>
                        </ul>
                    </p>
                </div>
            </div>
        </div>
     );
}