import React, { Component } from 'react';
import auth from '../../auth/auth';
import { LoaderComponent } from '../loaderComponent';
// import ProposalDetailsForm from './ProposalDetailsForm';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


class ProposalCreateOrEditComponent extends Component {
    constructor(props) {
        super(props);    
        this.handleChange = this.handleChange.bind(this)
    }
    state = { 
        step: 0, Proposal_id: null,
        isLoading: false, isUpLoading: false, userId:'', msgS: '', msgF: '',
        name: "",
        email: "",
        phone: "",
        country: "",
        _state: "",
        city: "",
        zip: 234,
        priceStart: 0,
        priceStop: 0,
        description:'', size: '',
    }

    componentDidMount() {
        // if(this.props.Proposal_id != null && this.props.Proposal_id !== undefined){            
        //     this.setState({isLoading: true})
        //     auth.checkAuthentication().then(() => {
        //         if(!auth.isAuthenticated){
        //             localStorage.setItem("navigating_to", window.location.href);
        //             window.location.href = '/Login';
        //         }
        //         this.loadProposal();
        //     });
        //     this.setState({isLoading: true})
        // }
        
        auth.checkAuthentication().then(() => {
            if(!auth.authenticated){
            }else{
                this.setState({isLoading: true});
                (async () => {
                    var result = await auth.GetData('user/profile', 'GET');
                    //console.log(result);
                    if(result["Error"] != null || result["Error"] !== undefined){
                        // this.setState({msgF: true});
                        this.setState({isLoading: false});
                    }else if(result["email"] != null || result["email"] !== undefined){
                        this.setState({name: (result.first_name + " " + 
                                result.last_name + " " + result.middle_name)});
                        this.setState({country: result.country});
                        this.setState({zip: result.zip});
                        this.setState({city: result.city});
                        this.setState({_state: result.state});
                        this.setState({phone: result.phone});
                        this.setState({email: result.email});
                    }
                })().then(()=>{this.setState({isLoading: false});});
            }
        });
    }
    
    loadProposal=()=>{
        (async () => {
            this.setState({isLoading: true})
            var result = await auth.GetData('proposals/' + this.props.Proposal_id, 'GET');
            // console.log(result);
            if(result["Error"] != null || result["Error"] !== undefined){
                this.setState({msgF: result.Error});
                this.setState({isLoading: false});
            }if(result["details"] != null || result["details"] !== undefined){
                var details = result['details'];
                this.setState({Proposal_id: details.id})
                this.setState({name: details.name})
                this.setState({email: details.email})
                this.setState({phone: details.phone})
                this.setState({country: details.country})
                this.setState({state: details.state})
                this.setState({city: details.city})
                this.setState({zip: details.zip})
                this.setState({priceStart: details.priceStart})
                this.setState({priceStop: details.priceStop})
                this.setState({description: details.description})
                this.setState({size: details.size})
                
            }
        })().then(()=>{this.setState({isLoading: false})});
    }

    nextStep = () => {
        if(this.state.step < 2){
            this.setState({step: this.state.step + 1})
        }
    }
    
    prevStep = () => {
        if(this.state.step > 0){
            this.setState({step: this.state.step - 1})
        }
    }

    handleChange = (input) => e =>{
        if(input==="description"){
            // console.log(e)
            this.setState({[input]: e});
        }
        else
            this.setState({[input]: e.target.value});
    }
    
    handleSubmit = async(e) => {
        e.preventDefault();

        if(!this.isValidated(true))
            return;
        
        const body = JSON.stringify({
            name: this.state.name, 
            email: this.state.email, 
            phone: this.state.phone, 
            country: this.state.country, 
            state: this.state.state, 
            city: this.state.city, 
            zip: this.state.zip, 
            priceStart: this.state.priceStart,
            priceStop: this.state.priceStop,
            size: this.state.size, description: this.state.description,
        });

        this.setState({msgF: ""})
        this.setState({msgS: ""})

        this.setState({isUpLoading: true});
        var method = 'PUT'; 
        var url = `proposals/` + this.state.Proposal_id;
        if(this.state.Proposal_id == null || this.state.Proposal_id === undefined || 
            this.state.Proposal_id === ''){
            method = 'POST'; url = `proposals`;
        }
        //alert(url);
        var result = await auth.PostData(body, url, method);
        // console.log(result)
        if(result["Error"] != null || result["Error"] !== undefined){
            this.setState({msgF: result.Error});
            this.setState({isLoading: false});
        }if(result['message'] === 'success'){
            var Proposal_id = this.state.Proposal_id;
            if(this.state.Proposal_id == null || this.state.Proposal_id === undefined || 
                this.state.Proposal_id === ''){
                Proposal_id = result['id'];
                this.setState({Proposal_id: Proposal_id});
                this.setState({msgS: "Proposal Sent"})
            }
            else
                this.setState({msgS: "Proposal Updated"})


            this.setState({isUpLoading: false});
            if(Proposal_id != null || Proposal_id !== undefined || 
                Proposal_id !== ''){
                window.location.href = "/proposals/" + Proposal_id;
            }
            return;
        }else{
            this.setState({msgF: result.statusText})
        }
        this.setState({isUpLoading: false});
    }

    GetPhoneCode(val) {
        this.setState({country: val}); 
        var result = auth.GetPhoneCode(val);
        if(result!==undefined)
            this.setState({zip: "+" + result}); 
    }
    
    isValidated=(alertT=false)=>{
        if(this.state.description !== "" && 
            this.state.name !== "" && 
            this.state.email !== "" && 
            this.state.phone !== "" && 
            this.state.country !== "" && 
            this.state.state !== "" && 
            this.state.city !== "" && 
            this.state.zip !== "" && 
            this.state.size !== ""){
            
                return true;
        }
        if(alertT)
            alert("Proposal details are required!")
        return false;
    }

    render() { 
        if(this.state.isLoading) 
            return (
                <LoaderComponent />
            );
        const {
            name, email, phone, country, state, city, zip, 
            priceStart, priceStop, description, size
        } = this.state;
        const ProposalDetails = {
            name, email, phone, country, state, city, zip, 
            priceStart, priceStop, description, size
        }
        
        return ( 
            <React.Fragment>
                <div className='card-header pt-4'>
                    {
                        this.props.Proposal_id == null && <h5 className='p-2 text-primary'>New Proposal Form</h5>
                    }
                    {
                        this.props.Proposal_id != null && <h5 className='p-2 text-primary'>Edit Proposal (by {this.state.name})</h5>
                    }
                    <hr />
                    <p>Kindly provide the details of the Proposal including price range if available.</p>
                </div>
                <form>
                    <div className=''>
                        <div className='col-md-12'>
                            <div className='shadow m-2 p-2'>
                                    <div className="card-body">
                                        <div className="form-group border-bottom">                
                                            <p className='text-success'>{this.state.msgS}</p>
                                            <p className='text-danger'>{this.state.msgF}</p>
                                        </div>
                                        {
                                            this.state.step === 0 &&
                                            <React.Fragment>                                                
                                                <h6 className='card-header p-2'>Proposal Info</h6>
                                                <div className="row">                        
                                                    <div className="form-group col-md-4">
                                                        <label className='control-label'>Name: </label>
                                                        <input type='text' className='form-control' 
                                                            id='name'
                                                            placeholder='Your Name' autoFocus
                                                            defaultValue={ProposalDetails.name}
                                                            onChange={this.handleChange('name')}
                                                        />
                                                    </div>
                                                    <div className="form-group col-md-4">
                                                        <label className='control-label'>Email: </label>
                                                        <input type='email' className='form-control' 
                                                            id='email'
                                                            placeholder='Your Email' autoFocus
                                                            defaultValue={ProposalDetails.email}
                                                            onChange={this.handleChange('email')}
                                                        />
                                                    </div>
                                                    <div className="form-group col-md-4">
                                                        <label className='control-label'>Phone: </label>
                                                        <input type='text' className='form-control' 
                                                            id='phone'
                                                            placeholder='Your Phone' autoFocus
                                                            defaultValue={ProposalDetails.phone}
                                                            onChange={this.handleChange('phone')}
                                                        />
                                                    </div>
                                                    <div className="form-group col-md-4">
                                                        <label className='control-label'>Country: </label>
                                                        <select className="form-control"  
                                                            value={ProposalDetails.country} 
                                                            onChange={e=>{this.GetPhoneCode(e.target.value);
                                                                this.handleChange('country')}}
                                                            required>
                                                            <option selected disabled value="">Choose Country</option>
                                                            <option value="Afghanistan">Afghanistan</option>
                                                            <option value="Albania">Albania</option>
                                                            <option value="Algeria">Algeria</option>
                                                            <option value="American Samoa">American Samoa</option>
                                                            <option value="Andorra">Andorra</option>
                                                            <option value="Angola">Angola</option>
                                                            <option value="Anguilla">Anguilla</option>
                                                            <option value="Antarctica">Antarctica</option>
                                                            <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                                            <option value="Argentina">Argentina</option>
                                                            <option value="Armenia">Armenia</option>
                                                            <option value="Aruba">Aruba</option>
                                                            <option value="Australia">Australia</option>
                                                            <option value="Austria">Austria</option>
                                                            <option value="Azerbaijan">Azerbaijan</option>
                                                            <option value="Bahamas">Bahamas</option>
                                                            <option value="Bahrain">Bahrain</option>
                                                            <option value="Bangladesh">Bangladesh</option>
                                                            <option value="Barbados">Barbados</option>
                                                            <option value="Belarus">Belarus</option>
                                                            <option value="Belgium">Belgium</option>
                                                            <option value="Belize">Belize</option>
                                                            <option value="Benin">Benin</option>
                                                            <option value="Bermuda">Bermuda</option>
                                                            <option value="Bhutan">Bhutan</option>
                                                            <option value="Bolivia">Bolivia</option>
                                                            <option value="Bosnia and Herzegowina">Bosnia and Herzegowina</option>
                                                            <option value="Botswana">Botswana</option>
                                                            <option value="Bouvet Island">Bouvet Island</option>
                                                            <option value="Brazil">Brazil</option>
                                                            <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                                                            <option value="Brunei Darussalam">Brunei Darussalam</option>
                                                            <option value="Bulgaria">Bulgaria</option>
                                                            <option value="Burkina Faso">Burkina Faso</option>
                                                            <option value="Burundi">Burundi</option>
                                                            <option value="Cambodia">Cambodia</option>
                                                            <option value="Cameroon">Cameroon</option>
                                                            <option value="Canada">Canada</option>
                                                            <option value="Cape Verde">Cape Verde</option>
                                                            <option value="Cayman Islands">Cayman Islands</option>
                                                            <option value="Central African Republic">Central African Republic</option>
                                                            <option value="Chad">Chad</option>
                                                            <option value="Chile">Chile</option>
                                                            <option value="China">China</option>
                                                            <option value="Christmas Island">Christmas Island</option>
                                                            <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                                                            <option value="Colombia">Colombia</option>
                                                            <option value="Comoros">Comoros</option>
                                                            <option value="Congo">Congo</option>
                                                            <option value="Congo, the Democratic Republic of the">Congo, the Democratic Republic of the</option>
                                                            <option value="Cook Islands">Cook Islands</option>
                                                            <option value="Costa Rica">Costa Rica</option>
                                                            <option value="Cote d&#039;Ivoire">Cote d&#039;Ivoire</option>
                                                            <option value="Croatia (Hrvatska)">Croatia (Hrvatska)</option>
                                                            <option value="Cuba">Cuba</option>
                                                            <option value="Cyprus">Cyprus</option>
                                                            <option value="Czech Republic">Czech Republic</option>
                                                            <option value="Denmark">Denmark</option>
                                                            <option value="Djibouti">Djibouti</option>
                                                            <option value="Dominica">Dominica</option>
                                                            <option value="Dominican Republic">Dominican Republic</option>
                                                            <option value="East Timor">East Timor</option>
                                                            <option value="Ecuador">Ecuador</option>
                                                            <option value="Egypt">Egypt</option>
                                                            <option value="El Salvador">El Salvador</option>
                                                            <option value="Equatorial Guinea">Equatorial Guinea</option>
                                                            <option value="Eritrea">Eritrea</option>
                                                            <option value="Estonia">Estonia</option>
                                                            <option value="Ethiopia">Ethiopia</option>
                                                            <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                                                            <option value="Faroe Islands">Faroe Islands</option>
                                                            <option value="Fiji">Fiji</option>
                                                            <option value="Finland">Finland</option>
                                                            <option value="France">France</option>
                                                            <option value="France Metropolitan">France Metropolitan</option>
                                                            <option value="French Guiana">French Guiana</option>
                                                            <option value="French Polynesia">French Polynesia</option>
                                                            <option value="French Southern Territories">French Southern Territories</option>
                                                            <option value="Gabon">Gabon</option>
                                                            <option value="Gambia">Gambia</option>
                                                            <option value="Georgia">Georgia</option>
                                                            <option value="Germany">Germany</option>
                                                            <option value="Ghana">Ghana</option>
                                                            <option value="Gibraltar">Gibraltar</option>
                                                            <option value="Greece">Greece</option>
                                                            <option value="Greenland">Greenland</option>
                                                            <option value="Grenada">Grenada</option>
                                                            <option value="Guadeloupe">Guadeloupe</option>
                                                            <option value="Guam">Guam</option>
                                                            <option value="Guatemala">Guatemala</option>
                                                            <option value="Guinea">Guinea</option>
                                                            <option value="Guinea-Bissau">Guinea-Bissau</option>
                                                            <option value="Guyana">Guyana</option>
                                                            <option value="Haiti">Haiti</option>
                                                            <option value="Heard and Mc Donald Islands">Heard and Mc Donald Islands</option>
                                                            <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                                                            <option value="Honduras">Honduras</option>
                                                            <option value="Hong Kong">Hong Kong</option>
                                                            <option value="Hungary">Hungary</option>
                                                            <option value="Iceland">Iceland</option>
                                                            <option value="India">India</option>
                                                            <option value="Indonesia">Indonesia</option>
                                                            <option value="Iran (Islamic Republic of)">Iran (Islamic Republic of)</option>
                                                            <option value="Iraq">Iraq</option>
                                                            <option value="Ireland">Ireland</option>
                                                            <option value="Israel">Israel</option>
                                                            <option value="Italy">Italy</option>
                                                            <option value="Jamaica">Jamaica</option>
                                                            <option value="Japan">Japan</option>
                                                            <option value="Jordan">Jordan</option>
                                                            <option value="Kazakhstan">Kazakhstan</option>
                                                            <option value="Kenya">Kenya</option>
                                                            <option value="Kiribati">Kiribati</option>
                                                            <option value="Korea, Democratic People&#039;s Republic of">Korea, Democratic People&#039;s Republic of</option>
                                                            <option value="Korea, Republic of">Korea, Republic of</option>
                                                            <option value="Kuwait">Kuwait</option>
                                                            <option value="Kyrgyzstan">Kyrgyzstan</option>
                                                            <option value="Lao, People&#039;s Democratic Republic">Lao, People&#039;s Democratic Republic</option>
                                                            <option value="Latvia">Latvia</option>
                                                            <option value="Lebanon">Lebanon</option>
                                                            <option value="Lesotho">Lesotho</option>
                                                            <option value="Liberia">Liberia</option>
                                                            <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                                                            <option value="Liechtenstein">Liechtenstein</option>
                                                            <option value="Lithuania">Lithuania</option>
                                                            <option value="Luxembourg">Luxembourg</option>
                                                            <option value="Macau">Macau</option>
                                                            <option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
                                                            <option value="Madagascar">Madagascar</option>
                                                            <option value="Malawi">Malawi</option>
                                                            <option value="Malaysia">Malaysia</option>
                                                            <option value="Maldives">Maldives</option>
                                                            <option value="Mali">Mali</option>
                                                            <option value="Malta">Malta</option>
                                                            <option value="Marshall Islands">Marshall Islands</option>
                                                            <option value="Martinique">Martinique</option>
                                                            <option value="Mauritania">Mauritania</option>
                                                            <option value="Mauritius">Mauritius</option>
                                                            <option value="Mayotte">Mayotte</option>
                                                            <option value="Mexico">Mexico</option>
                                                            <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                                                            <option value="Moldova, Republic of">Moldova, Republic of</option>
                                                            <option value="Monaco">Monaco</option>
                                                            <option value="Mongolia">Mongolia</option>
                                                            <option value="Montserrat">Montserrat</option>
                                                            <option value="Morocco">Morocco</option>
                                                            <option value="Mozambique">Mozambique</option>
                                                            <option value="Myanmar">Myanmar</option>
                                                            <option value="Namibia">Namibia</option>
                                                            <option value="Nauru">Nauru</option>
                                                            <option value="Nepal">Nepal</option>
                                                            <option value="Netherlands">Netherlands</option>
                                                            <option value="Netherlands Antilles">Netherlands Antilles</option>
                                                            <option value="New Caledonia">New Caledonia</option>
                                                            <option value="New Zealand">New Zealand</option>
                                                            <option value="Nicaragua">Nicaragua</option>
                                                            <option value="Niger">Niger</option>
                                                            <option value="Nigeria">Nigeria</option>
                                                            <option value="Niue">Niue</option>
                                                            <option value="Norfolk Island">Norfolk Island</option>
                                                            <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                                            <option value="Norway">Norway</option>
                                                            <option value="Oman">Oman</option>
                                                            <option value="Pakistan">Pakistan</option>
                                                            <option value="Palau">Palau</option>
                                                            <option value="Panama">Panama</option>
                                                            <option value="Papua New Guinea">Papua New Guinea</option>
                                                            <option value="Paraguay">Paraguay</option>
                                                            <option value="Peru">Peru</option>
                                                            <option value="Philippines">Philippines</option>
                                                            <option value="Pitcairn">Pitcairn</option>
                                                            <option value="Poland">Poland</option>
                                                            <option value="Portugal">Portugal</option>
                                                            <option value="Puerto Rico">Puerto Rico</option>
                                                            <option value="Qatar">Qatar</option>
                                                            <option value="Reunion">Reunion</option>
                                                            <option value="Romania">Romania</option>
                                                            <option value="Russian Federation">Russian Federation</option>
                                                            <option value="Rwanda">Rwanda</option>
                                                            <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                                            <option value="Saint Lucia">Saint Lucia</option>
                                                            <option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
                                                            <option value="Samoa">Samoa</option>
                                                            <option value="San Marino">San Marino</option>
                                                            <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                                            <option value="Saudi Arabia">Saudi Arabia</option>
                                                            <option value="Senegal">Senegal</option>
                                                            <option value="Seychelles">Seychelles</option>
                                                            <option value="Sierra Leone">Sierra Leone</option>
                                                            <option value="Singapore">Singapore</option>
                                                            <option value="Slovakia (Slovak Republic)">Slovakia (Slovak Republic)</option>
                                                            <option value="Slovenia">Slovenia</option>
                                                            <option value="Solomon Islands">Solomon Islands</option>
                                                            <option value="Somalia">Somalia</option>
                                                            <option value="South Africa">South Africa</option>
                                                            <option value="South Georgia and the South Sandwich Islands">South Georgia and the South Sandwich Islands</option>
                                                            <option value="Spain">Spain</option>
                                                            <option value="Sri Lanka">Sri Lanka</option>
                                                            <option value="St. Helena">St. Helena</option>
                                                            <option value="St. Pierre and Miquelon">St. Pierre and Miquelon</option>
                                                            <option value="Sudan">Sudan</option>
                                                            <option value="Suriname">Suriname</option>
                                                            <option value="Svalbard and Jan Mayen Islands">Svalbard and Jan Mayen Islands</option>
                                                            <option value="Swaziland">Swaziland</option>
                                                            <option value="Sweden">Sweden</option>
                                                            <option value="Switzerland">Switzerland</option>
                                                            <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                                                            <option value="Taiwan, Province of China">Taiwan, Province of China</option>
                                                            <option value="Tajikistan">Tajikistan</option>
                                                            <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                                                            <option value="Thailand">Thailand</option>
                                                            <option value="Togo">Togo</option>
                                                            <option value="Tokelau">Tokelau</option>
                                                            <option value="Tonga">Tonga</option>
                                                            <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                                            <option value="Tunisia">Tunisia</option>
                                                            <option value="Turkey">Turkey</option>
                                                            <option value="Turkmenistan">Turkmenistan</option>
                                                            <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                                                            <option value="Tuvalu">Tuvalu</option>
                                                            <option value="Uganda">Uganda</option>
                                                            <option value="Ukraine">Ukraine</option>
                                                            <option value="United Arab Emirates">United Arab Emirates</option>
                                                            <option value="United Kingdom">United Kingdom</option>
                                                            <option value="United States">United States</option>
                                                            <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                                                            <option value="Uruguay">Uruguay</option>
                                                            <option value="Uzbekistan">Uzbekistan</option>
                                                            <option value="Vanuatu">Vanuatu</option>
                                                            <option value="Venezuela">Venezuela</option>
                                                            <option value="Vietnam">Vietnam</option>
                                                            <option value="Virgin Islands (British)">Virgin Islands (British)</option>
                                                            <option value="Virgin Islands (U.S.)">Virgin Islands (U.S.)</option>
                                                            <option value="Wallis and Futuna Islands">Wallis and Futuna Islands</option>
                                                            <option value="Western Sahara">Western Sahara</option>
                                                            <option value="Yemen">Yemen</option>
                                                            <option value="Yugoslavia">Yugoslavia</option>
                                                            <option value="Zambia">Zambia</option>
                                                            <option value="Zimbabwe">Zimbabwe</option>
                                                        </select>
                                                    </div>
                                                    <div className="form-group col-md-4">
                                                        <label className='control-label'>State: </label>
                                                        <input type='text' className='form-control' 
                                                            id='state'
                                                            placeholder='Proposal State' autoFocus
                                                            defaultValue={ProposalDetails.state}
                                                            onChange={this.handleChange('state')}
                                                        />
                                                    </div>
                                                    <div className="form-group col-md-4">
                                                        <label className='control-label'>City: </label>
                                                        <input type='text' className='form-control' 
                                                            id='city'
                                                            placeholder='Proposal City' autoFocus
                                                            defaultValue={ProposalDetails.city}
                                                            onChange={this.handleChange('city')}
                                                        />
                                                    </div>
                                                    <div className="form-group col-md-4">
                                                        <label className='control-label'>Zip: </label>
                                                        <input type='text' className='form-control' 
                                                            id='zip'
                                                            placeholder='Proposal Zip' autoFocus
                                                            defaultValue={ProposalDetails.zip}
                                                            value={ProposalDetails.zip}
                                                            onChange={this.handleChange('zip')}
                                                        />
                                                    </div>
                                                    <div className="form-group col-md-4">
                                                        <label className='control-label'>Price Start: </label>
                                                        <input min={0}
                                                            defaultValue={ProposalDetails.priceStart}
                                                            type='number' className='form-control' 
                                                            placeholder='Proposal Price Start'
                                                            onChange={this.handleChange('priceStart')}
                                                        />
                                                    </div>                      
                                                    <div className="form-group col-md-4">
                                                        <label className='control-label'>Price Stop: </label>
                                                        <input min={0}
                                                            defaultValue={ProposalDetails.priceStop}
                                                            type='number' className='form-control' 
                                                            placeholder='Proposal Price Stop'
                                                            onChange={this.handleChange('priceStop')}
                                                        />
                                                    </div>                      
                                                    <div className="form-group col-md-4">
                                                        <label className='control-label'>Size: </label>
                                                        <input type='text' className='form-control' 
                                                            defaultValue={ProposalDetails.size}
                                                            onChange={this.handleChange('size')}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className='control-label'>Description: </label>
                                                    {/* <!-- Create the editor container --> */}
                                                    <ReactQuill required className='textarea' 
                                                        placeholder='Enter Description'
                                                        value={ProposalDetails.description}
                                                        onChange={this.handleChange("description")} >
                                                    </ReactQuill>
                                                </div>
                                            </React.Fragment>
                                        }
                                    </div>
                            </div>
                        </div>
                        <div className='text-center mt-4 border-top pt-2 bg-light m-0 p-2 card-footer'>
                            <div className='container mb-2 col-md-12' hidden>
                                { 
                                    this.state.step > 0 && <button type='button'
                                        className='btn btn-primary float-start'
                                        onClick={this.prevStep}
                                    >Previous</button>
                                }
                                { 
                                    this.state.step < 2 && <button type='button'
                                        className='btn btn-primary float-end'
                                        onClick={this.nextStep}
                                    >Next</button>
                                }
                            </div>
                            <div className='col-md-12'>
                                { 
                                    !this.state.isUpLoading && this.state.step === 0 && <button 
                                        className='btn btn-success col-md-4'
                                        onClick={this.handleSubmit}
                                    >
                                        {
                                            this.state.Proposal_id == null && <span>Submit New Proposal</span>
                                        }
                                        {
                                            this.state.Proposal_id != null && <span>Update Proposal</span>
                                        }
                                    </button>
                                }
                                { 
                                    this.state.isUpLoading && <button disabled
                                        className='btn btn-primary col-md-4'>
                                        <i className='fa fa-spinner fa-spin me-2'></i>
                                        Updating Proposal...</button>
                                }
                            </div>
                            <div className="form-group">
                                <p className='text-success'>{this.state.msgS}</p>
                                <p className='text-danger'>{this.state.msgF}</p>
                            </div>
                            { auth.userRole === "admin" && <a href='/proposals' className=''>Back To Proposals</a>}
                            <a href='/' className=''>Back To Home</a>
                        </div>
                        <div className='col-md-5 mt-4 mb-4 p-2'>
                            {/* <img src='' /> */}
                        </div>
                    </div>
                </form>
            </React.Fragment>
         );
    }
}
 
export default ProposalCreateOrEditComponent;