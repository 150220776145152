import logo from '../logo.png';
//import logo1 from '../images/anaking logo.png';
import logo1 from '../images/anaking_logo.png';
import React, { useEffect, useState } from 'react';
import AuthenticationButton from './authentication-button';

export const TopbarComponent = () => {
    useEffect(() => {
        //window.addEventListener('load', HandleLoad);
    }, []);
    
    function HandleLoad() {
        let body = document.querySelector("body"),
          modeToggle = body.querySelector(".mode-toggle");
          var sidebar = body.querySelector("nav");
          var sidebarToggle = body.querySelector(".sidebar-toggle");
    
        let getMode = localStorage.getItem("mode");
        if(getMode && getMode ==="dark"){
            body.classList.toggle("dark");
        }
    
        let getStatus = localStorage.getItem("status");
        if(getStatus && getStatus ==="close"){
            sidebar.classList.toggle("close");
        }
    
        modeToggle.addEventListener("click", () =>{
            body.classList.toggle("dark");
            if(body.classList.contains("dark")){
                localStorage.setItem("mode", "dark");
            }else{
                localStorage.setItem("mode", "light");
            }
        });
    
        sidebarToggle.addEventListener("click", () => {
            sidebar.classList.toggle("close");
            if(sidebar.classList.contains("close")){
                localStorage.setItem("status", "close");
            }else{
                localStorage.setItem("status", "open");
            }
        })
    }

    function searchProperty(){
        const searchValue = document.getElementById("searchValue");
        window.location.href = "/Search-Properties/Search/" + searchValue.value;
    }

    return (
        <section className="dashboard">
            <div className="top pb-2">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-6 mb-0">                            
                            <div className="text-white h4">
                                <i className="pe-4 fa fa-bars sidebar-toggle"
                                    onClick={()=>{
                                        let body = document.querySelector("body");
                                        var sidebar = body.querySelector("nav");
                                        
                                        sidebar.classList.toggle("close");
                                        if(sidebar.classList.contains("close")){
                                            localStorage.setItem("status", "close");
                                        }else{
                                            localStorage.setItem("status", "open");
                                        }
                                    }}
                                ></i>
                                <a href='/'>                                    
                                    <img className='logo1 rounded bg-light ps-2 pe-2' src={logo1} alt="Logo" />
                                    <span className="text" hidden>ANAKINGS HOMES AND PROPERTIES</span>
                                </a>
                            </div>
                        </div>
                        <div className="col-md-6">                            
                            <div className="row justify-content-end">
                                <div className="col-md-6">
                                    <div className="input-group">
                                        <input type="text" placeholder="Search by size, street, City, Zip, Country" 
                                            className="nav-input form-control float-right"
                                            id='searchValue'
                                            />
                                        <span className="nav-input input-group-text btn btn-primary"
                                            onClick={(e) => {searchProperty()}}
                                            ><i className=' fa fa-search'></i></span>
                                        <a href='/User-Cart' className=' text-light ms-2' title='View Cart'>
                                            <i className='fa fa-cart-plus fa-2x'></i>
                                        </a>
                                    </div>
                                </div>
                                <div hidden>
                                    <div className='col-md-4 d-md-block d-none'>
                                        <AuthenticationButton value={true}/>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>

                
                <img src="images/profile.jpg" alt=""/>
            </div>
        </section>
    );
}