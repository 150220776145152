import React from 'react';
import auth from '../auth/auth';
import LoginLink from './LoginLink';


const AuthenticationButton = (props) => {
  if(!auth.isAuthenticated()) {
    if(props.value){
      return (
        <div className='float-end'>
            {/* <li><a href="/Properties">
                <i className="uil uil-files-landscapes"></i>
                <span className="link-name">Properties</span>
            </a></li> */}
            <li><a href='/Login'>
              <i className="fa fa-user me-2"> </i>
              <span className="link-name">Login</span>
            </a></li>
            <li><a href='/Register'>
              <i className="fa fa-user me-2"> </i>
              <span className="link-name">Register</span>
            </a></li>
        </div>
      )
      // return (
      //   <div className='float-end'>
      //       <a href='/Login' className='btn btn-sm btn-pink text-white me-2'>
      //         <i className="fa fa-user me-2"> </i>
      //         <span className="link-name">Login</span>
      //       </a>
      //       <a href='/Register' className='btn btn-sm btn-pink text-white'>
      //         <i className="fa fa-user me-2"> </i>
      //         <span className="link-name">Register</span>
      //       </a>
      //   </div>
      // )
    }
    return (
      <LoginLink />      
    )
  }
  return (    
      <li>
        <a href='/Dashboard'>
          <i className="fa fa-home pe-2" ></i>
          <span className="link-name">Dashboard</span>
        </a>
      </li>
  )
};

export default AuthenticationButton;