import logo from '../logo.png';
import Diasporas_CIty from '../images/Diasporas CIty.jpeg';
import property1 from '../images/property_slide1.jpeg';
import property2 from '../images/property_slide2.jpg';
import property3 from '../images/property_slide3.jpg';
import property4 from '../images/property_slide4.jpg';
import property_prices from '../images/prices.jpeg';
import React, { Component } from 'react';

import { Carousel } from 'react-bootstrap';
import RecentPropertiesComponent from './RecentPropertiesComponent';
import SupportComponent from './support/SupportComponent';
import { SubscribeComponent } from './SubscribeComponent';
import MultipleRows from './slick-component';

class Home extends Component {
    
    state = { 
        propertyStyle: {
            'width': '100%',
            'maxWidth': '100%',
            'border': '2px solid rgb(100, 128, 238)',
            'pointer-events': 'none',
        },
        Carousel: {
            'overflow': 'hidden',
        }, carouselIndex: 0,
    } 

    componentDidMount(){
    }

    handleSelect = (selectedIndex, e) => {
        this.setState({carouselIndex: selectedIndex});
    };

    render() { 
        return (
            <div>
                <div className='bg-primary-new pt-4 pb-2'>
                    <div animation-type='fadeInLeft' className='animated-item animated fadeInLeft container justify-content-center'>
                        <Carousel fade interval={1500} variant="dark" 
                            activeIndex={this.state.carouselIndex} 
                            onSelect={this.handleSelect}
                        >
                            <Carousel.Item style={this.state.Carousel}>
                                <img src={property1} className="d-block card" alt="..." style={this.state.propertyStyle} />
                                <Carousel.Caption className='d-flex justify-content-center '>
                                    <div className="Caption">
                                        <p className='small'>Discover a place you'll love to live.</p>
                                    </div>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item style={this.state.Carousel}>
                                <img src={property2} className="d-block card" alt="..." style={this.state.propertyStyle}/>
                                <Carousel.Caption className='d-flex justify-content-center '>
                                    <div className="Caption">
                                        <p className='small'>Developed and undeveloped properties.</p>
                                    </div>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item style={this.state.Carousel}>
                                <img src={property3} className="d-block card" alt="..." style={this.state.propertyStyle} />
                                <Carousel.Caption className='d-flex justify-content-center '>
                                    <div className="Caption">
                                        <p className='small'>Find a home that’s perfect for you.</p>
                                    </div>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item style={this.state.Carousel}>
                                <img src={property4} className="d-block card" alt="..." style={this.state.propertyStyle} />
                                <Carousel.Caption className='d-flex justify-content-center '>
                                    <div className="Caption">
                                        <p className='small'>Find a home that’s perfect for you.</p>
                                    </div>
                                </Carousel.Caption>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <div className="col-md-12 text-center">
                        <div animation-type='fadeInRight' className="animated-item animated fadeInRight card-header">
                            <h3 className="text-primary ">
                                <u>Anakings Home And Properties</u>
                            </h3>
                            <p>
                                <b>Motto :</b> For peace of mind Investment.
                            </p>
                            <p>
                                Anakings provides a platform to market lands and properties in Africa, USA, Canada, Europe and Australia.
                            </p>
                            <p>
                                At Anaking Homes and properties we understand that you work too long and hard in your jobs and businesses. As such, a peace of mind investment in your land and properties is what we provide for you. We can design, build and develop your properties to your specifications. A process that is monitored by Anaking Homes and properties team to ensure legitimacy, transparency and satisfaction of each transaction.
                            </p>
                        </div>
                        <div className="row pt-4 pb-4">
                            <a href='/Search-Properties/063859e8' className="col-md-6">                                
                                <img src={Diasporas_CIty} className="rounded img-thumbnail" 
                                    style={{"max-height": "550px"}}
                                    alt="Our property prices" />
                            </a>
                            <a href='/Search-Properties' className="col-md-6">                                
                                <img src={property_prices} className="rounded img-thumbnail" 
                                    style={{"max-height": "550px"}}
                                    alt="Our property prices" />
                            </a>
                        </div>
                        <div animation-type='fadeInLeft' className="animated-item animated fadeInLeft card-header">
                            <p>
                                Take a deep dive and browse homes for sale, original neighborhood photos, resident reviews and local insights to find what is right for you.
                            </p>
                        </div>
                        <div className="m-4">
                            <div className="justify-content-center row">
                                <div animation-type='bounce' className="animated-item animated bounce card-imgs col-md-4 card p-0 m-2 ">
                                    <div className="card-header-gold p-2">
                                        Properties in Lagos
                                    </div>
                                    <div className="card-body p-2 bg-lagos">
                                        <p>
                                            <a href='/Search-Properties/Search/Lagos' className='btn-pink'>Buy Now</a>
                                        </p>
                                    </div>

                                </div>
                                <div animation-type='fadeInRight' className="animated-item animated fadeInRight col-md-3">
                                    <div className="column">
                                        <div className="col-12">
                                            <div className="card-imgs card p-0 m-2 ">
                                                <div className="card-header-gold p-2">
                                                    Properties in Anambra
                                                </div>
                                                <div className="card-body p-2 bg-lagos-sm">
                                                    <p>
                                                        <a href='/Search-Properties/Search/Anambra' className='btn-pink'>Buy Now</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="card-imgs card p-0 m-2 ">
                                                <div className="card-header-gold p-2">
                                                    Properties in Asaba
                                                </div>
                                                <div className="card-body p-2 bg-lagos-sm1">
                                                    <p>
                                                        <a href='/Search-Properties/Search/Asaba' className='btn-pink'>Buy Now</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div animation-type='bounce' className="animated-item animated bounce card-imgs col-md-4 card p-0 m-2 ">
                                    <div className="card-header-gold p-2">
                                        Properties in Abuja
                                    </div>
                                    <div className="card-body p-2 bg-abuja">
                                        <p>
                                            <a href='/Search-Properties/Search/Abuja' className='btn-pink'>Buy Now</a>
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="col-md-12 text-center bg-dark pt-4">
                    <h3 className='shadow-border-light '>Newly Listed Properties</h3>
                    <hr className='text-white'/>
                    <p className='text-white'>
                        Take a deep dive and browse homes for sale, original neighborhood photos, resident reviews and local insights to find what is right for you.
                    </p>
                    {/* <MultipleRows></MultipleRows> */}
                    <RecentPropertiesComponent >
                    </RecentPropertiesComponent>
                    
                    <div className='shadow-border-light  p-4 mt-4'>
                        <h4>Can't Find what you are looking for?</h4>
                        <p><a href='/Proposal' className='btn btn-primary'>Submit A Proposal</a></p>
                    </div>
                </div>
                
                <SupportComponent></SupportComponent>
                <SubscribeComponent></SubscribeComponent>
            </div>
        );
    }
}
 
export default Home;

