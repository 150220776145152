import logo from '../images/Career.png'

export const CareerComponent = () => {

    return ( 
        <div className="container justify-content-center d-flex mt-5 mb-5">
            <div className="card col-md-8 bg-light shadow p-2">
                <div className="text-header text-center border-bottom">
                    <img src={logo} alt="Site Logo" className='img-thumbnail rounded'/>
                </div>
                <div className="card-body">
                    <h5 className="text-primary ">
                        <u>Career</u>
                    </h5>
                    <p className='mb-2'>
                        ANAKING is hiring part-time independent Sales Representatives to market our present and FUTURE products.
                    </p>
                    <p className='mb-0'>
                        At ANAKING, we provide a platform for career oriented independent Sales Representatives to grow into:
                    </p>
                    <p>
                        <ul>
                            <li>
                                Sales manager
                            </li>
                            <li>
                                Regional Sales manager
                            </li>
                            <li>
                                National Sales manager
                            </li>
                            <li>
                                International Sales manager
                            </li>
                        </ul>
                    </p>
                    <hr/>
                    <p>Our future products may include:</p>
                    <p>
                        <ul>
                            <li>
                                BUREAU DE CHANGE
                            </li>
                            <li>
                                INSURANCE
                                <div>
                                    <ol>
                                        <li>Life</li>
                                        <li>Car</li>
                                    </ol>
                                </div>
                            </li>
                            <li>
                                INVESTMENT
                                <div>
                                    <ol>
                                        <li>Mutual Fund</li>
                                    </ol>
                                </div>
                            </li>
                        </ul>
                    </p>
                </div>
            </div>
        </div>
     );
}