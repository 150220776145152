import logo from '../images/anaking_logo.png';
//import logo from '../logo.png';
import React, { Component, useEffect, useState } from 'react';
import { Modal } from 'bootstrap';
import {LoginComponent} from './LoginComponent';
import { LogoutLink } from './LogoutLink';
//import AuthenticationButton from './authentication-button';
import { RegisterComponent } from './RegisterComponent';
import auth from '../auth/auth';
import LoginLink from './LoginLink';

class SidebarComponent12 extends Component {
    state = { 
        isAuthenticated: false,
        userRole: 'buyer'
     } 
     
     componentDidUpdate(){
         auth.checkAuthentication().then(() => {
             this.setState({isAuthenticated: auth.authenticated})
             this.setState({userRole: auth.userRole})
         });
        //window.addEventListener('load', this.HandleLoad);
     }
     

    componentDidMount(){
        window.addEventListener('load', this.HandleLoad);
    }
    
    componentWillUnmount(){
        window.removeEventListener('load', this.HandleLoad);
    }
    
    HandleLoad() {
        let body = document.querySelector("body"),
          modeToggle = body.querySelector(".mode-toggle");
          var sidebar = body.querySelector("nav");
          var sidebarToggle = body.querySelector(".sidebar-toggle1");
    
        let getMode = localStorage.getItem("mode");
        if(getMode && getMode ==="dark"){
            body.classList.toggle("dark");
        }
    
        let getStatus = localStorage.getItem("status");
        if(getStatus && getStatus ==="close"){
            sidebar.classList.toggle("close");
        }
    
        modeToggle.addEventListener("click", () =>{
            body.classList.toggle("dark");
            if(body.classList.contains("dark")){
                localStorage.setItem("mode", "dark");
            }else{
                localStorage.setItem("mode", "light");
            }
        });
    
        sidebarToggle.addEventListener("click", () => {
            sidebar.classList.toggle("close");
            if(sidebar.classList.contains("close")){
                localStorage.setItem("status", "close");
            }else{
                localStorage.setItem("status", "open");
            }
        })
    }
    render() { 
        return (
            <nav className='close'>
                <div className="modal fade modal-bg" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog shadow">
                        <div className="modal-content border shadow">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel"></h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <ul className="nav nav-pills nav-fill">
                                    <li className="nav-item">
                                        <a className="nav-link active"  id="login-tab" data-bs-toggle="tab" data-bs-target="#login" aria-current="page" href="#">Login</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#"  id="register-tab" data-bs-toggle="tab" data-bs-target="#register">Register</a>
                                    </li>
                                </ul>
                                <hr className='hr'/>
                                <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active" id="login" role="tabpanel" aria-labelledby="login-tab">
                                        <LoginComponent />
                                    </div>
                                    <div className="tab-pane fade" id="register" role="tabpanel" aria-labelledby="register-tab">
                                        <RegisterComponent />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="logo-name">
                    <div className="logo-image rounded bg-light">
                        <img src={logo} alt=""/>
                    </div>
        
                    <span className="logo_name">ANAKINGS HOMES</span>
                    <i className="ps-4 fa-2x fa fa-window-close sidebar-toggle1"></i>
                </div>
        
                <div className="menu-items">
                    <ul className="nav-links ps-0">
                        <li><a href="/">
                            <i className="uil uil-files-landscapes"></i>
                            <span className="link-name">Home</span>
                        </a></li>
                        {/* <AuthenticationButton value={false}/> */}
                        {/* if(!auth.isAuthenticated()){
                             <li><a href='#Register'  
                            data-bs-toggle="modal" data-bs-target="#exampleModal">
                            <i className="fa fa-user"></i>
                            <span className="link-name">Register</span>
                        </a></li>
                        } */}
                        {/* <li><LoginLink /></li> */}
                        {
                            this.state.authenticated && this.state.userRole === 'buyer' &&
                            <li><a href="/Search-Properties">
                                <i className="uil uil-files-landscapes"></i>
                                <span className="link-name">View Properties</span>
                            </a></li>
                        }
                        {
                            this.state.authenticated && (this.state.userRole === 'admin' ||
                            this.state.userRole === 'seller') &&
                            <li><a href="/Properties">
                                <i className="uil uil-files-landscapes"></i>
                                <span className="link-name">Properties</span>
                            </a></li>
                        }
                        {
                            this.state.authenticated && this.state.userRole === 'admin' &&
                            <li><a href="/Users">
                                <i className="uil uil-files-landscapes"></i>
                                <span className="link-name">Users</span>
                            </a></li>
                        }
                    </ul>
                    
                    <ul className="logout-mode">
                        <li>{
                                !this.state.authenticated && 
                                <a href='#Login'  
                                    data-bs-toggle="modal" data-bs-target="#exampleModal">
                                    <i className="fa fa-user"></i>
                                    <span className="link-name">Login</span>
                                </a>
                            }
                        </li>
                        <li>{
                                !this.state.authenticated && 
                                <a href='/Register'>
                                    <i className="fa fa-user"></i>
                                    <span className="link-name">Register</span>
                                </a>
                            }
                        </li>
                        <li>{
                                this.state.authenticated && 
                                <a href='/Profile'>
                                  <i className="fa fa-info"></i>
                                  <span className="link-name">Profile</span>
                                </a>
                            }
                        </li>
                        <li>{
                                this.state.authenticated && <LogoutLink />
                            }
                        </li>
        
                        <li className="mode">
                            <div className="mode-toggle">
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        );

    }
}

export const SidebarComponent = (props) => {
     const [authenticated, setIsAuthenticated] = useState(false);
     const [userRole, setUserRole] = useState('buyer');

     useEffect(()=>{         
         auth.checkAuthentication().then((result) => {
             setIsAuthenticated(auth.authenticated)
             setUserRole(auth.userRole)
            //  console.log(auth.userRole);
            //  console.log(authenticated + ", " + result);
         });
         //window.addEventListener('load', this.HandleLoad());
     }, [authenticated])

     const HandleLoad=()=> {
        let body = document.querySelector("body"),
          modeToggle = body.querySelector(".mode-toggle");
          var sidebar = body.querySelector("nav");
          var sidebarToggle = body.querySelector(".sidebar-toggle1");
    
        let getMode = localStorage.getItem("mode");
        if(getMode && getMode ==="dark"){
            body.classList.toggle("dark");
        }
    
        let getStatus = localStorage.getItem("status");
        if(getStatus && getStatus ==="close"){
            sidebar.classList.toggle("close");
        }
    
        modeToggle.addEventListener("click", () =>{
            body.classList.toggle("dark");
            if(body.classList.contains("dark")){
                localStorage.setItem("mode", "dark");
            }else{
                localStorage.setItem("mode", "light");
            }
        });
    
        sidebarToggle.addEventListener("click", () => {
            sidebar.classList.toggle("close");
            if(sidebar.classList.contains("close")){
                localStorage.setItem("status", "close");
            }else{
                localStorage.setItem("status", "open");
            }
        })
    }
    return (
        <nav className='close'>
            <div className="modal fade modal-bg" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog shadow">
                    <div className="modal-content border shadow">
                        <div className="modal-header">
                            <h5 className="modal-title text-light" id="exampleModalLabel">
                                ANAKINGS HOMES AND PROPERTIES
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <ul className="nav nav-pills nav-fill">
                                <li className="nav-item">
                                    <a className="nav-link active"  id="login-tab" data-bs-toggle="tab" data-bs-target="#login" aria-current="page" href="#">Login</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#"  id="register-tab" data-bs-toggle="tab" data-bs-target="#register">Register</a>
                                </li>
                            </ul>
                            <hr className='hr'/>
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="login" role="tabpanel" aria-labelledby="login-tab">
                                    <LoginComponent />
                                </div>
                                <div className="tab-pane fade" id="register" role="tabpanel" aria-labelledby="register-tab">
                                    <RegisterComponent />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="logo-name">
                <div className="logo-image">
                    <img src={logo} alt="" className='rounded bg-light ps-2 pe-2'/>
                </div>
    
                <span className="logo_name" hidden>ANAKINGs HOMES</span>
                <i className="ps-4 fa-2x fa fa-window-close sidebar-toggle1"
                    onClick={()=>{                        
                        let body = document.querySelector("body");
                        var sidebar = body.querySelector("nav");
                        sidebar.classList.toggle("close");
                    }}
                ></i>
            </div>
    
            <div className="menu-items">
                <ul className="nav-links ps-0">
                    <li><a href="/">
                        <i className="fa fa-home"></i>
                        <span className="link-name">Home</span>
                    </a></li>
                    <li>{
                            !authenticated && 
                            <a href='#Login'  
                                data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fa fa-user"></i>
                                <span className="link-name">Login</span>
                            </a>
                        }
                    </li>
                    <li>{
                            !authenticated && 
                            <a href='/Register'>
                                <i className="fa fa-user-plus"></i>
                                <span className="link-name">Register</span>
                            </a>
                        }
                    </li>
                    <li>{
                            authenticated && 
                            <a href='/Dashboard'>
                                <i className="fa fa-chart-line"></i>
                                <span className="link-name">Dashboard</span>
                            </a>
                        }
                    </li>
                    {
                        // authenticated && userRole === 'buyer' &&
                        <li><a href="/Search-Properties">
                            <i className="fa fa-house-user"></i>
                            <span className="link-name">View Properties</span>
                        </a></li>
                    }
                    {
                        authenticated && (userRole === 'admin' ||
                        userRole === 'seller') &&
                        <li><a href="/Properties">
                            <i className="uil uil-files-landscapes"></i>
                            <span className="link-name">Properties</span>
                        </a></li>
                    }
                    {
                        authenticated && (userRole === 'admin' ||
                        userRole === 'seller') &&
                        <li><a href="/Properties-Create">
                            <i className="fa fa-plus-circle"></i>
                            <span className="link-name">New Property</span>
                        </a></li>
                    }
                    <li>
                        <a href="/Proposal">
                            <i className="fa fa-edit"></i>
                            <span className="link-name">New Proposal</span>
                        </a>
                    </li>
                    {
                        authenticated && userRole === 'admin' &&
                        <li>
                            <a href="/Proposals">
                                <i className="fa fa-newspaper"></i>
                                <span className="link-name">Proposals</span>
                            </a>
                        </li>
                    }
                    {
                        authenticated && userRole === 'admin' &&
                        <li><a href="/Users">
                            <i className="fa fa-users"></i>
                            <span className="link-name">Users</span>
                        </a></li>
                    }
                    <li><a href="/About">
                        <i className="fa fa-info"></i>
                        <span className="link-name">About</span>
                    </a></li>
                    <li><a href="/Support">
                        <i className="fa fa-question-circle"></i>
                        <span className="link-name">Support</span>
                    </a></li>
                </ul>
                
                <ul className="logout-mode">
                    <li>{
                            authenticated && 
                            <a href='/Profile'>
                                <i className="fa fa-info-circle"></i>
                                <span className="link-name">Profile</span>
                            </a>
                        }
                    </li>
                    <li>{
                            authenticated && <LogoutLink />
                        }
                    </li>
                </ul>
            </div>
        </nav>
    );
}


