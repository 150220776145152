//import logo from '../logo.png';
import logo from '../images/anaking_logo.png';
import React, { useEffect, useState } from 'react';
import TawkTo from 'tawkto-react';

export const FooterComponent = () => {
    useEffect(() => {
        //window.addEventListener('load', HandleLoad);
        var tawk = new TawkTo('5faa95ad8e1c140c2abca796', 'default')
        // var tawk = new TawkTo('', 'default')

        tawk.onStatusChange((status) => 
        {
            // console.log(status)
        })
    }, []);
    
    return (
        <div className="footer">
            <div className="row m-0">
                <div className="col-md-6 mb-2 pt-4 ps-4">
                    <a className='p-2 logo-a' href='/'>
                        <img src={logo} alt="" className='w-25 bg-light img-thumbnail rounded'/>
                    </a>
                    <p className='ps-2 text-light'>
                        Anakings provides a platform to market lands and properties in Africa, USA, Canada, Europe and Australia.
                    </p>
                    <p className='ps-2 text-secondary'>
                        <h6 className='text-info'>Head Office Address:</h6>
                        6815 Riverdale road, Riverdale, An Maryland 20737 USA
                    </p>
                    <p className='ps-2 text-secondary'>
                        <h6 className='text-info'>Branch Office Address:</h6>
                        No 15 Nnobi road, along Awka-Etiti rd, Nnobi, Anambra State, Nigeria
                    </p>
                    <a className='' href='/' hidden>
                        <p className='text-secondary h4 text-center'>
                            ANAKINGS HOMES AND PROPERTIES
                        </p>
                    </a>
                </div>
                <hr className='d-block d-md-none bg-secondary' />
                <div className="row col-md-6 text-center pt-4 ps-4">
                    <div className="row col-md-6 text-start">
                        <h5 className="text-light">Useful Links</h5>
                        <ul className=''>
                            <li><a className='text-secondary' href='/'>Home</a></li>
                            <li><a className='text-secondary' href='/About'>About</a></li>
                            <li><a className='text-secondary' href='/Career'>Career</a></li>
                            <li><a className='text-secondary' href='/Support'>Support</a></li>
                            <li><a className='text-secondary' href='/Register'>Register</a></li>
                            <li><a className='text-secondary' href='/Proposal'>Make Proposal</a></li>
                            <li><a className='text-secondary' href='/Search-Properties'>View Properties</a></li>
                        </ul>
                    </div>
                    <div className="row col-md-6 text-start">
                        <h5 className="text-light">Contacts</h5>
                        <p className='text-secondary'>
                            Email: <br/>
                            <a className='text-secondary' href='mailto:support@anakinghomes.com'>
                                support@anakinghomes.com
                            </a><br/>
                            <a className='text-secondary' href='mailto:anakinghomes@gmail.com'>
                                anakinghomes@gmail.com
                            </a>
                        </p>
                        <p className='text-secondary' href='/'>
                            <h6 className='text-info'>Tel (USA):</h6>
                            <a className='text-secondary' href='tel:443-477-1671'>443-477-1671</a>
                        </p>
                        <p className='text-secondary' href='/'>
                            <h6 className='text-info'>Tel (NG):</h6>
                            <a className='text-secondary' href='tel:+234-705-185-6197'>(+234)-705-185-6197</a>
                        </p>
                    </div>
                </div>
            </div>
            <div className="text-center text-light border-top">
                <p>Copyright &copy; 2022 ANAKINGS HOMES AND PROPERTIES</p>
            </div>
        </div>
    );
}