import React from 'react';
import auth from '../../auth/auth';
import { LoaderComponent } from '../loaderComponent';

export class ProfileUpdateComponent extends React.Component {
    // constructor(props) {
    //     super(props);
    //     // Don't do this!
    //     // this.state = { color: props.color };
    // }
    state = {
        isLoading: false, isUpLoading: false, userId:'', msgS: '', msgF: '', msgFLoad: '',
        first_name:'', last_name:'', middle_name:'', gender:'', email:'', phone:'',
        address:'', state:'', city:'', country:'', zip:'', logo: null, role:'', lga:'',
        selectedImage: null
    };

    componentDidMount() {
        auth.checkAuthentication().then(() => {
            if(!auth.authenticated){
                localStorage.setItem("navigating_to", window.location.href);
                window.location.href = '/Login';
            }else{
                (async () => {
                    var result = await auth.GetData('user/profile', 'GET');
                    //console.log(result);
                    
                    if(result["Error"] != null || result["Error"] !== undefined){
                        this.setState({msgFLoad: result.Error});
                        this.setState({isLoading: false});
                    }if(result["email"] != null || result["email"] !== undefined){
                        this.setState({userId: result.id})
                        this.setState({first_name: result.first_name})
                        this.setState({last_name: result.last_name})
                        this.setState({middle_name: result.middle_name})
                        this.setState({gender: result.gender})
                        this.setState({email: result.email})
                        this.setState({lga: result.lga})
                        this.setState({phone: result.phone})
                        this.setState({address: result.address})
                        this.setState({state: result.state})
                        this.setState({city: result.city})
                        this.setState({country: result.country})
                        this.setState({zip: result.zip})
                        this.setState({role: result.role})
                        //this.setState({logo: result.logo})
                        if(result.logo != null && result.logo !== ''){
                            this.setState({logo: auth.baseUrl() + 'users/profile_images/' + result.id})
                            return;

                            // var resultLogo = await auth.GetData('users/profile_images/' + result.id, 'GET', false);
                            // //console.log(resultLogo);
                            // if(resultLogo != null){
                            //     var blob = await resultLogo.blob();
                            //     this.setState({logo: URL.createObjectURL(blob)})
                            // }
                        }
                    }
                })().then(()=>{this.setState({isLoading: false})});
            }
        });

    }
    
  

    handleSubmit = async(e) => {
        e.preventDefault();
        var gender = this.state.gender;
        if(gender !== 'male'){
            gender = 'female';
        }

        if(this.state.selectedImage != null){
            if (!this.check_multifile_logo(this.state.selectedImage['name'])) {
                this.setState({msgF: 'We only accept JPG, JPEG, PNG, GIF and BMP files'});
                return;
            }
        }
        //console.log(this.state.gender)
        const body = JSON.stringify({
            first_name: this.state.first_name, last_name: this.state.last_name,
            middle_name: this.state.middle_name, gender: gender,
            email: this.state.email, lga: this.state.lga,
            phone: this.state.phone, address: this.state.address,
            state: this.state.state, city: this.state.city, 
            country: this.state.country,zip: this.state.zip,role: [this.state.role]
        });
            //logo: this.state.logo, 
        //console.log(body)
        this.setState({msgF: ""})
        this.setState({msgS: ""})

        this.setState({isUpLoading: true});
        //console.log(this.state.userId);
        var result = await auth.PostData(body, `users/update/${this.state.userId}`, 'PUT');
        //console.log(result);
        
        if(result["Error"] != null || result["Error"] !== undefined){
            this.setState({msgF: result.Error});
            this.setState({isLoading: false});
        }if(result.message === 'success'){
            if(this.state.selectedImage != null){
                var form_data = new FormData();
                form_data.append("file", this.state.selectedImage);

                if (this.check_multifile_logo(this.state.selectedImage['name'])) {
                    var resultFile = await auth.PostFileData(form_data, `users/upload/${this.state.userId}`, 'POST');
                    console.log(resultFile);
                    if(resultFile.Filename !== ''){
                        this.setState({msgF: 'Unable to upload image'});
                        this.setState({isUpLoading: false});
                        return;
                    }
                    
                } else {
                    alert('We only accept JPG, JPEG, PNG, GIF and BMP files');
                }
            }
            this.setState({msgS: "Profile Updated"})
        }else{
            this.setState({msgF: result.statusText})
        }
        this.setState({isUpLoading: false});
    }

    GetPhoneCode(val) {
        this.setState({country: val}); 
        var result = auth.GetPhoneCode(val);
        this.setState({zip: "+" + result}); 
    }
    
    check_multifile_logo(file) {
        var extension = file.substr((file.lastIndexOf('.') + 1))
        if (extension === 'jpg' || extension === 'jpeg' || extension === 'gif' || extension === 'png' || extension === 'bmp') {
            return true;
        } else {
            return false;
        }
    }

    render(){
        if(this.state.isLoading) 
            return (
                <LoaderComponent />
            );

            if(this.state.msgFLoad !== "") 
            return (
                <React.Fragment>
                    <div className='card-header text-center p-5 m-5'>
                        <h4 className='text-danger'>{this.state.msgFLoad}</h4>
                    </div>
                </React.Fragment>
            );
        return( 
            <section className="dashboard ps-0 pe-0 mt-4 mb-4">
                <div className="dash-content">
                    <div className="col-md-6 card shadow ms-2 me-2 p-0">
                        <div className='justify-content-center text-center border-bottom bg-light card-header p-2 m-0'>
                            {
                                (this.state.logo!=='' && this.state.logo!=null) &&
                                <img src={this.state.logo} alt="profile avartar" 
                                    className='profile-img img rounded img-thumbnail'
                                    />
                            }
                            {
                                (this.state.logo==='' || this.state.logo==null) &&
                                <div><span className='far fa-user fa-4x bg-light rounded img-thumbnail'></span></div>
                            }
                            <div>
                                <button className='btn btn-sm btn-primary' 
                                    onClick={()=>{document.getElementById('profileImage').click()}}
                                    >Change Image</button>
                            </div>
                            <input type="file" accept='image/png,image/jpeg,image/bmp' hidden id="profileImage" name="profileImage" onChange={(event) => {
                                    // console.log(event.target.files[0]);
                                    this.setState({selectedImage: event.target.files[0]});
                                    this.setState({logo: URL.createObjectURL(event.target.files[0])});
                                }}
                            />
                            <h5 className=''>{this.state.email}</h5>
                        </div>
                        <form>
                            <div className="card-body p-4">
                                <div className="form-group border-bottom">
                                    <p className='text-success'>{this.state.msgS}</p>
                                    <p className='text-danger'>{this.state.msgF}</p>
                                </div>
                                <div className="row">                        
                                    <div className="form-group col-md-6">
                                        <label className='control-label'>First name: </label>
                                        <input type='text' className='form-control' 
                                            name='first_name' id='first_name'
                                            value={this.state.first_name} autoFocus
                                            onChange={e=>{this.setState({first_name: e.target.value})}}
                                        />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label className='control-label'>Last name: </label>
                                        <input type='text' className='form-control'
                                            name='last_name' id='last_name'
                                            value={this.state.last_name} 
                                            onChange={e=>{this.setState({last_name: e.target.value})}}
                                        />
                                    </div>
                                </div>
                                <div className="row">                        
                                    <div className="form-group col-md-6">
                                        <label className='control-label'>Other names: </label>
                                        <input type='text' className='form-control' 
                                            name='middle_name' id='middle_name'
                                            value={this.state.middle_name} 
                                            onChange={e=>{this.setState({middle_name: e.target.value})}}
                                        />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label className='control-label'>Phone: </label>
                                        <input type='text' className='form-control' 
                                            name='phone' id='phone'
                                            value={this.state.phone} 
                                            onChange={e=>{this.setState({phone: e.target.value})}}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className='control-label'>Address: </label>
                                    <input type='text' className='form-control' 
                                        name='address' id='address'
                                        value={this.state.address} 
                                            onChange={e=>{this.setState({address: e.target.value})}}
                                    />
                                </div>
                                <div className="row">                        
                                    <div className="form-group col-md-6">
                                        <label className='control-label'>City: </label>
                                        <input type='text' className='form-control' 
                                            name='city' id='city'
                                            value={this.state.city} 
                                            onChange={e=>{this.setState({city: e.target.value})}}
                                        />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label className='control-label'>LGA: </label>
                                        <input type='text' className='form-control' 
                                            name='lga' id='lga'
                                            value={this.state.lga} 
                                            onChange={e=>{this.setState({lga: e.target.value})}}
                                        />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label className='control-label'>State: </label>
                                        <input type='text' className='form-control'
                                            name='state' id='state' value={this.state.state}
                                            onChange={e=>{this.setState({state: e.target.value})}}
                                        />
                                    </div>     
                                    <div className="form-group col-md-6">
                                        <label className='control-label'>Country: </label>
                                        <select className="form-control"  
                                            value={this.state.country} 
                                            onChange={e=>{this.GetPhoneCode(e.target.value);}}
                                            required>
                                            <option selected disabled value="">Choose Country</option>
                                            <option value="Afghanistan">Afghanistan</option>
                                            <option value="Albania">Albania</option>
                                            <option value="Algeria">Algeria</option>
                                            <option value="American Samoa">American Samoa</option>
                                            <option value="Andorra">Andorra</option>
                                            <option value="Angola">Angola</option>
                                            <option value="Anguilla">Anguilla</option>
                                            <option value="Antarctica">Antarctica</option>
                                            <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                            <option value="Argentina">Argentina</option>
                                            <option value="Armenia">Armenia</option>
                                            <option value="Aruba">Aruba</option>
                                            <option value="Australia">Australia</option>
                                            <option value="Austria">Austria</option>
                                            <option value="Azerbaijan">Azerbaijan</option>
                                            <option value="Bahamas">Bahamas</option>
                                            <option value="Bahrain">Bahrain</option>
                                            <option value="Bangladesh">Bangladesh</option>
                                            <option value="Barbados">Barbados</option>
                                            <option value="Belarus">Belarus</option>
                                            <option value="Belgium">Belgium</option>
                                            <option value="Belize">Belize</option>
                                            <option value="Benin">Benin</option>
                                            <option value="Bermuda">Bermuda</option>
                                            <option value="Bhutan">Bhutan</option>
                                            <option value="Bolivia">Bolivia</option>
                                            <option value="Bosnia and Herzegowina">Bosnia and Herzegowina</option>
                                            <option value="Botswana">Botswana</option>
                                            <option value="Bouvet Island">Bouvet Island</option>
                                            <option value="Brazil">Brazil</option>
                                            <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                                            <option value="Brunei Darussalam">Brunei Darussalam</option>
                                            <option value="Bulgaria">Bulgaria</option>
                                            <option value="Burkina Faso">Burkina Faso</option>
                                            <option value="Burundi">Burundi</option>
                                            <option value="Cambodia">Cambodia</option>
                                            <option value="Cameroon">Cameroon</option>
                                            <option value="Canada">Canada</option>
                                            <option value="Cape Verde">Cape Verde</option>
                                            <option value="Cayman Islands">Cayman Islands</option>
                                            <option value="Central African Republic">Central African Republic</option>
                                            <option value="Chad">Chad</option>
                                            <option value="Chile">Chile</option>
                                            <option value="China">China</option>
                                            <option value="Christmas Island">Christmas Island</option>
                                            <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                                            <option value="Colombia">Colombia</option>
                                            <option value="Comoros">Comoros</option>
                                            <option value="Congo">Congo</option>
                                            <option value="Congo, the Democratic Republic of the">Congo, the Democratic Republic of the</option>
                                            <option value="Cook Islands">Cook Islands</option>
                                            <option value="Costa Rica">Costa Rica</option>
                                            <option value="Cote d&#039;Ivoire">Cote d&#039;Ivoire</option>
                                            <option value="Croatia (Hrvatska)">Croatia (Hrvatska)</option>
                                            <option value="Cuba">Cuba</option>
                                            <option value="Cyprus">Cyprus</option>
                                            <option value="Czech Republic">Czech Republic</option>
                                            <option value="Denmark">Denmark</option>
                                            <option value="Djibouti">Djibouti</option>
                                            <option value="Dominica">Dominica</option>
                                            <option value="Dominican Republic">Dominican Republic</option>
                                            <option value="East Timor">East Timor</option>
                                            <option value="Ecuador">Ecuador</option>
                                            <option value="Egypt">Egypt</option>
                                            <option value="El Salvador">El Salvador</option>
                                            <option value="Equatorial Guinea">Equatorial Guinea</option>
                                            <option value="Eritrea">Eritrea</option>
                                            <option value="Estonia">Estonia</option>
                                            <option value="Ethiopia">Ethiopia</option>
                                            <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                                            <option value="Faroe Islands">Faroe Islands</option>
                                            <option value="Fiji">Fiji</option>
                                            <option value="Finland">Finland</option>
                                            <option value="France">France</option>
                                            <option value="France Metropolitan">France Metropolitan</option>
                                            <option value="French Guiana">French Guiana</option>
                                            <option value="French Polynesia">French Polynesia</option>
                                            <option value="French Southern Territories">French Southern Territories</option>
                                            <option value="Gabon">Gabon</option>
                                            <option value="Gambia">Gambia</option>
                                            <option value="Georgia">Georgia</option>
                                            <option value="Germany">Germany</option>
                                            <option value="Ghana">Ghana</option>
                                            <option value="Gibraltar">Gibraltar</option>
                                            <option value="Greece">Greece</option>
                                            <option value="Greenland">Greenland</option>
                                            <option value="Grenada">Grenada</option>
                                            <option value="Guadeloupe">Guadeloupe</option>
                                            <option value="Guam">Guam</option>
                                            <option value="Guatemala">Guatemala</option>
                                            <option value="Guinea">Guinea</option>
                                            <option value="Guinea-Bissau">Guinea-Bissau</option>
                                            <option value="Guyana">Guyana</option>
                                            <option value="Haiti">Haiti</option>
                                            <option value="Heard and Mc Donald Islands">Heard and Mc Donald Islands</option>
                                            <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                                            <option value="Honduras">Honduras</option>
                                            <option value="Hong Kong">Hong Kong</option>
                                            <option value="Hungary">Hungary</option>
                                            <option value="Iceland">Iceland</option>
                                            <option value="India">India</option>
                                            <option value="Indonesia">Indonesia</option>
                                            <option value="Iran (Islamic Republic of)">Iran (Islamic Republic of)</option>
                                            <option value="Iraq">Iraq</option>
                                            <option value="Ireland">Ireland</option>
                                            <option value="Israel">Israel</option>
                                            <option value="Italy">Italy</option>
                                            <option value="Jamaica">Jamaica</option>
                                            <option value="Japan">Japan</option>
                                            <option value="Jordan">Jordan</option>
                                            <option value="Kazakhstan">Kazakhstan</option>
                                            <option value="Kenya">Kenya</option>
                                            <option value="Kiribati">Kiribati</option>
                                            <option value="Korea, Democratic People&#039;s Republic of">Korea, Democratic People&#039;s Republic of</option>
                                            <option value="Korea, Republic of">Korea, Republic of</option>
                                            <option value="Kuwait">Kuwait</option>
                                            <option value="Kyrgyzstan">Kyrgyzstan</option>
                                            <option value="Lao, People&#039;s Democratic Republic">Lao, People&#039;s Democratic Republic</option>
                                            <option value="Latvia">Latvia</option>
                                            <option value="Lebanon">Lebanon</option>
                                            <option value="Lesotho">Lesotho</option>
                                            <option value="Liberia">Liberia</option>
                                            <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                                            <option value="Liechtenstein">Liechtenstein</option>
                                            <option value="Lithuania">Lithuania</option>
                                            <option value="Luxembourg">Luxembourg</option>
                                            <option value="Macau">Macau</option>
                                            <option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
                                            <option value="Madagascar">Madagascar</option>
                                            <option value="Malawi">Malawi</option>
                                            <option value="Malaysia">Malaysia</option>
                                            <option value="Maldives">Maldives</option>
                                            <option value="Mali">Mali</option>
                                            <option value="Malta">Malta</option>
                                            <option value="Marshall Islands">Marshall Islands</option>
                                            <option value="Martinique">Martinique</option>
                                            <option value="Mauritania">Mauritania</option>
                                            <option value="Mauritius">Mauritius</option>
                                            <option value="Mayotte">Mayotte</option>
                                            <option value="Mexico">Mexico</option>
                                            <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                                            <option value="Moldova, Republic of">Moldova, Republic of</option>
                                            <option value="Monaco">Monaco</option>
                                            <option value="Mongolia">Mongolia</option>
                                            <option value="Montserrat">Montserrat</option>
                                            <option value="Morocco">Morocco</option>
                                            <option value="Mozambique">Mozambique</option>
                                            <option value="Myanmar">Myanmar</option>
                                            <option value="Namibia">Namibia</option>
                                            <option value="Nauru">Nauru</option>
                                            <option value="Nepal">Nepal</option>
                                            <option value="Netherlands">Netherlands</option>
                                            <option value="Netherlands Antilles">Netherlands Antilles</option>
                                            <option value="New Caledonia">New Caledonia</option>
                                            <option value="New Zealand">New Zealand</option>
                                            <option value="Nicaragua">Nicaragua</option>
                                            <option value="Niger">Niger</option>
                                            <option value="Nigeria">Nigeria</option>
                                            <option value="Niue">Niue</option>
                                            <option value="Norfolk Island">Norfolk Island</option>
                                            <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                            <option value="Norway">Norway</option>
                                            <option value="Oman">Oman</option>
                                            <option value="Pakistan">Pakistan</option>
                                            <option value="Palau">Palau</option>
                                            <option value="Panama">Panama</option>
                                            <option value="Papua New Guinea">Papua New Guinea</option>
                                            <option value="Paraguay">Paraguay</option>
                                            <option value="Peru">Peru</option>
                                            <option value="Philippines">Philippines</option>
                                            <option value="Pitcairn">Pitcairn</option>
                                            <option value="Poland">Poland</option>
                                            <option value="Portugal">Portugal</option>
                                            <option value="Puerto Rico">Puerto Rico</option>
                                            <option value="Qatar">Qatar</option>
                                            <option value="Reunion">Reunion</option>
                                            <option value="Romania">Romania</option>
                                            <option value="Russian Federation">Russian Federation</option>
                                            <option value="Rwanda">Rwanda</option>
                                            <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                            <option value="Saint Lucia">Saint Lucia</option>
                                            <option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
                                            <option value="Samoa">Samoa</option>
                                            <option value="San Marino">San Marino</option>
                                            <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                            <option value="Saudi Arabia">Saudi Arabia</option>
                                            <option value="Senegal">Senegal</option>
                                            <option value="Seychelles">Seychelles</option>
                                            <option value="Sierra Leone">Sierra Leone</option>
                                            <option value="Singapore">Singapore</option>
                                            <option value="Slovakia (Slovak Republic)">Slovakia (Slovak Republic)</option>
                                            <option value="Slovenia">Slovenia</option>
                                            <option value="Solomon Islands">Solomon Islands</option>
                                            <option value="Somalia">Somalia</option>
                                            <option value="South Africa">South Africa</option>
                                            <option value="South Georgia and the South Sandwich Islands">South Georgia and the South Sandwich Islands</option>
                                            <option value="Spain">Spain</option>
                                            <option value="Sri Lanka">Sri Lanka</option>
                                            <option value="St. Helena">St. Helena</option>
                                            <option value="St. Pierre and Miquelon">St. Pierre and Miquelon</option>
                                            <option value="Sudan">Sudan</option>
                                            <option value="Suriname">Suriname</option>
                                            <option value="Svalbard and Jan Mayen Islands">Svalbard and Jan Mayen Islands</option>
                                            <option value="Swaziland">Swaziland</option>
                                            <option value="Sweden">Sweden</option>
                                            <option value="Switzerland">Switzerland</option>
                                            <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                                            <option value="Taiwan, Province of China">Taiwan, Province of China</option>
                                            <option value="Tajikistan">Tajikistan</option>
                                            <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                                            <option value="Thailand">Thailand</option>
                                            <option value="Togo">Togo</option>
                                            <option value="Tokelau">Tokelau</option>
                                            <option value="Tonga">Tonga</option>
                                            <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                            <option value="Tunisia">Tunisia</option>
                                            <option value="Turkey">Turkey</option>
                                            <option value="Turkmenistan">Turkmenistan</option>
                                            <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                                            <option value="Tuvalu">Tuvalu</option>
                                            <option value="Uganda">Uganda</option>
                                            <option value="Ukraine">Ukraine</option>
                                            <option value="United Arab Emirates">United Arab Emirates</option>
                                            <option value="United Kingdom">United Kingdom</option>
                                            <option value="United States">United States</option>
                                            <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                                            <option value="Uruguay">Uruguay</option>
                                            <option value="Uzbekistan">Uzbekistan</option>
                                            <option value="Vanuatu">Vanuatu</option>
                                            <option value="Venezuela">Venezuela</option>
                                            <option value="Vietnam">Vietnam</option>
                                            <option value="Virgin Islands (British)">Virgin Islands (British)</option>
                                            <option value="Virgin Islands (U.S.)">Virgin Islands (U.S.)</option>
                                            <option value="Wallis and Futuna Islands">Wallis and Futuna Islands</option>
                                            <option value="Western Sahara">Western Sahara</option>
                                            <option value="Yemen">Yemen</option>
                                            <option value="Yugoslavia">Yugoslavia</option>
                                            <option value="Zambia">Zambia</option>
                                            <option value="Zimbabwe">Zimbabwe</option>
                                        </select>
                                    </div>                  
                                    <div className="form-group col-md-6">
                                        <label className='control-label'>Zip: </label>
                                        <input type='text' className='form-control' value={this.state.zip} 
                                            id='countryCode'
                                            onChange={e=>{this.setState({zip: e.target.value})}}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='text-center mt-4 border-top pt-2 bg-light m-0 p-2 card-footer'>
                                { 
                                    !this.state.isUpLoading && <button 
                                        className='btn btn-primary col-md-6'
                                        onClick={this.handleSubmit}
                                    >Update Profile</button>
                                }
                                { 
                                    this.state.isUpLoading && <button disabled
                                        className='btn btn-primary col-md-6'>
                                        <i className='fa fa-spinner fa-spin me-2'></i>
                                        Updating Profile...</button>
                                }
                                <div className="form-group">
                                    <p className='text-success'>{this.state.msgS}</p>
                                    <p className='text-danger'>{this.state.msgF}</p>
                                </div>
                                <a href='/Profile' className=''>Back To Profile</a>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        )
    }
}
 
export default ProfileUpdateComponent;