import React, { useState } from 'react';
import {Navigate } from 'react-router-dom'
import auth from '../auth/auth';


export const LoginComponent = (props) => {
 
    const [username, setUsername]= useState();
    const [password, setPassword]= useState();
    const [redirect, setRedirect]= useState(false);
    const [message, setMessage]= useState('');
    const [passwordType, setPasswordType]= useState('password');
    const [isLoading, setIsLoading]= useState(false);

    const togglePWD = (e) => {
        // toggle the type attribute
        const pwd = document.getElementById("Password");
        if(pwd==null) return;
        const newType = passwordType === "password" ? "text" : "password";
        setPasswordType(newType);
        // toggle the eye icon
        e.className = passwordType === "password" ? 'far fa-eye-slash' : 'far fa-eye';
    };

    const validatePassword = (e) => {
        var validated =  true;
        if(e.value.length < 8)
            validated = false;
        else if(!/\d/.test(e.value))
            validated = false;
        else if(!/[a-z]/.test(e.value))
            validated = false;
        else if(!/[A-Z]/.test(e.value))
            validated = false;
        else if(/[a-zA-Z0-9]{8,}/.test(e.value))
            validated = false;

        setMessage('');
        e.style.borderColor = "green";
        if(!validated){
            setMessage("Password must contain at least one digit, At least one lower case, At least one upper case, At least 8 from the mentioned characters");
            e.style.borderColor = "red";
        }
        return validated;
    }
    const handleSubmit = (btn) => {
        try {
            const pass = document.getElementById('Password');
            pass.value = password;
            if(!validatePassword(pass)){
                return;
            }
            setIsLoading(true);
            const userData = JSON.stringify({
                "email": username,
                "password": password
                });
            //console.log(userData);
            fetch(auth.baseUrl() + 'users/login', {
                method: 'POST', 
                headers: {
                    'content-type': 'application/json'
                },
                credentials: 'include',
                body: userData
            }).then((response) => {
                setIsLoading(false);
                if(response.status === 200){
                    response.json().then(result => {
                        console.log(result);
                        if(result["error"] != null || result["error"] !== undefined){
                            setMessage(result.error);
                        }else if(result["access token"] != null || result["access token"] !== undefined){
                            setMessage("Successfull Login");
                            localStorage.setItem('access_token', result['access token'])
                            auth.setLogin();
                            if(localStorage.getItem("navigating_to") != null){
                                var ref = localStorage.getItem("navigating_to");
                                localStorage.removeItem("navigating_to");
                                window.location.href = ref;
                            }else{
                                window.location.href = '/Profile' ;
                            }
                            setRedirect(true);
                            return;
                        }else{
                            setMessage(result);
                        }
                    });
                }else{
                    setMessage("An error occured");
                }
            });
        } catch (error) {
            setMessage(error.toString().replace("TypeError: ", ""));
        }

        if(redirect){
            window.location.href = '/Dashboard' ;
            return <Navigate to='/Dashboard' replace />
        }
    }

    return ( 
        <React.Fragment>
            <form>
                <div className='form-group'>
                    <label className='control-label lbl'>Email</label>
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1">@</span>
                        <input required className='form-control' type='email' 
                            name='Email' id='Email' placeholder='Enter Email'
                            value={username} autoFocus
                            onChange={e => {
                                setUsername(e.target.value);
                                if(e.target.value === 'Administrator'){
                                    e.target.type = 'text';
                                }else{
                                    e.target.type = 'email'
                                }
                            }}
                            aria-describedby="basic-addon1"
                            />
                    </div>
                </div>
                <div className='form-group'>
                    <label className='control-label lbl'>Password</label>
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1"><i className='fa fa-key'></i></span>
                        <input required className='form-control' type={passwordType} 
                            name='Password' id='Password' placeholder='Enter Password'
                            value={password}
                            onChange={e => {setPassword(e.target.value); validatePassword(e.target)}}
                            aria-describedby="basic-addon1"
                            />
                        <span className="input-group-text"><i className="far fa-eye-slash" 
                            onClick={e=>{togglePWD(e.target)}}></i></span>
                    </div>
                </div>
                <hr className='hr'/>
                <div className='form-group text-center'>
                    {isLoading &&
                        <button 
                            type='button'
                            className='btn btn-primary btn-5'>
                            <i className='fa fa-spinner fa-spin'></i> Submiting...
                        </button>
                    }{!isLoading &&
                        <button 
                            className='btn btn-primary btn-5'
                            onClick={e => {
                                e.preventDefault();
                                return handleSubmit(this)
                            }}>Login</button>
                    }
                    <div className='text-warning'>
                        {message}
                    </div>
                </div>
            </form>
        </React.Fragment>
    );
}