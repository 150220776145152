import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import auth from '../../auth/auth';
import { Property } from '../../models/statics';
import { LoaderComponent } from '../loaderComponent';
import PropertyComponent from './PropertyComponent';


export default class UserPropertiesComponent extends Component {
    constructor(props) {
        super(props);
        
    }
    state = { 
        pageNumber: 0, countPerPage: 15, sort_by: 'title', sort_by_order: 1,
        SearchValue: '',negotiable: -1, user_type: -1, status: -1,
        step: 0, property_id: this.props.property_id,
        isLoading: false, isUpLoading: false, userId:'', msgS: '', msgF: '',
        properties: [],
    }

    componentDidMount() {
        auth.checkAuthentication().then(() => {
        });
        if(this.props.searchString != null && this.props.searchString !== undefined && 
            this.props.searchString !== ''){
            this.setSearchValue(this.props.searchString)
        }
        this.searchValue();
    }
    
    pagesVisited = () =>{
        return this.state.pageNumber * this.state.countPerPage;
    }
    pageCount = () =>{
        return Math.ceil(this.state.properties.length / this.state.countPerPage);
    }

    changePage=({selected})=>{
        this.setState({pageNumber: selected});
    }

    displayItems = () =>{
        return this.state.properties.slice(
            this.pagesVisited(), this.pagesVisited() + this.state.countPerPage
        ).map(property => {return(
                <div className="col-md-4">
                    <PropertyComponent value={property} key={property.id} 
                    //onDelete={this.handleDelete}
                    //onIncreament={this.handleIncreament}
                    />
                </div>
            )}
        )
    }

    searchValue = () => {
        (async () => {
            this.setState({isLoading: true});
            this.setState({property_id: this.props.property_id});
            this.setState({user_id: this.props.user_id});

            //search_string: str = '', negotiable: int = -1, user_type: int = -1, status
            var url = ``;
            if(this.props.is_cart)
                url = `users/get_from_cart?` + new URLSearchParams({
                    search_string: this.state.SearchValue
                });
            else
                url = `properties?` + new URLSearchParams({
                    search_string: this.state.SearchValue, negotiable: this.state.negotiable,
                    user_type: -1, status:1
                });

            var result = await auth.GetData(url, 'GET');
            // console.log(result);
            if(result["Error"] != null || result["Error"] !== undefined){
                this.setState({msgF: result.Error});
                this.setState({isLoading: false});
            }if(result["details"] == null){
                this.setState({msgF: "No result found"})
            }else if(result["details"] !== null || result["details"] !== undefined){
                //const {properties} = this.state;
                const newProperties = [];
                result["details"].forEach(prop => {
                    var details = prop.details;
                    // console.log(prop.locations);
                    newProperties.push(new Property(
                        details.id, details.title, details.price,
                        details.negotiable, details.description, details.size,
                        details.added_by, details.status, details.time_posted,
                        prop.locations, prop.graphics
                    ))
                })
                this.setState({properties: newProperties});
            }
        })().then(()=>{this.setState({isLoading: false})});
    }

    clearPropertyMsg(property_id){
        const {properties} = this.state;
        properties.forEach(prop => {
            if(prop.id===property_id){
                prop.msg = '';
                return
            }
        })
        this.setState({properties})
    }

    setSearchValue(val){
        this.setState({SearchValue: val});
    }

    setSortBy(val){
        this.setState({sort_by: val});
        const {properties} = this.state;
        properties.sort(auth.dynamicSort(val, this.state.sort_by_order));
    }

    setSortByOrder(val){
        this.setState({sort_by_order: val});
        const {properties} = this.state;
        properties.sort(auth.dynamicSort(this.state.sort_by, val));
    }

    render() { 
        if(this.state.isLoading) 
            return (
                <LoaderComponent />
            );
            
        return ( 
            <React.Fragment>
                <div className=''>
                    <div className='col-md-12'>
                        <div className='shadow m-2 p-2'>
                                <div className="card-body">
                                    <div className="form-group">
                                        <p className='text-success'>{this.state.msgS}</p>
                                        <p className='text-danger'>{this.state.msgF}</p>
                                    </div>
                                    <div className='card-header p-2 mt-2 text-primary'>
                                        {this.props.is_cart && <h5>Available Properties in Cart</h5>}
                                        {!this.props.is_cart && <h5>Available Properties</h5>}
                                        <p>These properties are available in the database.</p>
                                    </div>
                                    <div className="text-start mt-2">
                                        <h6 className='control-label'>Filter:</h6>
                                        <div className="row">
                                            <div className="form-group col-md-3">
                                                <label className='control-label'>Negotiable?</label>
                                                <select className='form-select'
                                                    onChange={(e) => {this.setState({negotiable: e.target.value})}}
                                                    value={this.state.negotiable}>
                                                    <option value="-1" selected>All</option>
                                                    <option value="0">Negotiable</option>
                                                    <option value="1">Non Negotiable</option>
                                                </select>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="form-group col-md-4 float-start mb-2">
                                                <div className="input-group">
                                                    <input type="text" className='form-control' 
                                                        placeholder='Search by size, street, City, Zip, Country'
                                                        value={this.state.SearchValue}
                                                        onChange={(e)=>{this.setSearchValue(e.target.value)}}
                                                        onKeyDown={(e)=>{
                                                            if (e.key === "Enter") {
                                                                // Cancel the default action, if needed
                                                                e.preventDefault();
                                                                // Trigger the button element with a click
                                                                this.searchValue()
                                                              }}}
                                                    />
                                                    <span className="input-group-text btn btn-primary"
                                                        onClick={(e)=>{this.searchValue()}}
                                                        ><i className=' fa fa-search'></i></span>
                                                </div>
                                            </div>
                                            <div className="col-md-8 text-end pe-4">
                                                <label className='control-label mw-150 pe-2'>Sort By:</label>
                                                <select className='mw-150' 
                                                    onChange={(e) => this.setSortBy(e.target.value)}
                                                >
                                                    <option value="title" selected>Title</option>
                                                    <option value="price">Price</option>
                                                    <option value="size">Size</option>
                                                    <option value="status">Status</option>
                                                    <option value="time_posted">Creation Date</option>
                                                </select>
                                                <select className='mw-150'
                                                    onChange={(e) => this.setSortByOrder(e.target.value)}>
                                                    <option value="1" selected>Asc</option>
                                                    <option value="-1">Desc</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row p-2">
                                        <div className="row p-2">
                                            {this.displayItems()}
                                        </div>
                                        <hr />
                                        <ReactPaginate 
                                            previousLabel={'Previous'}
                                            nextLabel={'Next'}
                                            pageCount={this.pageCount()}
                                            onPageChange={this.changePage}
                                            previousLinkClassName={'btn btn-sm btn-primary'}
                                            nextLinkClassName={'btn btn-sm btn-primary'}
                                            activeClassName={'btn btn-sm btn-primary'}
                                            disabledClassName={'btn btn-sm disabled'}
                                            
                                        />
                                    </div>
                                </div>
                        </div>
                    </div>
                    <div className='col-md-5 mt-4 mb-4 p-2'>
                        {/* <img src='' /> */}
                    </div>
                </div>
            </React.Fragment>
         );
    }
}