import React, { Component, useState, useEffect } from 'react';
import auth from '../auth/auth';
import { LoaderComponent } from './loaderComponent';

export default class DashboardComponent extends Component {
    constructor(props) {
        super(props);
    }
    state = { 
        isLoading: false, isUpLoading: false, msgS: '', msgF: '',
        users: 0, PropertiesAvailable: 0, PropertiesPending: 0,
        ProposalsAvailable: 0, ProposalsPending: 0,
    } 

    componentDidMount() {
        auth.checkAuthentication().then(() => {
            if(auth.userRole === 'admin'){
                this.getUsers();
                this.getProperties();
                this.getProposals();
            }
            else if(auth.userRole === 'seller'){
                this.getProperties();
            }
        });
    }

    getUsers = () => {
        (async () => {
            this.setState({isLoading: true});
            //search_string: str = '', negotiable: int = -1, user_type: int = -1, status
            var url = `users?` + new URLSearchParams({
                status:-1
            });
            var result = await auth.GetData(url, 'GET');
            if(result["Error"] != null || result["Error"] !== undefined){
                this.setState({msgF: result.Error});
                this.setState({isLoading: false});
            }else if(result["details"] != null || result["details"] !== undefined){
                this.setState({users: result["details"].length});
            }
        })().then(()=>{this.setState({isLoading: false})});
    }

    getProperties = () => {
        (async () => {
            this.setState({isLoading: true});
            //search_string: str = '', negotiable: int = -1, user_type: int = -1, status
            var url = `properties?`;

            var result = await auth.GetData(url, 'GET');
            if(result["Error"] != null || result["Error"] !== undefined){
                this.setState({msgF: result.Error});
                this.setState({isLoading: false});
            }else if(result["details"] != null || result["details"] !== undefined){
                //const {properties} = this.state;
                var {PropertiesAvailable,PropertiesPending} = this.state;
                PropertiesAvailable = 0; PropertiesPending = 0;

                result["details"].forEach(prop => {
                    var details = prop.details;
                    if(auth.userRole === "admin" || details.added_by === auth.userId){                        
                        if(details.status === 0){
                            PropertiesPending += 1;
                        }
                        else if(details.status === 1){
                            PropertiesAvailable += 1;
                        }
                    }
                })
                this.setState({PropertiesAvailable});
                this.setState({PropertiesPending});
            }
        })().then(()=>{this.setState({isLoading: false})});
    }

    getProposals = () => {
        (async () => {
            //alert(this.props.proposal_id)
            this.setState({isLoading: true});
            var result = await auth.GetData(`proposals`, 'GET');
            //console.log(result);
            if(result["Error"] != null || result["Error"] !== undefined){
                this.setState({msgF: result.Error});
                this.setState({isLoading: false});
            }if(result["details"] == null){
                this.setState({msgF: "No result found"})
            }else if(result["details"] != null || result["details"] !== undefined){
                result["details"].forEach(prop => {
                    var details = prop.details;
                    if(details.status === 0){
                        this.setState({ProposalsPending: this.state.ProposalsPending + 1});
                    }else{
                        this.setState({ProposalsAvailable: this.state.ProposalsAvailable + 1});
                    }
                        // newproposals.push(new proposal(
                    //     details.id, details.name, 
                    //     details.email, details.phone, details.country,
                    //     details.state, details.city, details.zip,
                    //     details.priceStart, details.priceStop,
                    //     details.description, details.size,
                    //     details.status, details.time_posted
                    // ))
                })
            }
        })().then(()=>{this.setState({isLoading: false})});
    }

    render() { 
        if(this.state.isLoading) 
            return (
                <LoaderComponent />
            );

        return (
            <section className="dashboard bg-light">
                <div className="">
                    <div className="overview">
                        <div className="title">
                            <i className="uil uil-tachometer-fast-alt"></i>
                            <span className="text-primary text">Dashboard</span>
                        </div>
                        <div className="card-header text-center">
                            <h4>{"Welecome " + auth.userEmail}</h4>
                        </div>

                        { auth.userRole === "admin" &&
                            <div className="row justify-content-center p-2 text-center">
                                <div className="col-md-3 m-2">
                                    <a href='/Proposals' className='btn btn-primary'>
                                        View Proposals
                                    </a>
                                </div>
                                <div className="col-md-3 m-2">
                                    <a href='/Subscribers' className='btn btn-secondary'>
                                        View Subscribers
                                    </a>
                                </div>
                                <div className="col-md-3 m-2">
                                    <a href='/Support-Admin' className='btn btn-primary'>
                                        Send Support Mail
                                    </a>
                                </div>
                            </div>
                        }
                        <hr />
                        { auth.userRole === "admin" &&
                            <div className="row justify-content-center">
                                <div className="col-md-3 box box1">
                                    <i className="fas fa-users text-light "></i>
                                    <span className="text-light text">Total Users</span>
                                    <hr className='bg-dark border-2 p-2 w-100'/>
                                    <span className="text-light number">{this.state.users}</span>
                                </div>
                                <div className="col-md-3 box box2">
                                    <i className="far fa-folder-open"></i>
                                    <span className="text">Available Properties</span>
                                    <hr className='bg-dark border-2 p-2 w-100'/>
                                    <span className="number">{this.state.PropertiesAvailable}</span>
                                </div>
                                <div className="col-md-3 box box3">
                                    <i className="text-primary fa fa-paper-plane"></i>
                                    <span className="text-primary text">Pending Properties</span>
                                    <hr className='bg-dark border-2 p-2 w-100'/>
                                    <span className="text-primary number">{this.state.PropertiesPending}</span>
                                </div>
                                <div className="bg-dark col-md-3 box box1">
                                    <i className="fas fa-users text-light "></i>
                                    <span className="text-light text">Pending Proposals</span>
                                    <hr className='bg-secondary border-2 p-2 w-100'/>
                                    <span className="text-light number">{this.state.ProposalsPending}</span>
                                </div>
                                <div className="bg-light col-md-3 box box2">
                                    <i className="fas fa-users "></i>
                                    <span className="text">Available Proposals</span>
                                    <hr className='bg-dark border-2 p-2 w-100'/>
                                    <span className="number">{this.state.ProposalsAvailable}</span>
                                </div>
                            </div>
                        }

                        { auth.userRole === "seller" &&
                            <div className="row justify-content-center">
                                <div className="col-md-3 box box2">
                                    <i className="far fa-folder-open"></i>
                                    <span className="text">Available Properties</span>
                                    <hr className='bg-dark border-2 p-2 w-100'/>
                                    <span className="number">{this.state.PropertiesAvailable}</span>
                                </div>
                                <div className="col-md-3 box box3">
                                    <i className="text-primary fa fa-paper-plane"></i>
                                    <span className="text-primary text">Pending Properties</span>
                                    <hr className='bg-dark border-2 p-2 w-100'/>
                                    <span className="text-primary number">{this.state.PropertiesPending}</span>
                                </div>
                            </div>
                        }
                        <hr />
                    </div>
                </div>
            </section>
        );

    }
}
export const DashboardComponent12 = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isUpLoading, setIsUpLoading] = useState(false);
    const [users, setUsers] = useState(0);
    const [PropertiesAvailable, setPropertiesAvailable] = useState(0);
    const [PropertiesPending, setPropertiesPending] = useState(0);
    const [msgS, setmsgS] = useState('');
    const [msgF, setmsgF] = useState('');

    useEffect(() => {
        auth.checkAuthentication().then(() => {
            if(!auth.isAuthenticated){
                localStorage.setItem("navigating_to", window.location.href);
                window.location.href = '/Login';
            }
            if(auth.userRole === 'admin'){
                getUsers();
                getProperties();
            }
        });
    }, []);

    var getUsers = () => {
        (async () => {
            setIsLoading(isLoading);
            //search_string: str = '', negotiable: int = -1, user_type: int = -1, status
            var url = `users?` + new URLSearchParams({
                status:-1
            });
            var result = await auth.GetData(url, 'GET');
            if(result["Error"] != null || result["Error"] !== undefined){
                this.setState({msgF: result.Error});
                this.setState({isLoading: false});
            }else if(result["details"] != null || result["details"] !== undefined){
                setUsers(result["details"][0].length);
            }
        })().then(()=>{setIsLoading(false)});
    }

    var getProperties = () => {
        (async () => {
            this.setState({isLoading: true});
            //search_string: str = '', negotiable: int = -1, user_type: int = -1, status
            var url = `properties?`;

            var result = await auth.GetData(url, 'GET');
            if(result["Error"] != null || result["Error"] !== undefined){
                this.setState({msgF: result.Error});
                this.setState({isLoading: false});
            }else if(result["details"] != null || result["details"] !== undefined){
                //const {properties} = this.state;
                var {PropertiesAvailable,PropertiesPending} = this.state;
                PropertiesAvailable = 0; PropertiesPending = 0;

                result["details"].forEach(prop => {
                    var details = prop.details;
                    if(details.status === 0){
                        PropertiesPending += 1;
                    }
                    else if(details.status === 1){
                        PropertiesAvailable += 1;
                    }
                })
                this.setState({PropertiesAvailable});
                this.setState({PropertiesPending});
            }
        })().then(()=>{this.setIsLoading(false)});
    }

    if(isLoading) 
        return (
            <LoaderComponent />
        );

    return (
        <section className="dashboard bg-light">
            <div className="">
                <div className="overview">
                    <div className="title">
                        <i className="uil uil-tachometer-fast-alt"></i>
                        <span className="text-primary text">Dashboard</span>
                    </div>
                    <div className="card-header text-center">
                        <h4>{"Welecome " + auth.userEmail}</h4>
                    </div>

                    { auth.userRole === "admin" &&
                        <div className="row justify-content-center p-2 text-center">
                            <div className="col-md-3">
                                <a href='/Proposals' className='btn btn-primary'>
                                    View Proposals
                                </a>
                            </div>
                            <div className="col-md-3">
                                <a href='/Subscribers' className='btn btn-secondary'>
                                    View Subscribers
                                </a>
                            </div>
                            <div className="col-md-3">
                                <a href='/Support-Admin' className='btn btn-primary'>
                                    Send Support Mail
                                </a>
                            </div>
                        </div>
                    }

                    { auth.userRole === "admin" &&
                        <div className="row justify-content-center">
                            <div className="col-md-3 box box1">
                                <i className="fas fa-users text-light "></i>
                                <span className="text-light text">Total Users</span>
                                <hr className='bg-dark border-2 p-2 w-100'/>
                                <span className="text-light number">{users}</span>
                            </div>
                            <div className="col-md-3 box box2">
                                <i className="far fa-folder-open"></i>
                                <span className="text">Available Properties</span>
                                <hr className='bg-dark border-2 p-2 w-100'/>
                                <span className="number">{PropertiesAvailable}</span>
                            </div>
                            <div className="col-md-3 box box3">
                                <i className="text-primary fa fa-paper-plane"></i>
                                <span className="text-primary text">Pending Properties</span>
                                <hr className='bg-dark border-2 p-2 w-100'/>
                                <span className="text-primary number">{PropertiesPending}</span>
                            </div>
                        </div>
                    }
                    <hr />
                </div>
            </div>
        </section>
    );
}