import {LoginComponent} from '../components/LoginComponent'
import logo from '../logo.png';

export const LoginPage = () => {
    return(
        <div className="modal-dialog shadow">
            <div className="modal-content border shadow">
                <div className="modal-header">
                    <h5 className="modal-title text-white" id="exampleModalLabel">Login</h5>
                </div>
                <div className="modal-body modal-bg m-0 p-2">
                    <div className='text-center'>
                        <span hidden className='fa fa-key fa-4x img-thumbnail'></span>
                        <img src={logo} alt="logo" className='img-thumbnail' />
                    </div>
                    <hr className='hr'/>
                    <p className='modal-bg'>Welcome Back. Login to access your account.</p>
                    <hr className='hr'/>
                    <div className="tab-content bg-white">
                        <div className="tab-pane fade show active" id="login" role="tabpanel" aria-labelledby="login-tab">
                            <LoginComponent />
                        </div>
                    </div>
                    <p>Don't have account? <a href="/Register" className=''>Create New Account</a></p>
                </div>
            </div>
        </div>
    )
}