import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import auth from '../../auth/auth';
import { LoaderComponent } from '../loaderComponent';


export default class SubscribersComponent extends Component {
    constructor(props) {
        super(props);
    }
    state = { 
        pageNumber: 0, countPerPage: 15, sort_by: 'title', sort_by_order: 1,
        SearchValue: '',
        isLoading: false, isUpLoading: false, userId:'', msgS: '', msgF: '',
        users: [],
    }

    componentDidMount() {
        auth.checkAuthentication().then(() => {
            if(auth.userRole !== 'admin'){
                localStorage.setItem("navigating_to", window.location.href);
                window.location.href = '/Login';
            }else
                this.searchValue();
        });
    }
    
    searchValue = () => {
        (async () => {
            this.setState({isLoading: true});
            //search_string: str = '', negotiable: int = -1, user_type: int = -1, status
            var url = `subscribers`;
            if(this.state.SearchValue !== "")
                url += "?" + new URLSearchParams({
                search_string: this.state.SearchValue
            });
            var result = await auth.GetData(url, 'GET');
            if(result["Error"] != null || result["Error"] !== undefined){
                this.setState({msgF: result.Error});
                this.setState({isLoading: false});
            }if(result["details"] == null){
                this.setState({msgF: "No result found"})
            }else if(result["details"] != null || result["details"] !== undefined){
                var {users} = this.state;
                users = [];
                console.log(result["details"])
                result["details"].forEach(prop => {
                    const user = prop;
                    users.push(user)
                })
                this.setState({users});
            }
        })().then(()=>{this.setState({isLoading: false})});
    }
    pagesVisited = () =>{
        return this.state.pageNumber * this.state.countPerPage;
    }
    pageCount = () =>{
        return Math.ceil(this.state.users.length / this.state.countPerPage);
    }

    changePage=({selected})=>{
        this.setState({pageNumber: selected});
    }

    displayItems = () =>{
        return this.state.users.slice(
            this.pagesVisited(), this.pagesVisited() + this.state.countPerPage
        ).map(user => {return(<div className="p-2">
                <div className='card-header row border'>
                    <div className="col-md-3"> 
                        <label className='h6'>Email:</label>
                        &nbsp; {user.email}
                    </div>
                    <div className="col-md-6">
                        <label className='h6'>Date:</label>
                        &nbsp;{user.created_on}
                    </div>
                    <div className="col-md-3">   
                        <a href='#Delete' type='button' 
                            onClick={()=>{
                                this.deleteUser(user.email)
                            }}
                            className='btn-sm btn-danger float-end'
                        ><span className='fa fa-trash'></span></a>                     
                        <a href={'/Support-Admin/' + user.email} type='button' 
                            className='btn-sm btn-primary float-end me-2'
                        ><span className='fa fa-envelope'></span></a>
                    </div>
                </div>
            </div>)}
        )
    }

    deleteUser(user_email){
        (async () => {
            this.setState({msgF: ""})
            this.setState({msgS: ""})
            var answer = window.confirm("Delete " + user_email + "?");
            if(answer){
                this.setState({isUpLoading: true});
                var result = await auth.PostData(null, `subscribers/` + user_email, 'DELETE');
                //console.log(result);
                if(result["Error"] != null || result["Error"] !== undefined){
                    this.setState({msgF: result.Error});
                    this.setState({isLoading: false});
                }if(result['message'] === 'success'){
                    this.setState({msgS: "User deleted"})
                    this.setState({isUpLoading: false});
                    const users = this.state.users.filter(c=>c.email !== user_email);
                    this.setState({users});
                }else{
                    this.setState({msgF: result.statusText})
                }
                this.setState({isUpLoading: false});
            }
        })().then(()=>{this.setState({isLoading: false})});
    }

    clearUserMsg(user_id){
        const {users} = this.state;
        users.forEach(prop => {
            if(prop.id===user_id){
                prop.msg = '';
                return
            }
        })
        this.setState({users})
    }

    setSearchValue(val){
        this.setState({SearchValue: val});
    }

    setSortBy(val){
        this.setState({sort_by: val});
        const {properties} = this.state;
        properties.sort(auth.dynamicSort(val, this.state.sort_by_order));
    }

    setSortByOrder(val){
        this.setState({sort_by_order: val});
        const {properties} = this.state;
        properties.sort(auth.dynamicSort(this.state.sort_by, val));
    }

    render() { 
        if(this.state.isLoading) 
            return (
                <LoaderComponent />
            );

        return ( 
            <React.Fragment>
                <div className=''>
                    <div className='col-md-12'>
                        <div className='shadow m-2 p-2'>
                                <div className="card-body">
                                    <div className="form-group">
                                        <p className='text-success'>{this.state.msgS}</p>
                                        <p className='text-danger'>{this.state.msgF}</p>
                                    </div>
                                    <div className='card-header p-2 mt-2'>
                                        <h5 className='text-primary'>Available Subscribers</h5>
                                        <p>These subscribers are available in the database.</p>
                                    </div>
                                    <div className="text-start mt-2">
                                        <div className="row">
                                            <div className="form-group col-md-6 float-start mb-2">
                                                <input type="text" className='form-control-sm me-2 mb-2' 
                                                    placeholder='Enter Search Keyword'
                                                    value={this.state.SearchValue}
                                                    onChange={(e)=>{this.setSearchValue(e.target.value)}}
                                                    onKeyDown={(e)=>{
                                                        if (e.key === "Enter") {
                                                            // Cancel the default action, if needed
                                                            e.preventDefault();
                                                            // Trigger the button element with a click
                                                            this.searchValue()
                                                          }}}
                                                />
                                                <button className='btn btn-sm btn-primary'
                                                    onClick={(e)=>{this.searchValue()}}>
                                                    <i className='fa fa-search me-2'></i>Search
                                                </button>
                                            </div>
                                            <div className="col-md-6 text-end pe-4">
                                                <label className='control-label mw-150 pe-2'>Sort By:</label>
                                                <select className='mw-150' 
                                                    onChange={(e) => this.setSortBy(e.target.value)}
                                                >
                                                    <option value="email" selected>Email</option>
                                                    <option value="created_on">Creation Date</option>
                                                </select>
                                                <select className='mw-150'
                                                    onChange={(e) => this.setSortByOrder(e.target.value)}>
                                                    <option value="1" selected>Asc</option>
                                                    <option value="-1">Desc</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row p-2">
                                        {this.displayItems()}
                                        <hr />
                                        <ReactPaginate 
                                            previousLabel={'Previous'}
                                            nextLabel={'Next'}
                                            pageCount={this.pageCount()}
                                            onPageChange={this.changePage}
                                            previousLinkClassName={'btn btn-sm btn-primary'}
                                            nextLinkClassName={'btn btn-sm btn-primary'}
                                            activeClassName={'btn btn-sm btn-primary'}
                                            disabledClassName={'btn btn-sm disabled'}
                                            
                                        />
                                    </div>
                                </div>
                        </div>
                    </div>
                    <div className='col-md-5 mt-4 mb-4 p-2'>
                        {/* <img src='' /> */}
                    </div>
                </div>
            </React.Fragment>
         );
    }
}