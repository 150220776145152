import React, { Component } from 'react';
import auth from '../auth/auth';
import Graphics from '../models/graphics';
import Location from '../models/location';
import { Property } from '../models/statics';
import PropertyComponent from './Property/PropertyComponent';
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { LoaderComponent } from './loaderComponent';


class RecentPropertiesComponent extends Component {
    constructor(props) {
        super(props);
        this.properties = [];
    }
    state = { 
        properties : [],
        msgF: "",
        isLoading: true
    }
    componentDidMount(){
        this.searchValue();
    }
     
    searchValue = () => {
        (async () => {
            //alert(this.props.property_id)
            this.setState({isLoading: true});
            //search_string: str = '', negotiable: int = -1, user_type: int = -1, status
            var url = `properties?` + new URLSearchParams({
                status:1
            });

            var result = await auth.GetData(url, 'GET');
            //console.log(result);
            if(result["Error"] != null || result["Error"] !== undefined){
                this.setState({msgF: result.Error});
            }if(result["details"] != null || result["details"] !== undefined){
                //const {properties} = this.state;
                const newProperties = [];
                result["details"].forEach(prop => {
                    var details = prop.details;
                    //console.log(prop.locations);
                    newProperties.push(new Property(
                        details.id, details.title, details.price,
                        details.negotiable, details.description, details.size,
                        details.added_by, details.status, details.time_posted,
                        prop.locations, prop.graphics
                    ))
                })
                this.setState({properties: newProperties});
                var el = document.getElementsByClassName("slick-track");
                for (let i = 0; i < el.length; i++) {
                    const element = el[i];
                    // element.style.width = "100%";
                }
                var el_child = document.getElementsByClassName("slick-slide");
                for (let i = 0; i < el_child.length; i++) {
                    const element = el_child[i];
                    // element.style.width = "350px";
                }
            }
            this.setState({isLoading: false});
        })().then(()=>{this.setState({isLoading: false})});
    }

    render() { 
        if(this.state.isLoading)
            return (
                <div className='bg-dark'>
                    <div className='text-center mt-4 mb-4'>
                        <div className='h3 text-primary'>
                            <span><i className='fa fa-spin fa-spinner'></i></span>
                            <p>Loading..</p>
                            <p className='h3 text-success'>Please wait</p>
                        </div>
                    </div>
                </div>
            )
        if(this.state.msgF !== "") 
            return (
                <React.Fragment>
                    <div className='text-center p-5 m-5'>
                        <h4 className='text-danger'>{this.state.msgF}</h4>
                    </div>
                </React.Fragment>
            );
            const settings = {
              className: "center",
              centerMode: true,
              infinite: true,
              centerPadding: "60px",
              slidesToShow: 3,
              speed: 500,
              rows: 1,
              slidesPerRow: 1,
              slidesToScroll: 1,
              autoplay: true,
              autoplaySpeed: 5000,
              cssEase: "linear"
            };
            const settingsMobile = {
              className: "center",
              centerMode: true,
              infinite: true,
              centerPadding: "60px",
              slidesToShow: 1,
              speed: 500,
              rows: 1,
              slidesPerRow: 1,
              slidesToScroll: 1,
              autoplay: true,
              autoplaySpeed: 5000,
              cssEase: "linear"
            };
        return (
            <div className='overflow-hidden'>
                <div className='d-sm-block d-md-none'>
                    <Slider {...settingsMobile} class="justify-content-center">{
                            this.state.properties.map(property => 
                                    <PropertyComponent value={property} key={property.id}
                                        slickerindex={this.state.properties.indexOf(property)} 
                                    />
                            )
                        }
                    </Slider>
                </div>
                <div className='d-none d-md-block'>
                    <Slider {...settings} class="justify-content-center">{
                            this.state.properties.map(property => 
                                    <PropertyComponent value={property} key={property.id}
                                        slickerindex={this.state.properties.indexOf(property)} 
                                    />
                            )
                        }
                    </Slider>
                </div>
            </div>
        );
    }
}
 
export default RecentPropertiesComponent;