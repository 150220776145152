import React, { Component } from 'react';
import auth from '../../auth/auth';
import { LoaderComponent } from '../loaderComponent';
import PropertyDetailsForm from './PropertyDetailsForm';
import PropertyGraphicsForm from './PropertyGraphicsForm';
import PropertyLocationForm from './PropertyLocationForm';


export class Location{
    constructor(id, state, street, lga, country, zip) {
        this.id= id;
        this.state= state;
        this.street= street;
        this.lga= lga;
        this.country= country;
        this.zip=zip
    }

}

export class Graphic{
    constructor(id, selectedValue,msg,comment) {
        this.id= id;
        this.selectedValue= selectedValue;
        this.msg=msg;
        this.comment=comment;
    }

}

class PropertyCreateOrEditComponent extends Component {
    constructor(props) {
        super(props);
        this.handleDetailsChange = this.handleDetailsChange.bind(this)
    }
    state = { 
        step: 0, property_id: null,
        isLoading: false, isUpLoading: false, userId:'', msgS: '', msgF: '',
        title: '', price: 0, negotiable: false, 
        description:'', size: '',
        locations: [],
        graphics: []
    }

    componentDidMount() {
        this.setState({isLoading: true})
        auth.checkAuthentication().then(() => {
            if(!auth.isAuthenticated){
                localStorage.setItem("navigating_to", window.location.href);
                window.location.href = '/Login';
            }
            this.setState({isLoading: false});
            if(this.props.property_id != null && this.props.property_id !== undefined){            
                this.loadProperty();
            }
        });
    }
    
    loadProperty=()=>{
        (async () => {
            this.setState({isLoading: true})
            var result = await auth.GetData('properties/' + this.props.property_id, 'GET');
            // console.log(result);
            if(result["Error"] != null || result["Error"] !== undefined){
                this.setState({msgF: result.Error});
                this.setState({isLoading: false});
            }if(result["details"] != null || result["details"] !== undefined){
                var details = result['details'];
                this.setState({property_id: details.id})
                this.setState({title: details.title})
                this.setState({price: details.price})
                this.setState({description: details.description})
                this.setState({negotiable: details.negotiable})
                this.setState({size: details.size})
                
                var pLocIndex = 1;
                if(result["locations"] != null || result["locations"] !== undefined){
                    const {locations} = this.state;
                    result["locations"].forEach(loc => {
                        loc.id = pLocIndex++;
                        locations.push(loc)
                    })
                    this.setState({locations});
                }
            }
        })().then(()=>{this.setState({isLoading: false})});
    }

    nextStep = () => {
        if(this.state.step < 2){
            this.setState({step: this.state.step + 1})
        }
    }
    
    prevStep = () => {
        this.setState({graphics: []});
        if(this.state.step > 0){
            this.setState({step: this.state.step - 1})
        }
    }

    handleDetailsChange = (input) => e =>{
        if(input==="description"){
            // console.log(e)
            this.setState({[input]: e});
        }else
            this.setState({[input]: e.target.value});
    }

    handleLocationsChange = (locationId, input) => e =>{
        const {locations} = this.state;
        //const {location} = locations.filter(c=>c.id === locationId);
        locations[locationId - 1][input]= e.target.value;
        this.setState({locations});
        if(input==='country'){
            var result = auth.GetPhoneCode(e.target.value);
            //console.log(result);
            locations[locationId - 1]['zip']= result;
        }
    }
    

    handleGraphicsChange = (graphicId, input) => e =>{
        if(e.target.type === 'textarea' || e.target.type === 'text'){
            const {graphics} = this.state;
            graphics[graphicId - 1][input]= e.target.value;
            this.setState({graphics});
            return;
        }
        var fileName = e.target.value;
        var idxDot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
        if (extFile==="jpg" || extFile==="jpeg" || extFile==="png" || 
            extFile==="bmp" || extFile==="mp4" || extFile === 'mp2t'){
            //TO DO
            const {graphics} = this.state;
            graphics[graphicId - 1][input]= e.target.files[0];
            this.setState({graphics});
        }else{
            alert("Only jpg/jpeg and png files are allowed!");
        }   
    }
    

    handleSubmit = async(e) => {
        e.preventDefault();

        if(!this.isValidated(true))
            return;
        //console.log(this.state.gender)

        const graphics = this.state.graphics;
        var locs = [];
        this.state.locations.forEach((l)=>{
            locs.push({
                "street": l.street,
                "lga": l.lga,
                "state": l.state,
                "country": l.country,
                "zip": l.zip,
                "gmap": ''
            })
        })
        const body = JSON.stringify({
            title: this.state.title, price: this.state.price,
            size: this.state.size, description: this.state.description,
            negotiable: this.state.negotiable, locations: locs
        });

        this.setState({msgF: ""})
        this.setState({msgS: ""})

        this.setState({isUpLoading: true});
        var method = 'PUT'; 
        var url = `properties/` + this.state.property_id;
        if(this.state.property_id == null || this.state.property_id === undefined || 
            this.state.property_id === ''){
            method = 'POST'; url = `properties`;
        }
        //alert(url);
        // console.log(result)
        var result = await auth.PostData(body, url, method);
        if(result["Error"] != null || result["Error"] !== undefined){
            this.setState({msgF: result.Error});
            this.setState({isLoading: false});
        }if(result['message'] === 'success'){
            var property_id = this.state.property_id;
            if(this.state.property_id == null || this.state.property_id === undefined || 
                this.state.property_id === ''){
                property_id = result['id'];
                this.setState({property_id: property_id});
            }
            //alert(url)
            for(var i = 0; i < graphics.length; i++){
                if(graphics[i].selectedValue != null){
                    if(this.check_multifile_logo(graphics[i].selectedValue)){
                        url = `properties/uploads/` + property_id + "?" + new URLSearchParams({
                            property_id: property_id, comment:graphics[i].comment});
                        var fd = new FormData();
                        fd.append("file", graphics[i].selectedValue);
                        fd.append("comment", graphics[i].comment);
                        var resultFile = await auth.PostFileData(fd, url, 'POST');
                        if(resultFile['Filename'] !== null && 
                            resultFile['Filename'] !== undefined){
                            this.setState({msgS: 'Uploaded successfully'});
                            //graphics[i].selectedValue = null;
                            graphics[i].msg = 'Uploaded successfully';
                            this.setState({graphics});
                            this.setState({isUpLoading: false});
                        }else{
                            this.setState({msgF: resultFile['detail']});
                            graphics[i].msg = 'Upload was not successfully';
                            this.setState({graphics});
                            this.setState({isUpLoading: false});
                            return;
                        }
                    }
                }
            }
            if(this.props.property_id != null && this.props.property_id !== undefined && 
                this.props.property_id !== '')
                this.setState({msgS: "Property Created"})
            else
                this.setState({msgS: "Property Updated"})
            this.setState({isUpLoading: false});
            if(property_id != null || property_id !== undefined || 
                property_id !== ''){
                window.location.href = "/Properties/" + property_id;
            }
            return;
        }else{
            this.setState({msgF: result.statusText})
        }
        this.setState({isUpLoading: false});
    }

    
    check_multifile_logo(file) {
        var extension = file.name.substr((file.name.lastIndexOf('.') + 1))
        if (extension === 'jpg' || extension === 'jpeg' || 
            extension === 'gif' || extension === 'png' || extension === 'bmp' ||
            extension === 'mp4' || extension === 'mp2t'
        ) {
            return true;
        } else {
            return false;
        }
    }

    handleDeleteLocation = locationId => {
        const locations = this.state.locations.filter(c=>c.id !== locationId);
        var counter = 1;
        this.setState({locations});
        locations.forEach((loc)=>{
            loc.id = counter++;
        })
    }

    handleDeleteGraphics = graphicId => {
        const graphics = this.state.graphics.filter(c=>c.id !== graphicId);
        var counter = 1;
        this.setState({graphics});
        graphics.forEach((loc)=>{
            loc.id = counter++;
        })
    }

    handleIncreamentLocation = () => {
        const locations = [...this.state.locations];
        locations.push(new Location(
            locations.length + 1, '', '', 
            '', 'Nigeria', +234
        ))
        this.setState({locations});
    }

    handleIncreamentGraphics = () => {
        const graphics = [...this.state.graphics];
        graphics.push(new Graphic(
            graphics.length + 1, null,''
        ))
        this.setState({graphics});
    }
    
    isValidated=(alertT=false)=>{
        if(this.state.description !== "" && this.state.title !== "" && 
            this.state.size !== "" && this.state.locations.length > 0){
                return true;
            }
        if(alertT)
            alert("Property details and at least one location is required!")
        return false;
    }

    render() { 
        if(this.state.isLoading) 
            return (
                <LoaderComponent />
            );
        const {title, price, negotiable, description, size} = this.state;
        const PropertyDetails = {title, price, negotiable, description, size}
        
        return ( 
            <React.Fragment>
                <div className='card-header pt-4'>
                    {
                        this.props.property_id == null && <h5 className='p-2 text-primary'>New Property Form</h5>
                    }
                    {
                        this.props.property_id != null && <h5 className='p-2 text-primary'>Edit Property({this.state.title})</h5>
                    }
                    <hr />
                    <p>Kindly provide the details of the property including images and/or videos if available.</p>
                </div>
                <form>
                    <div className=''>
                        <div className='col-md-12'>
                            <div className='shadow m-2 p-2'>
                                    <div className="card-body">
                                        <div className="form-group border-bottom">                
                                            <p className='text-success'>{this.state.msgS}</p>
                                            <p className='text-danger'>{this.state.msgF}</p>
                                        </div>
                                        {
                                            this.state.step === 0 &&
                                            <React.Fragment>                                                
                                                <h6 className='card-header p-2'>Property Info</h6>
                                                <PropertyDetailsForm 
                                                    nextStep={this.nextStep}
                                                    handleChange={this.handleDetailsChange}
                                                    PropertyDetails={PropertyDetails}
                                                />
                                            </React.Fragment>
                                        }
                                        {
                                            this.state.step === 1 &&
                                            <React.Fragment>
                                                <h6 className='card-header p-2'>Property Locations</h6>
                                                <div>
                                                    <p>
                                                        Properties with similar design and mesurement might be 
                                                        available in multiple <quote>locations.</quote>
                                                        These locations help the user when searching for properties.
                                                        Add the locations where this property is available.
                                                    </p>
                                                </div>
                                                <div className="row">
                                                {
                                                    this.state.locations.map(PropertyLocation => <div className="col-md-6">
                                                        <PropertyLocationForm
                                                            key={PropertyLocation.id}
                                                            nextStep={this.nextStep}
                                                            onDelete={this.handleDeleteLocation}
                                                            PropertyLocation={PropertyLocation}
                                                            handleChange={this.handleLocationsChange}
                                                        />
                                                    </div>) 
                                                }
                                                </div>
                                                <div className="col-md-12">
                                                    <button type='button'
                                                        className='mt-2 btn btn-primary float-start'
                                                        onClick={this.handleIncreamentLocation}
                                                    >New Location</button>
                                                </div>
                                            </React.Fragment>
                                        }
                                        {
                                            this.state.step === 2 &&
                                            <React.Fragment>
                                                <h6 className='card-header p-2'>Property Graphics</h6>
                                                <div>
                                                    <p>
                                                        High quality Images/videos are proven to attract more buyers.
                                                        Add images and videos of this property.
                                                    </p>
                                                </div>
                                                <div className="row p-2">
                                                    {
                                                        this.state.graphics.map(PropertyGraphic => 
                                                            <PropertyGraphicsForm
                                                                key={PropertyGraphic.id}
                                                                nextStep={this.nextStep}
                                                                onDelete={this.handleDeleteGraphics}
                                                                PropertyGraphic={PropertyGraphic}
                                                                handleChange={this.handleGraphicsChange}
                                                            />)
                                                    }
                                                </div>
                                                <div className="col-md-12">
                                                    <button type='button'
                                                        className='btn btn-primary float-start'
                                                        onClick={this.handleIncreamentGraphics}
                                                    >New Graphic</button>
                                                </div>
                                            </React.Fragment>
                                        }
                                    </div>
                            </div>
                        </div>
                        <div className='text-center mt-4 border-top pt-2 bg-light m-0 p-2 card-footer'>
                            <div className='container mb-2 col-md-12'>
                                { 
                                    this.state.step > 0 && <button type='button'
                                        className='btn btn-primary float-start'
                                        onClick={this.prevStep}
                                    >Previous</button>
                                }
                                { 
                                    this.state.step < 2 && <button type='button'
                                        className='btn btn-primary float-end'
                                        onClick={this.nextStep}
                                    >Next</button>
                                }
                            </div>
                            <div className='col-md-12'>
                                { 
                                    !this.state.isUpLoading && this.state.step === 2 && <button 
                                        className='btn btn-success col-md-4'
                                        onClick={this.handleSubmit}
                                    >
                                        {
                                            this.state.property_id == null && <span>Add New Property</span>
                                        }
                                        {
                                            this.state.property_id != null && <span>Update Property</span>
                                        }
                                    </button>
                                }
                                { 
                                    this.state.isUpLoading && <button disabled
                                        className='btn btn-primary col-md-4'>
                                        <i className='fa fa-spinner fa-spin me-2'></i>
                                        Updating Property...</button>
                                }
                            </div>
                            <div className="form-group">
                                <p className='text-success'>{this.state.msgS}</p>
                                <p className='text-danger'>{this.state.msgF}</p>
                            </div>
                            <a href='/Properties' className=''>Back To Properties</a>
                        </div>
                        <div className='col-md-5 mt-4 mb-4 p-2'>
                            {/* <img src='' /> */}
                        </div>
                    </div>
                </form>
            </React.Fragment>
         );
    }
}
 
export default PropertyCreateOrEditComponent;