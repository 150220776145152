import React from 'react';
import auth from '../auth/auth';

export const LogoutLink = props => {

  return (
    <a href='#Logout'
      onClick={() =>
        auth.logout(() =>{
          window.location.href = '/'
        })
      } >
        <i className="uil uil-signout"></i>
        <span className="link-name">Log Out</span>
    </a>
  );
};