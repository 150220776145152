import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import auth from '../../auth/auth';
import { LoaderComponent } from '../loaderComponent';


export class proposal{
    constructor(
        id = 0, name = "", email = "", phone = "", 
        country = "", _state = "", city = "", zip = 0,
        priceStart = 0, priceStop = 0, description = "", size = "", 
        status = 0,
        time_posted
    ){
        this.id = id;
        this.name = name;
        this.email = email;
        this.phone = phone;
        this.country = country;
        this._state = _state;
        this.city = city;
        this.zip = zip;
        this.priceStart = priceStart;
        this.priceStop = priceStop;
        this.description = description;
        this.size = size;
        this.status = status;
        this.time_posted = time_posted;
        this.isUpLoading = false
        this.isPendingChange = false
        this.isChangeSuccessfull = false
        this.msg = ''
        this.StatusClass = 'form-select'
    }
}


export default class ProposalsComponent extends Component {
    
    state = { 
        pageNumber: 0, countPerPage: 15, sort_by: 'name', sort_by_order: 1,
        SearchValue: '',negotiable: -1, user_type: -1, status: -1,
        step: 0, proposal_id: this.props.proposal_id,
        isLoading: false, isUpLoading: false, userId:'', msgS: '', msgF: '',
        proposals: [],
    }

    componentDidMount() {
        auth.checkAuthentication().then(() => {
            if(auth.userRole !== 'admin'){
                localStorage.setItem("navigating_to", window.location.href);
                window.location.href = '/Login';
            }
            this.searchValue();
        });
    }
    
    pagesVisited = () =>{
        return this.state.pageNumber * this.state.countPerPage;
    }
    pageCount = () =>{
        return Math.ceil(this.state.proposals.length / this.state.countPerPage);
    }

    changePage=({selected})=>{
        this.setState({pageNumber: selected});
    }

    displayItems = () =>{
        return this.state.proposals.slice(
            this.pagesVisited(), this.pagesVisited() + this.state.countPerPage
        ).map(proposal => {return(<div className="">
                <h6 className='card-header'>
                    {proposal.name}
                    <a href='#Delete' type='button' 
                        onClick={()=>{
                            this.deleteproposal(proposal.id, proposal.name)
                        }}
                        className='btn-sm btn-danger float-end'
                    ><span className='fa fa-trash'></span></a>
                    <a href={'Proposals/Edit/' + proposal.id}
                        className='btn-sm btn-primary me-2 float-end'
                        hidden
                    ><span className='fa fa-edit'></span></a>
                    <a href={'Proposals/' + proposal.id}
                        className='btn-sm btn-success me-2 float-end'
                    ><span className='fa fa-eye'></span></a>
                </h6>
                <div className="lst shadow p-2">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="row">
                                <div className="form-group col-6">
                                    <label className='control-label'>PriceStart: </label>
                                    <p className='description'>{auth.formatter.format(proposal.priceStart)}</p>
                                </div>                      
                                <div className="form-group col-6">
                                    <label className='control-label'>PriceStop: </label>
                                    <p className='description'>{auth.formatter.format(proposal.priceStop)}</p>
                                </div>                      
                            </div>                      
                        </div>                      
                        <div className="form-group col-md-3">
                            <label className='control-label'>Size: </label>
                            <p className='description'>{proposal.size}</p>
                        </div>
                        <div className="form-group col-md-3">
                            <label className='control-label me-2'>
                                Status: 
                            </label>
                            {
                                auth.userRole === 'admin' &&
                                <div className="input-group mb-3">
                                    <select className={proposal.StatusClass}
                                        defaultValue={proposal.status}
                                        onChange={e => {this.setproposalStatus(proposal.id, e)}}
                                    >
                                        {
                                            auth.proposal_statuses.map(status => 
                                                <option value={auth.proposal_statuses.indexOf(status)}>{status}</option>
                                            )
                                        }
                                    </select>
                                    <span className="input-group-text text-primary" title='Save Changes'
                                        onClick={(e) => {this.changeproposalStatus(proposal.id, e.target)}}
                                    >
                                        <i className='fa fa-save'></i>
                                    </span>
                                </div>
                            }
                            {
                                auth.userRole !== 'admin' &&
                                <div className="input-group mb-3">
                                    <input disabled className={proposal.StatusClass}
                                        defaultValue={auth.proposal_statuses[proposal.status]} />
                                </div>
                            }
                            {
                                proposal.msg !== "" && proposal.isChangeSuccessfull && <div className='p-2 shadow border-secondary alert alert-primary d-flex align-items-center alert-dismissible fade show' role="alert">
                                    <button type="button" className="p-2 btn-sm btn-close" 
                                        onClick={() => this.clearproposalMsg(proposal.id)}
                                    // <button type="button" className="p-2 btn-sm btn-close" data-bs-dismiss="alert" 
                                        aria-label="Close" title='Close'></button>
                                    {proposal.msg}
                                </div>
                            }
                            {
                                proposal.msg !== "" && !proposal.isChangeSuccessfull && <div className='p-2 shadow border-secondary alert alert-danger d-flex align-items-center alert-dismissible fade show' role="alert">
                                    <button type="button" className="p-2 btn-sm btn-close" 
                                        onClick={() => this.clearproposalMsg(proposal.id)}
                                    // <button type="button" className="p-2 btn-sm btn-close" data-bs-dismiss="alert" 
                                        aria-label="Close" title='Close'></button>
                                    {proposal.msg}
                                </div>
                            }
                        </div>                     
                        <div className="form-group col-md-3">
                            <label className='control-label'>Address: </label>
                            <p className='description'>
                                {
                                    proposal.city + ", " + proposal.state + ", " + 
                                    proposal.country + "."
                                }
                            </p>
                        </div>
                    </div>
                </div>
            </div>)}
        )
    }

    searchValue = () => {
        (async () => {
            //alert(this.props.proposal_id)
            this.setState({isLoading: true});
            this.setState({proposal_id: this.props.proposal_id});
            this.setState({user_id: this.props.user_id});

            //search_string: str = '', negotiable: int = -1, user_type: int = -1, status
            var url = `proposals?` + new URLSearchParams({
                search_string: this.state.SearchValue, status:this.state.status
            });

            var result = await auth.GetData(url, 'GET');
            //console.log(result);
            if(result["Error"] != null || result["Error"] !== undefined){
                this.setState({msgF: result.Error});
                this.setState({isLoading: false});
            }if(result["details"] == null){
                this.setState({msgF: "No result found"})
            }else if(result["details"] != null || result["details"] !== undefined){
                //const {proposals} = this.state;
                const newproposals = [];
                result["details"].forEach(prop => {
                    var details = prop.details;
                    //console.log(prop.locations);
                    if(auth.userRole === 'admin'){ //} || auth.userId === details.added_by){
                        newproposals.push(new proposal(
                            details.id, details.name, 
                            details.email, details.phone, details.country,
                            details.state, details.city, details.zip,
                            details.priceStart, details.priceStop,
                            details.description, details.size,
                            details.status, details.time_posted
                        ))
                    }
                })
                this.setState({proposals: newproposals});
                if(newproposals.length === 0){
                    this.setState({msgF: "No result found"})
                }
            }
        })().then(()=>{this.setState({isLoading: false})});
    }

    deleteproposal(proposal_id, proposal_name){
        (async () => {
            this.setState({msgF: ""})
            this.setState({msgS: ""})
            var answer = window.confirm("Delete " + proposal_name + "?");
            if(answer){
                this.setState({isUpLoading: true});
                var result = await auth.PostData(null, `proposals/` + proposal_id, 'DELETE');
                //console.log(result);
                if(result["Error"] != null || result["Error"] !== undefined){
                    this.setState({msgF: result.Error});
                    this.setState({isLoading: false});
                }if(result['message'] === 'success'){
                    this.setState({msgS: "proposal deleted"})
                    this.setState({isUpLoading: false});
                    const proposals = this.state.proposals.filter(c=>c.id !== proposal_id);
                    this.setState({proposals});
                }else{
                    this.setState({msgF: result.statusText})
                }
                this.setState({isUpLoading: false});
            }
        })().then(()=>{this.setState({isLoading: false})});
    }

    changeproposalNegotiable(proposal_id){
        (async () => {
            this.setState({msgF: ""})
            this.setState({msgS: ""})
            this.setState({isUpLoading: true});
            var result = await auth.PostData(null, `proposals/change_negotiable/` + proposal_id, 'POST');
            //console.log(result);
            if(result["Error"] != null || result["Error"] !== undefined){
                this.setState({msgF: result.Error});
                this.setState({isLoading: false});
            }if(result['message'] === 'success'){
                this.setState({msgS: "proposal Negotiation Changed"})
                this.setState({isUpLoading: false});
                const proposals = this.state.proposals;
                this.state.proposals.forEach(prop => {
                    if(prop.id===proposal_id){
                        prop.negotiable = !prop.negotiable;
                    }
                })
                this.setState({proposals});
            }else{
                this.setState({msgF: result.statusText})
            }
            this.setState({isUpLoading: false});
        })().then(()=>{this.setState({isLoading: false})});
    }

    clearproposalMsg(proposal_id){
        const {proposals} = this.state;
        proposals.forEach(prop => {
            if(prop.id===proposal_id){
                prop.msg = '';
                return
            }
        })
        this.setState({proposals})
    }

    setproposalStatus(proposal_id, e){
        const {proposals} = this.state;
        proposals.forEach(prop => {
            if(prop.id===proposal_id){
                prop.status = e.target.value;
                prop.isPendingChange = true;
                prop.StatusClass = this.getStatusClass(prop);
                return
            }
        })
        this.setState({proposals})
        //console.log(this.state.proposals);
        //e.target.className = 'form-control border-primary';
    }

    getStatusClass = (prop) => {
        var className = 'form-select'
        if(prop.isPendingChange) className = 'form-select border-primary';
        if(prop.isUpLoading) className = 'form-select border-warning';
        if(prop.isChangeSuccessfull) className = 'form-select border-success';
        return className;
    }

    changeproposalStatus(proposal_id, e){
        (async () => {
            this.setState({msgF: ""})
            this.setState({msgS: ""})
            this.setState({isUpLoading: true});
            var status = 0;
            const {proposals} = this.state;
            var p = null;
    
            proposals.forEach(prop => {
                if(prop.id===proposal_id){
                    prop.isPendingChange = false;
                    prop.isUpLoading = true;
                    status = prop.status;
                    prop.StatusClass = this.getStatusClass(prop)
                    prop.msg = '';
                    p = prop;
                    return;
                }
            })

            this.setState({proposals})
            e.disabled = true;
            var classname = e.className;
            e.className = 'fa fa-spin fa-spinner';
            var result = await auth.PostData(JSON.stringify({
                    "proposal": "status",
                    "value": status
                }), 
                `proposals/change_status/` + proposal_id, 'POST');
            //console.log(result);
            if(result["Error"] != null || result["Error"] !== undefined){
                this.setState({msgF: result.Error});
                this.setState({isLoading: false});
            }if(result['message'] === 'success'){
                this.setState({msgS: "proposal Status Changed"})
                p.msg = 'Status Changed';
                p.isPendingChange = false;
                p.isChangeSuccessfull = true;
            }else{
                this.setState({msgF: result.statusText})
                p.msg = result.statusText;
            }
            e.className = classname;
            this.setState({isUpLoading: false});
            p.isUpLoading = false;
            p.StatusClass = this.getStatusClass(p);
            this.setState({proposals})
            e.disabled = false;
        })().then(()=>{this.setState({isLoading: false})});
    }

    setSearchValue(val){
        this.setState({SearchValue: val});
    }

    setSortBy(val){
        this.setState({sort_by: val});
        const {proposals} = this.state;
        proposals.sort(auth.dynamicSort(val, this.state.sort_by_order));
    }

    setSortByOrder(val){
        this.setState({sort_by_order: val});
        const {proposals} = this.state;
        proposals.sort(auth.dynamicSort(this.state.sort_by, val));
    }

    render() { 
        if(this.state.isLoading) 
            return (
                <LoaderComponent />
            );
            
        return ( 
            <React.Fragment>
                <div className=''>
                    <div className='col-md-12 p-4'>
                        <a href='/Proposal' className=''>Add New Proposal</a>
                        <div className='shadow m-2 p-2'>
                                <div className="card-body">
                                    <div className="form-group">
                                        <p className='text-success'>{this.state.msgS}</p>
                                        <p className='text-danger'>{this.state.msgF}</p>
                                    </div>
                                    <div className='card-header p-2 mt-2'>
                                        <h5 className='text-primary'>Available proposals</h5>
                                        <p>These proposals are available in the database.</p>
                                    </div>
                                    <div className="text-start mt-2">
                                        <h6 className='control-label'>Filter:</h6>
                                        <div className="row">
                                            <div className="form-group col-md-3">
                                                <label className='control-label'>Status</label>
                                                <select className='form-select'
                                                    onChange={(e) => {this.setState({status: e.target.value})}}
                                                    value={this.state.status}>
                                                    <option value="-1" selected>All</option>
                                                    {
                                                        auth.proposal_statuses.map(status => 
                                                            <option value={auth.proposal_statuses.indexOf(status)}>{status}</option>
                                                        )
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="form-group col-md-4 float-start mb-2">
                                                <div className="input-group">
                                                    <input type="text" className='form-control' 
                                                        placeholder='Search by title, size, street, City, Zip, Country'
                                                        value={this.state.SearchValue}
                                                        onChange={(e)=>{this.setSearchValue(e.target.value)}}
                                                        onKeyDown={(e)=>{
                                                            if (e.key === "Enter") {
                                                                // Cancel the default action, if needed
                                                                e.preventDefault();
                                                                // Trigger the button element with a click
                                                                this.searchValue()
                                                              }}}
                                                    />
                                                    <span className="input-group-text btn btn-primary"
                                                        onClick={(e)=>{this.searchValue()}}
                                                        ><i className=' fa fa-search'></i></span>
                                                </div>
                                            </div>
                                            <div className="col-md-8 text-end pe-4">
                                                <label className='control-label mw-150 pe-2'>Sort By:</label>
                                                <select className='mw-150' 
                                                    onChange={(e) => this.setSortBy(e.target.value)}
                                                >
                                                    <option value="name" selected>Name</option>
                                                    <option value="priceStart">Price Start</option>
                                                    <option value="priceStop">Price Stop</option>
                                                    <option value="size">Size</option>
                                                    <option value="country">Country</option>
                                                    <option value="state">State</option>
                                                    <option value="city">City</option>
                                                    <option value="status">Status</option>
                                                    <option value="time_posted">Creation Date</option>
                                                </select>
                                                <select className='mw-150'
                                                    onChange={(e) => this.setSortByOrder(e.target.value)}>
                                                    <option value="1" selected>Asc</option>
                                                    <option value="-1">Desc</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        {this.displayItems()}
                                        <hr />
                                        <ReactPaginate 
                                            previousLabel={'Previous'}
                                            nextLabel={'Next'}
                                            pageCount={this.pageCount()}
                                            onPageChange={this.changePage}
                                            previousLinkClassName={'btn btn-sm btn-primary'}
                                            nextLinkClassName={'btn btn-sm btn-primary'}
                                            activeClassName={'btn btn-sm btn-primary'}
                                            disabledClassName={'btn btn-sm disabled'}
                                            
                                        />
                                    </div>
                                </div>
                        </div>
                    </div>
                    <div className="text-start">
                        <a href='/Proposal' className=''>Add New Proposal</a>
                    </div>
                    <div className='col-md-5 mt-4 mb-4 p-2'>
                        {/* <img src='' /> */}
                    </div>
                </div>
            </React.Fragment>
         );
    }
}