
export class Property{
    constructor(id, title, price, negotiable, description, size, 
        added_by, status, time_posted, locations, graphics) {
        this.id= id;
        this.title= title;
        this.price= price;
        this.negotiable= negotiable;
        this.description= description;
        this.size=size
        this.added_by=added_by
        this.status=status
        this.time_posted=time_posted
        this.locations=locations
        this.graphics=graphics
        this.isUpLoading = false
        this.isPendingChange = false
        this.isChangeSuccessfull = false
        this.msg = ''
        this.StatusClass = 'form-select'
    }

}

export class Location{
    constructor(id, state, street, lga, country, zip) {
        this.id= id;
        this.state= state;
        this.street= street;
        this.lga= lga;
        this.country= country;
        this.zip=zip
    }

}

export class Graphic{
    constructor(id, selectedValue,msg,comment) {
        this.id= id;
        this.selectedValue= selectedValue;
        this.msg=msg;
        this.comment=comment;
    }

}
