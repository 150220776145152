export const LoaderComponent=(props) => {
    return (
        <section className="dashboard ps-0 pe-0 mt-4 mb-4">
            <div className="dash-content">
                <div className='text-center mt-4 mb-4'>
                    <div className='h3 text-primary'>
                        <span><i className='fa fa-spin fa-spinner'></i></span>
                        <p>Loading..</p>
                        <p className='h3 text-success'>Please wait</p>
                    </div>
                </div>
            </div>
    </section>
    );
}