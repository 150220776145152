import { Outlet } from "react-router-dom";
//import SidebarComponent from "./components/SidenavComponent";
import { SidebarComponent } from "./components/SidenavComponent";
import { TopbarComponent } from "./components/TopbarComponent";
import { FooterComponent } from "./components/FooterComponent";
import { useEffect } from "react";

export function Layout() {
    useEffect(()=>{
        window.onload = () => {
            var loaderEl = document.getElementById("loaderDiv");
            //loaderEl.style.display = 'none';
            loaderEl.classList = 'hidden';
        }
        
        window.addEventListener("scroll", function() {
            var onAppear = [];
            onAppear = [].map.call(document.querySelectorAll(".animated-item"), function(item ) {
                return item;
            });
            onAppear.forEach(function(elem) {
                var vwTop = window.pageYOffset;
                var vwBottom = (window.pageYOffset + window.innerHeight);
                var elemTop = elem.offsetTop;
                var elemHeight = elem.offsetHeight;

                if (vwBottom > elemTop && ((vwTop - elemHeight) < elemTop)) {
                    elem.classList.add(elem.getAttribute('animation-type'));
                } else {
                    elem.classList.remove(elem.getAttribute('animation-type'));
                }
            });
        }, false);
    })
    return (
        <div>
            <div id="loaderDiv" className="d-flex loader justify-content-center">
                <div className="loading text-center">
                    {/* <span className="text-primary fa spinner fa-spin fa-4x"></span> */}
                </div>
            </div>
            <SidebarComponent />
            <TopbarComponent />
            <section className="bg-light dashboard ps-0 pe-0 pb-0">
                <div className="dash-content mt-2">
                    <div className=''>
                        <Outlet />
                    </div>
                </div>
            </section>
            <FooterComponent />
        </div>
    );
  }