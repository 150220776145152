import React, { Component } from "react";
import Slider from "react-slick";
import property1 from '../images/Anakings.jpg'
import property2 from '../images/Anakingss.jpg'
import property3 from '../images/Anakingsss.jpg'
import property4 from '../images/Anakingssss.jpg'

export default class MultipleRows extends Component {
  render() {
    const settings = {
      className: "center",
      centerMode: true,
      infinite: true,
      centerPadding: "60px",
      slidesToShow: 1,
      speed: 500,
      rows: 1,
      slidesPerRow: 3
    };
    return (
      <div className="text-light">
        <h2>Multiple Rows</h2>
        <Slider {...settings} style={{with: '100%'}}>
          <div className="card bg-secondary">
            <img src={property1} alt="as" className="img-thumbnail" style={{minWidth: '33%'}} />
          </div>
          <div className="card bg-secondary">
            <img src={property2} alt="as" className="img-thumbnail" style={{minWidth: '33%'}} />
          </div>
          <div className="card bg-secondary">
            <img src={property3} alt="as" className="img-thumbnail" style={{minWidth: '33%'}} />
          </div>
          <div className="card bg-secondary">
            <img src={property4} alt="as" className="img-thumbnail" style={{minWidth: '33%'}} />
          </div>
        </Slider>
      </div>
    );
  }
}
