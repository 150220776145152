import React, { Component } from 'react';
import auth from '../../auth/auth';
import { LoaderComponent } from '../loaderComponent';

export default class UserComponent extends Component {
    constructor(props) {
        super(props);
    }
    state = { 
        step: 0, user_id: this.props.user_id, 
        isLoading: false, isUpLoading: false, userId:'', msgS: '', msgF: '',
        user: [],
        locations: [],
        graphics: [],
        properties: []
    }

    componentDidMount() {
        auth.checkAuthentication().then(() => {
            if(auth.userRole !== 'admin'){
                localStorage.setItem("navigating_to", window.location.href);
                window.location.href = '/Login';
            }
            (async () => {
                this.setState({isLoading: true});
                this.setState({user_id: this.props.user_id});
                if(this.props.user_id == null || this.props.user_id === undefined) return
    
                var result = await auth.GetData('users/' + this.props.user_id, 'GET');
                console.log(result["details"]);
                if(result["Error"] != null || result["Error"] !== undefined){
                    this.setState({msgF: result.Error});
                    this.setState({isLoading: false});
                }if(result["details"] != null || result["details"] !== undefined){
                    this.setState({user: result.details.details})
                    const user = this.state.user;
                    user.msg = '';
                    this.setState({user});
                }
            })().then(()=>{this.setState({isLoading: false})});
        });
    }
    
    deleteUser(user_id, user_title){
        (async () => {
            this.setState({msgF: ""})
            this.setState({msgS: ""})
            var answer = window.confirm("Delete " + user_title + "?");
            if(answer){
                this.setState({isUpLoading: true});
                var result = await auth.PostData(null, `Users/` + user_id, 'DELETE');
                //console.log(result);
                if(result["Error"] != null || result["Error"] !== undefined){
                    this.setState({msgF: result.Error});
                    this.setState({isLoading: false});
                }if(result['message'] === 'success'){
                    this.setState({msgS: "User deleted"})
                    this.setState({isUpLoading: false});
                    const users = this.state.users.filter(c=>c.id !== user_id);
                    this.setState({users});
                }else{
                    this.setState({msgF: result.statusText})
                }
                this.setState({isUpLoading: false});
            }
        })().then(()=>{this.setState({isLoading: false})});
    }

    change_status(user_id){
        (async () => {
            this.setState({msgF: ""})
            this.setState({msgS: ""})
            this.setState({isUpLoading: true});
            var result = await auth.PostData(null, `users/change_status/` + user_id, 'POST');
            //console.log(result);
            if(result["Error"] != null || result["Error"] !== undefined){
                this.setState({msgF: result.Error});
                this.setState({isLoading: false});
            }if(result['message'] === 'success'){
                this.setState({msgS: "User status Changed"})
                this.setState({isUpLoading: false});
                const users = this.state.users;
                users.forEach(prop => {
                    if(prop.id===user_id){
                        prop.status = !prop.status;
                        return;
                    }
                })
                this.setState({users});
            }else{
                this.setState({msgF: result.statusText})
            }
            this.setState({isUpLoading: false});
        })().then(()=>{this.setState({isLoading: false})});
    }

    handleSelect = (selectedIndex, e) => {
        this.setState({carouselIndex: selectedIndex});
    };


    render() { 
        if(this.state.isLoading) 
            return (
                <LoaderComponent />
            );

        const userDetails = this.state.user;
        // const {locations, graphics} = this.state;
        
        return ( 
            <React.Fragment>
                <script>0</script>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='shadow m-2 p-2'>
                            <div className="card-body">
                                <div className='text-center card-header p-2 mt-2'>
                                    <h4 className=''>
                                        <u>User's Information</u>
                                        { auth.AdminUsername !== userDetails.username && <a href='#Delete' type='button' 
                                            onClick={()=>{
                                                this.deleteUser(this.state.user_id, userDetails.title)
                                            }}
                                            className='btn-sm btn-danger float-end'
                                        ><span className='fa fa-trash'></span></a>}
                                    </h4>
                                    <p>Below are details of this user.</p>
                                </div>
                                <div className="form-group">
                                    <p className='text-success'>{this.state.msgS}</p>
                                    <p className='text-danger'>{this.state.msgF}</p>
                                </div>
                                <div className='d-flex justify-content-center'>
                                    <div className="col-md-5 p-2 card shadow ms-2 me-2">
                                        <div className='text-center border-bottom bg-dark p-2 rounded'>
                                            {
                                                (userDetails.logo!=='' && userDetails.logo!=null) &&
                                                <img src={auth.baseUrl() + 'users/profile_images/' + userDetails.id} 
                                                    alt="profile avartar" 
                                                    className='profile-img img rounded img-thumbnail'
                                                    />
                                            }
                                            {
                                                (userDetails.logo==='' || userDetails.logo==null) &&
                                                <div><span className='far fa-user fa-4x bg-light rounded img-thumbnail'></span></div>
                                            }
                                            <h5 className='text-light'>{userDetails.email}</h5>
                                        </div>
                                        <div className="card-body">
                                            <div className="form-group">
                                                <label className=' control-label'>Name:</label>
                                                <div className="input-group">
                                                    <span className="input-group-text" id="basic-addon1"><i className='far fa-user'></i></span>
                                                    <input type="text" disabled className='disabled form-control' 
                                                        value={userDetails.first_name + ' ' + userDetails.middle_name + ' ' + userDetails.last_name} />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className=' control-label'>Phone:</label>
                                                <div className="input-group">
                                                    <span className="input-group-text" id="basic-addon1"><i className='fa fa-phone'></i></span>
                                                    <input type="text" disabled className='disabled form-control' value={userDetails.phone} />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className=' control-label'>Address:</label>
                                                <div className="input-group">
                                                    <span className="input-group-text" id="basic-addon1"><i className='fa fa-globe'></i></span>
                                                    <input type="text" disabled className='disabled form-control' value={userDetails.address} />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className=' control-label'>LGA:</label>
                                                <div className="input-group">
                                                    <span hidden className="input-group-text" id="basic-addon1"><i className='fa fa-phone'></i></span>
                                                    <input type="text" disabled className='disabled form-control' value={userDetails.lga} />
                                                </div>
                                            </div>
                                            <div className="row">                        
                                                <div className="form-group col-md-6">
                                                    <label className='control-label'>City: </label>
                                                    <input disabled type='text' className='form-control' value={userDetails.city} 
                                                        // onChange={}
                                                    />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label className='control-label'>State: </label>
                                                    <input disabled type='text' className='form-control' value={userDetails.state} 
                                                        // onChange={}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">      
                                                <div className="form-group col-md-6">
                                                    <label className='control-label'>Country: </label>
                                                    <input disabled type='text' className='form-control' value={userDetails.country} 
                                                        // onChange={}
                                                    />
                                                </div>                  
                                                <div className="form-group col-md-6">
                                                    <label className='control-label'>Zip: </label>
                                                    <input disabled type='text' className='form-control' value={userDetails.zip} 
                                                        // onChange={}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className='control-label me-2'>Status: </label>
                                                <div className='description row'>
                                                    <div className="col-1">
                                                        <input type='checkbox' className='form-control-sm' 
                                                            defaultChecked={userDetails.status}
                                                            onChange={() => {this.change_status(userDetails.id)}}
                                                        />
                                                    </div>
                                                    <div className="col-9">
                                                        {userDetails.status && 'User is Active'}
                                                        {!userDetails.status && 'User is Disabled'}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <a href="#"><i className="fa fa-dribbble"></i></a>
                                    <a href="#"><i className="fa fa-twitter"></i></a>
                                    <a href="#"><i className="fa fa-linkedin"></i></a>
                                    <a href="#"><i className="fa fa-facebook"></i></a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='text-center mt-4 border-top pt-2 bg-light m-0 p-2 card-footer'>
                        <div className='col-md-12'>
                            { 
                                this.state.isUpLoading && <button disabled
                                    className='btn btn-primary col-md-4'>
                                    <i className='fa fa-spinner fa-spin me-2'></i>
                                    Updating Property...</button>
                            }
                        </div>
                        <div className="form-group">
                            <p className='text-success'>{this.state.msgS}</p>
                            <p className='text-danger'>{this.state.msgF}</p>
                        </div>
                        <a href='/Users' className=''>Back To Users</a>
                    </div>
                    <div className='col-md-5 mt-4 mb-4 p-2'>
                        {/* <img src='' /> */}
                    </div>
                </div>
            </React.Fragment>
         );
    }
}