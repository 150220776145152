import React from 'react';
import auth from '../auth/auth'

export const LoginLink = () => {

  if(auth.isAuthenticated()) return (
    <a href='/Profile'>
      <i className="fa fa-user"></i>
      <span className="link-name">Profile</span>
    </a>
  );

  return (
    <li>
      <a href='#Login'  
        data-bs-toggle="modal" data-bs-target="#exampleModal">
        <i className="fa fa-user"></i>
        <span className="link-name">Login</span>
      </a>
    </li>
  );
};

export default LoginLink;