import React, {useEffect, useState } from 'react';
import auth from '../../auth/auth';
import { LoaderComponent } from '../loaderComponent';

const ProfileComponent = () => {
    const [isLoading, setisLoading] = useState(false)
    const [userData, setUserData] = useState({
        username: '',
        first_name:'', last_name:'', middle_name:'', gender:'', email:'', phone:'',
        address:'', state:'', city:'', country:'', zip:'', logo: null, role:'', lga:''
      });
      
    const [profileUrl, setprofileUrl] = useState('');
    const [msgF, setmsgF] = useState('');
    

    useEffect(() => {
        auth.checkAuthentication().then(() => {
            if(!auth.authenticated){
                localStorage.setItem("navigating_to", window.location.href);
                window.location.href = '/Login';
            }else{
                setisLoading(true);
                (async () => {
                    var result = await auth.GetData('user/profile', 'GET');
                    //console.log(result);
                    if(result["Error"] != null || result["Error"] !== undefined){
                        setmsgF(result.Error);
                        setisLoading(false);
                    }else if(result["email"] != null || result["email"] !== undefined){
                        var imgSrc = null
                        if(result.logo !== ''){
                            var resultLogo = await auth.GetData('users/profile_images/' + result.id, 'GET', false);
                            //console.log(resultLogo);
                            if(resultLogo != null && resultLogo !== ''){
                                var blob = await resultLogo.blob();
                                imgSrc = URL.createObjectURL(blob);
                            }
                        }
                        setUserData({
                            username: result.username,
                            first_name: result.first_name, last_name: result.last_name,
                            middle_name: result.middle_name, gender: result.gender,
                            email: result.email, lga: result.lga,
                            phone: result.phone, address: result.address,
                            state: result.state, city: result.city, 
                            country: result.country,zip: result.zip,
                            role: result.role, logo: imgSrc
                        });
                    }
                })().then(()=>{setisLoading(false)});
            }
        });
    },[]);

    
    const {
        username,
        first_name, last_name, middle_name, gender, email, phone,
        address, lga, state, city, country, zip, logo, role
    } = userData;

    if(isLoading) return (
        <LoaderComponent />
    );  
    
    if(msgF !== "") 
        return (
            <React.Fragment>
                <div className='card-header text-center p-5 m-5'>
                    <h4 className='text-danger'>{msgF}</h4>
                </div>
            </React.Fragment>
        );

    return( 
        <section className="dashboard ps-0 pe-0 mt-4 mb-4">
            <div className="dash-content">
                <div className="col-md-4 p-2 card shadow ms-2 me-2">
                    <h4 className='text-center'>
                        <u>User's Profile</u>
                    </h4>
                    <div className='justify-content-center text-center border-bottom bg-dark p-2 rounded'>
                        {
                            (logo!=='' && logo!=null) &&
                            <img src={logo} alt="profile avartar" 
                                className='profile-img img rounded img-thumbnail'
                                />
                        }
                        {
                            (logo==='' || logo==null) &&
                            <div><span className='far fa-user fa-4x bg-light rounded img-thumbnail'></span></div>
                        }
                        <h5 className='text-light'>{username}</h5>
                    </div>
                    <div className="card-body">
                        <div className="form-group">
                            <label className=' control-label'>Name:</label>
                            <div className="input-group">
                                <span className="input-group-text" id="basic-addon1"><i className='far fa-user'></i></span>
                                <input type="text" disabled className='disabled form-control' 
                                    value={first_name + ' ' + middle_name + ' ' + last_name} />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className=' control-label'>Email:</label>
                            <div className="input-group">
                                <span className="input-group-text" id="basic-addon1"><i className='far fa-user'></i></span>
                                <input type="email" disabled className='disabled form-control' 
                                    value={email} />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className=' control-label'>Phone:</label>
                            <div className="input-group">
                                <span className="input-group-text" id="basic-addon1"><i className='fa fa-phone'></i></span>
                                <input type="text" disabled className='disabled form-control' value={phone} />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className=' control-label'>Address:</label>
                            <div className="input-group">
                                <span className="input-group-text" id="basic-addon1"><i className='fa fa-globe'></i></span>
                                <input type="text" disabled className='disabled form-control' value={address} />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className=' control-label'>LGA:</label>
                            <div className="input-group">
                                <span hidden className="input-group-text" id="basic-addon1"><i className='fa fa-phone'></i></span>
                                <input type="text" disabled className='disabled form-control' value={lga} />
                            </div>
                        </div>
                        <div className="row">                        
                            <div className="form-group col-md-6">
                                <label className='control-label'>City: </label>
                                <input disabled type='text' className='form-control' value={city} 
                                    // onChange={}
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <label className='control-label'>State: </label>
                                <input disabled type='text' className='form-control' value={state} 
                                    // onChange={}
                                />
                            </div>
                        </div>
                        <div className="row">      
                            <div className="form-group col-md-6">
                                <label className='control-label'>Country: </label>
                                <input disabled type='text' className='form-control' value={country} 
                                    // onChange={}
                                />
                            </div>                  
                            <div className="form-group col-md-6">
                                <label className='control-label'>Zip: </label>
                                <input disabled type='text' className='form-control' value={zip} 
                                    // onChange={}
                                />
                            </div>
                        </div>
                    </div>
                    {/* <a href="#"><i className="fa fa-dribbble"></i></a>
                    <a href="#"><i className="fa fa-twitter"></i></a>
                    <a href="#"><i className="fa fa-linkedin"></i></a>
                    <a href="#"><i className="fa fa-facebook"></i></a> */}
                    <p className='text-center'><a href='/Update-Profile' className='btn btn-primary col-md-6'>Update</a></p>
                </div>
            </div>
        </section>
    )
}
 
export default ProfileComponent;