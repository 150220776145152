import React from 'react'
import {Route, Redirect, Routes, Navigate, Outlet} from 'react-router-dom'
import auth from '../../auth/auth';


export const ProtectedRoutes = ({children}) => {
    var res = auth.isAuthenticated();
    var access_token = localStorage.getItem('access_token');
    if(res === true && access_token !== null && access_token !== ''){
        return children
    }
    else{
        return <Navigate to="/Login" />
    }
}
 