import logo from './logo1.png';
import Home from './components/Home';
import { BrowserRouter, Route, Routes, useParams } from 'react-router-dom';
import ProfileComponent from './components/Users/ProfileComponent';
import ProfileUpdateComponent from './components/Users/ProfileUpdateComponent';
import { ProtectedRoutes } from './components/protected/protected-routes';
import { NotFound } from './components/NotFound';
import { RegisterPage } from './auth/Register';
import { LoginPage } from './auth/Login';
// import {DashboardComponent} from './components/DashboardComponent';
import PropertyCreateOrEditComponent from './components/Property/PropertyCreateOrEditComponent';
import PropertyDetailsComponent from './components/Property/PropertyDetailsComponent';
import PropertiesComponent from './components/Property/PropertiesComponent';
import UsersComponent from './components/Users/Users';
import UserComponent from './components/Users/User';
import UserPropertiesComponent from './components/Property/UserPropertiesComponent';
import React from 'react';
import Helmet from 'react-helmet';
import { Layout } from './Layout';
import SupportComponent from './components/support/SupportComponent';
import { AboutComponent } from './components/About';
import ProposalCreateOrEditComponent from './components/Proposal/ProposalCreateOrEditComponent';
import ProposalsComponent from './components/Proposal/ProposalsComponent';
import ProposalDetailsComponent from './components/Proposal/ProposalDetailsComponent';
import SupportAdminComponent from './components/support/SupportAdminComponent';
import VerifyAccountComponent from './components/Users/VerifyAccount';
import { CareerComponent } from './components/Career';
import SubscribersComponent from './components/Users/SubscribersComponent';
import DashboardComponent from './components/DashboardComponent';

function App() {
  return (
    <React.Fragment>
      <Helmet>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="canonical" href="https://anakings.com/" />
        <title>Anakings Home and Properties</title>
        <link rel="icon" href={logo} />
      </Helmet>
      <BrowserRouter>
        <Routes>          
          <Route path="/" element={<Layout />}>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/Login" element={<LoginPage />} />
            <Route exact path="/Register" element={<RegisterPage />} />
            <Route exact path="/About" element={<AboutComponent />} />
            <Route exact path="/Career" element={<CareerComponent />} />
            <Route exact path="/Support" element={<SupportComponent property_id=''/>} />
            <Route exact path="/Subscribers" element={<SubscribersComponent />} />
            <Route exact path="/Support-Admin" element={<SupportAdminComponent property_id=''/>} />
            <Route exact path="/Proposal" element={<ProposalCreateOrEditComponent />} />
            <Route exact path="/Proposals/:id" element={<GetProposal />} />
            <Route exact path="/Account/Verify/:id" element={<GetAccountVerifyComponent />} />
            <Route exact path="/Subscription/Unsubscribe/:id" element={<GetUnsubscribeComponent />} />
            <Route exact path="/Support/:id" element={<GetSupportComponent />} />
            <Route exact path="/Support-Admin/:id" element={<GetSupportAdminComponent />} />
            <Route exact path="/Register" element={<RegisterPage />} />
            <Route exact path="/Search-Properties" element={<UserPropertiesComponent is_cart={false}/>} />
            <Route path="/User-Cart" element={
                <ProtectedRoutes>
                  <UserPropertiesComponent is_cart={true} />
                </ProtectedRoutes>
            }/>
            <Route exact path="/Search-Properties/:id" element={<GetProperty view_only={true}/>} />
            <Route exact path="/Search-Properties/Search/:id" element={<GetPublicProperties />} />
            <Route
              path="/Profile"
              element={
                <ProtectedRoutes>
                  <ProfileComponent />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Update-Profile"
              element={
                <ProtectedRoutes>
                  <ProfileUpdateComponent />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Dashboard"
              element={
                <ProtectedRoutes>
                  <DashboardComponent />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Proposals"
              element={
                <ProtectedRoutes>
                  <ProposalsComponent />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Properties"
              element={
                <ProtectedRoutes>
                  <PropertiesComponent />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Properties-Create"
              element={
                <ProtectedRoutes>
                  <PropertyCreateOrEditComponent property_id={null}/>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Properties/Create"
              element={
                <ProtectedRoutes>
                  <PropertyCreateOrEditComponent property_id={null}/>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Properties-Edit/:id"
              element={
                <ProtectedRoutes>
                  <EditProperty />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Properties/Edit/:id"
              element={
                <ProtectedRoutes>
                  <EditProperty />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Properties/:id"
              element={
                <ProtectedRoutes>
                  <GetProperty view_only={false}/>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Users"
              element={
                <ProtectedRoutes>
                  <UsersComponent />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/Users/:id"
              element={
                <ProtectedRoutes>
                  <GetUser />
                </ProtectedRoutes>
              }
            />
            <Route path="*" element={<NotFound/>}/>
          </Route>
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
}


function GetSupportComponent() {
  const { id } = useParams();
  console.log(id);

  return (
      <div>
          <SupportComponent property_id={id} />
      </div>
  );
}


function GetAccountVerifyComponent() {
  const { id } = useParams();
  console.log(id);

  return (
      <div>
          <VerifyAccountComponent user_id={id} action_type = "verify"/>
      </div>
  );
}


function GetUnsubscribeComponent() {
  const { id } = useParams();
  console.log(id);

  return (
      <div>
          <VerifyAccountComponent user_id={id} action_type = "unsubscribe"/>
      </div>
  );
}


function GetSupportAdminComponent() {
  const { id } = useParams();
  return (
      <div>
          <SupportAdminComponent email_id={id} />
      </div>
  );
}

function GetUser() {
  const { id } = useParams();
  console.log(id);

  return (
      <div>
          <UserComponent user_id={id} />
      </div>
  );
}

function GetPublicProperties(props) {
  const { id } = useParams();

  return (
      <div>
          <UserPropertiesComponent  searchString={id}/>
      </div>
  );
}

function GetProposal(props) {
  const { id } = useParams();

  return (
      <div>
          <ProposalDetailsComponent proposal_id={id}/>
      </div>
  );
}

function GetProperty(props) {
  const { id } = useParams();
  const {view_only} = props

  return (
      <div>
          <PropertyDetailsComponent property_id={id}  view_only={view_only}/>
      </div>
  );
}

function EditProperty() {
  const { id } = useParams();
  console.log(id);

  return (
      <div>
          <PropertyCreateOrEditComponent property_id={id} />
      </div>
  );
}

export default App;
