import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


class PropertyDetailsForm extends Component {
    constructor(props) {
        super(props);
    }
    state = {  }

    

    continue = (e) => {
        e.preventDefault();
        this.props.nextStep();
    }

    render() { 
        const {PropertyDetails, handleChange} = this.props;
        return ( 
            <React.Fragment>
                <div className="row">                        
                    <div className="form-group col-md-4">
                        <label className='control-label'>Title: </label>
                        <input type='text' className='form-control' 
                            placeholder='Property Title' autoFocus
                            defaultValue={PropertyDetails.title}
                            onChange={handleChange('title')}
                        />
                    </div>
                    <div className="form-group col-md-4">
                        <label className='control-label'>Price: </label>
                        <input min={0}
                            defaultValue={PropertyDetails.price}
                            type='number' className='form-control' 
                            placeholder='Property Price'
                            onChange={handleChange('price')}
                        />
                    </div>                      
                    <div className="form-group col-md-4">
                        <label className='control-label'>Size: </label>
                        <input type='text' className='form-control' 
                            defaultValue={PropertyDetails.size}
                            onChange={handleChange('size')}
                        />
                    </div>
                    <div className="form-group col-md-4">
                        <label className='control-label me-2'>Negotiable: </label>
                        <input type='checkbox' className='form-control-sm' 
                            defaultChecked={PropertyDetails.negotiable}
                            onChange={handleChange('negotiable')}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <label className='control-label'>Description: </label>
                    {/* <!-- Create the editor container --> */}
                    <ReactQuill required className='textarea' 
                        placeholder='Enter Description'
                        value={PropertyDetails.description}
                        onChange={handleChange("description")} >
                    </ReactQuill>
                </div>
            </React.Fragment>
         );
    }
}
 
export default PropertyDetailsForm;