import React, { Component } from 'react';
import auth from '../../auth/auth';
import { LoaderComponent } from '../loaderComponent';

export default class VerifyAccountComponent extends Component {
    constructor(props) {
        super(props);
    }
    state = { 
        user_id: this.props.user_id, 
        isLoading: false, isUpLoading: false, userId:'', msgS: '', msgF: '',
    }

    componentDidMount() {
        (async () => {
            this.setState({isLoading: true});
            this.setState({user_id: this.props.user_id});
            if(this.props.user_id == null || this.props.user_id === undefined) {
                document.URL = auth.siteUrl();
                return
            }

            var url = 'users/account/verify/';
            if(this.props.action_type === "verify") {
                var result = await auth.GetData(url + this.props.user_id, 'GET');
                // console.log(result["details"]);
                if(result["Error"] != null || result["Error"] !== undefined){
                    this.setState({msgF: result.Error});
                }if(result["message"] != null || result["message"] !== undefined){
                    this.setState({msgS: result.message});
                }
            }
            this.setState({isLoading: false});
        })().then(()=>{this.setState({isLoading: false})});
    }
    
    Unsubscribe = () => {
        (async () => {
            this.setState({isLoading: true});
            var url = 'subscribers/';
            var result = await auth.GetData(url + this.props.user_id, 'DELETE');
            // console.log(result["details"]);
            if(result["Error"] != null || result["Error"] !== undefined){
                this.setState({msgF: result.Error});
            }if(result["message"] != null || result["message"] !== undefined){
                this.setState({msgS: result.message});
                alert(result.message);
                document.URL = auth.siteUrl();
            }
            this.setState({isLoading: false});
        })().then(()=>{this.setState({isLoading: false})});        
    }

    render() { 
        if(this.state.isLoading) 
            return (
                <LoaderComponent />
            );

        return ( 
            <React.Fragment>
                <div className='shadow m-2 p-2'>
                    <div className="card-body">
                        <div className="text-center form-group">
                            {this.props.action_type === "unsubscribe" && <div>
                                <p>We are sorry to see you go</p>
                                <p>
                                    <button className='btn btn-warning'
                                        onClick={e=>this.Unsubscribe()}
                                    >
                                        Procee to Unsubscribe me
                                    </button>
                                </p>
                            </div>}
                            <p className='text-success'>{this.state.msgS}</p>
                            <p className='text-danger'>{this.state.msgF}</p>
                        </div>
                    </div>
                </div>
            </React.Fragment>
         );
    }
}